import classnames from "classnames";
import styles from "./index.module.scss";
import { Button, Dropdown, Menu, ConfigProvider } from "antd";

interface CateProps {
  setPageType: (id: string) => void;
  pageType: string;
  category: Array<{
    id: string;
    icon?: string;
    name: string;
  }>;
  handelAdd: (e: React.MouseEvent) => void;
  selectedFilter?: string;
  handleFilter?: (filter: string) => void;
  isTabFolder?: boolean;
  tags?: string[];
  needFilterBtn?: boolean;
}

// 顶部分类条组件
const Cate: React.FC<CateProps> = (props) => {
  const {
    setPageType,
    pageType,
    category,
    handelAdd,
    selectedFilter,
    handleFilter,
    isTabFolder,
    tags = [],
    needFilterBtn = true,
  } = props;

  // 确保 tags 的第一项为 '全部'
  const processedTags = ["全部", ...tags.filter((tag) => tag !== "全部")];

  // 最多显示的按钮和隐藏在下拉菜单里的按钮，考虑 '全部' 按钮
  const visibleButtons = processedTags.slice(0, 10);
  const dropdownButtons = processedTags.slice(10);

  // 下拉菜单
  const menu = (
    <Menu>
      {dropdownButtons.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => handleFilter && handleFilter(item)}
          className={selectedFilter === item ? styles.selectedMenuItem : ""}
        >
          {item}
        </Menu.Item>
      ))}
    </Menu>
  );

  if (!isTabFolder) {
    return null;
  }

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          // 使用品牌色
          colorPrimary: "#29CC6A",
        },
        components: {
          Button: {
            /* 这里是你的组件 token */
            colorPrimaryHover: "#29CC6A",
            primaryShadow: "none",
            defaultBorderColor: "#29CC6A",
            defaultBg: "transparent",
            defaultColor: "#29CC6A",
          },
          Input: {
            activeShadow: "none",
          },
        },
      }}
    >
      <div>
        {/* 分类tab */}
        <div className={styles.category}>
          <div className={styles.cates}>
            {category.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classnames(styles.category_item)}
                  onClick={() => setPageType(item.id)}
                >
                  <div className={styles.category_item_name_box}>
                    {item.icon && (
                      <img
                        src={item.icon}
                        className={classnames(
                          styles.category_img,
                          pageType === item.id && styles.category_img_active
                        )}
                        alt={`${item.name} icon`}
                      />
                    )}
                    <div
                      className={classnames(
                        styles.category_name,
                        pageType === item.id && styles.category_name_active
                      )}
                    >
                      {item.name}
                    </div>
                  </div>
                  <div
                    className={classnames(
                      styles.category_item_bottom_active,
                      pageType === item.id ? styles.category_item_bottom : ""
                    )}
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.btn}>
            {pageType === "knowledge" && (
              <Button
                className={styles.btnWrapper}
                type="default"
                onClick={handelAdd}
              >
                新建知识库
              </Button>
            )}
          </div>
        </div>

        {/* 筛选按钮 */}
        {needFilterBtn ? (
          <div className={styles.container}>
            <div className={styles.btns}>
              {visibleButtons.map((item, index) => (
                <Button
                  key={index}
                  className={styles.button}
                  type={selectedFilter === item ? "primary" : "default"}
                  onClick={() => handleFilter && handleFilter(item)}
                >
                  {item}
                </Button>
              ))}
              {dropdownButtons.length > 0 && (
                <Dropdown overlay={menu}>
                  <Button className={styles.button}>展开更多</Button>
                </Dropdown>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </ConfigProvider>
  );
};

export default Cate;
