// store.ts

import { createSlice } from "@reduxjs/toolkit";

// 定义初始状态
interface ModalState {
  coinModal: {
    visible: boolean,
    isFirst: boolean;
  };
  rechargeModalVisible: boolean;
}

const initialState: ModalState = {
  coinModal: {
    visible: false,
    isFirst: false
  },
  rechargeModalVisible: false
};

// 创建 slice
const modalSlice = createSlice({
  name: "coinModal",
  initialState,
  reducers: {
    openCoin(state, action?) {
      state.coinModal.visible = true;
      state.coinModal.isFirst = !!action?.payload;
    },
    closeCoin(state) {
      state.coinModal.visible = false;
      state.coinModal.isFirst = false;
    },
    openRecharge(state) {
      state.rechargeModalVisible = true;
    },
    closeRecharge(state) {
      state.rechargeModalVisible = false;
    },
  },
});

// 导出 action creators
export const { openRecharge, closeRecharge, openCoin, closeCoin } = modalSlice.actions;

export default modalSlice.reducer;
