import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Styles from "./index.module.scss";
import { Trans, useTranslation } from "react-i18next";
import PublishDialog from "./components/PublishDialog";
import PrivatePolicy from "@/components/PrivatePolicy";
import { publishFieldType } from "@/types/common";
import { Form, Select, Input, Radio, Button } from "antd";
import { message } from "antd";
// import { category } from "./publishCategory";
import { useSearchParams } from "react-router-dom";
import { IPublishInfo } from "@/types/common";
import {
  getAgentCategory,
  toPublish,
  toGetAgentPublicInfo,
  toRollbackAgent,
} from "@/apis/common";
import dayjs from "dayjs";
import closePic from "@/assets/common/close.svg";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import ConfirmModal from "@/components/Modal";

import _ from "lodash";

const { Option } = Select;

type IPublish = {
  createId: any;
  createInfo: any;
  createInfoRef: any;
};
const Deployment = forwardRef(
  ({ createId, createInfo, createInfoRef }: IPublish, ref) => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [params] = useSearchParams();

    let publicId = Number(params.getAll("publicId")[0]);
    let Type = Number(params.getAll("type")[0]);
    const [isPublishDialog, setPublishDialog] = useState(false);
    const [updateTime, setUpdateTime] = useState<any>("");
    const [showPolicy, setShowPolicy] = useState(false);
    const [isPublish, setIsPublish] = useState(!!createInfo.pub_agent_id);
    const [form] = Form.useForm();
    const [categoryInfo, setCategoryInfo] = useState([]);
    const [curId, setCurId] = useState(0);
    const [curName, setCurName] = useState<any>("");

    const [publishInfo, setPublishInfo] = useState<IPublishInfo>({
      tags: [], // 标签列表
      public: 2, // 公开性 1仅查看， 2可创建同款
      category1: "", // 1级类目
      category2: "", // 2级类目
      category: [],
      question_samples: ["", "", "", ""], // 运行示例
    });
    const ConfirmModalRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      publishAction,
      rollBack: rollBack,
    }));

    useEffect(() => {
      getAgentCategory({}).then((res) => {
        if (res.data && res.data.status === 200) {
          setCategoryInfo(res.data.data.categories);
        }
      });

      if (publicId) {
        getPublishAgentInfo();
      }

      form.setFieldsValue(publishInfo);
    }, []);

    const getPublishAgentInfo = () => {
      toGetAgentPublicInfo({
        id: publicId,
      }).then((res: any) => {
        const data = res?.data;
        if (data?.status === 200) {
          const curData = data?.data;
          if (curData) {
            const publicAgentInfo: any = _.pick(curData, [
              "tags",
              "public", // 公开性 1仅查看， 2可创建同款
              "category1", // 1级类目
              "category2", // 2级类目
              "question_samples", // 运行示例
              "update_time", //更新时间
            ]);

            publicAgentInfo.category = publicAgentInfo.category1;
            setCurId(curData?.agent_id);
            setCurName(curData?.name);

            let tags: any = [];
            publicAgentInfo.tags.forEach((item: any) => {
              tags.push(item.name);
            });

            publicAgentInfo.tags = tags;
            setUpdateTime(publicAgentInfo.update_time);

            setPublishInfo(publicAgentInfo);
            form.setFieldsValue(publicAgentInfo);
          }
        }
      });
    };

    // 修改发布数据
    const changePublishInfo = (key: string, value: any) => {
      let newPublishInfo: any = _.cloneDeep(publishInfo);
      _.set(newPublishInfo, key, value);
      setPublishInfo(newPublishInfo);
      form.setFieldsValue(newPublishInfo);
    };

    const categoryRef = useRef<any>();

    const [categoryTop, setCategoryTop] = useState(0);

    useEffect(() => {
      const { top } = categoryRef?.current?.getBoundingClientRect();
      setCategoryTop(top + createInfoRef.current?.scrollTop);
    }, [createInfo, createInfoRef]);

    const publishAction = () => {
      form
        .validateFields()
        .then((values) => {
          console.log({ ...publishInfo, agent_id: createId.current });

          publishInfo.question_samples?.forEach(
            (item: string, index: number) => {
              if (!item) {
                publishInfo.question_samples.splice(index, 1);
              }
            }
          );

          let param: any = {
            ...publishInfo,
            agent_id: createId.current,
            category1: publishInfo.category,
          };

          if (publishInfo.category[1]) {
            param.category2 = publishInfo.category[1];
          } else {
            delete param.category2;
          }

          toPublish(param).then((res: any) => {
            if (res.data && res.data.status === 200) {
              message.success(t("publish.success"));
              setUpdateTime(new Date());
              navigate(`/my_agent`);
            }
          });
        })
        .catch((error: any) => {
          console.log("formError", error);
          const { errorFields } = error;
          if (errorFields.length) {
            createInfoRef?.current?.scrollTo({
              top: categoryTop - 80,
              behavior: "smooth",
            });
          }
        });
    };

    const showPolicyAction = () => {
      setShowPolicy(true);
    };

    const recommandList = [
      "🎵 音乐",
      "🎨 艺术",
      "💼 协作",
      "📐 办公",
      "➕ 其他",
    ];

    const clickRecommand = (tag: any) => {
      let tags = [...publishInfo.tags];
      if (!publishInfo.tags.includes(tag)) {
        tags.push(tag);
        changePublishInfo("tags", tags);
      }
    };

    const rollBack = () => {
      ConfirmModalRef?.current?.showModal();
    };
    const doRollBack = () => {
      toRollbackAgent({ agent_id: curId }).then((res) => {
        if (res.data && res.data.status === 200) {
          message.success("撤回发布成功");
          setUpdateTime(new Date());
          navigate(`/my_agent`);
        }
      });
    };

    return (
      <div className={Styles.page} ref={categoryRef}>
        <div className={classnames(Styles.page_inner, "scroll-hide")}>
          <Form className={Styles.form} form={form} scrollToFirstError>
            <div className={Styles.form_item_title}>{t("common.category")}</div>
            <Form.Item<publishFieldType>
              label=""
              className={Styles.form_item}
              name="category"
              rules={[
                {
                  required: true,
                  message: t("create.category.placeholder"),
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onChange={(value: any) => {
                  changePublishInfo("category", value);
                }}
                value={publishInfo?.category}
                className={Styles.select}
              >
                {categoryInfo.map((item: any) => {
                  return (
                    <Option key={item.key} value={item.id}>
                      {t(item.key)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div className={Styles.form_item_title}>{t("common.tags")}</div>
            <Form.Item<publishFieldType>
              label=""
              className={Styles.form_item}
              name="tags"
              // rules={[
              //   {
              //     required: true,
              //     type: "array",
              //     message: t("publish.tags.placeholder"),
              //   },
              // ]}
            >
              <Select
                mode="tags"
                onChange={(value: any) => {
                  changePublishInfo("tags", value);
                }}
                value={publishInfo?.tags}
                popupClassName={Styles.tags_popup}
                className={Styles.select}
                tagRender={(props) => {
                  return (
                    <div className={Styles.select_tag}>
                      {props.label}
                      <img
                        className={Styles.select_tag_img}
                        src={closePic}
                        onClick={() => {
                          props.onClose();
                        }}
                        loading="lazy"
                      ></img>
                    </div>
                  );
                }}
                maxCount={3}
              >
                {publishInfo.tags.map((item) => {
                  return (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div className={Styles.form_item_recommand_tag}>
              <div className={Styles.form_item_title}>
                {t("publish.recommand.tags")}
              </div>
              <div className={Styles.recommand_tag}>
                {recommandList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={Styles.recommand_tag_item}
                      onClick={() => {
                        clickRecommand(item);
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={Styles.form_item_title}>
              {t("publish.question.example")}
            </div>

            <Form.Item<publishFieldType> label="" className={Styles.form_item}>
              <Input
                className={Styles.question_samples_item}
                value={publishInfo.question_samples?.[0]}
                onChange={(e: any) => {
                  changePublishInfo("question_samples[0]", e.target.value);
                }}
                maxLength={100}
              ></Input>
            </Form.Item>

            <Form.Item<publishFieldType> label="" className={Styles.form_item}>
              <Input
                className={Styles.question_samples_item}
                value={publishInfo.question_samples?.[1]}
                onChange={(e: any) => {
                  changePublishInfo("question_samples[1]", e.target.value);
                }}
                maxLength={100}
              ></Input>
            </Form.Item>

            <Form.Item<publishFieldType> label="" className={Styles.form_item}>
              <Input
                className={Styles.question_samples_item}
                value={publishInfo.question_samples?.[2]}
                onChange={(e: any) => {
                  changePublishInfo("question_samples[2]", e.target.value);
                }}
                maxLength={100}
              ></Input>
            </Form.Item>

            <Form.Item<publishFieldType> label="" className={Styles.form_item}>
              <Input
                className={Styles.question_samples_item}
                value={publishInfo.question_samples?.[3]}
                onChange={(e: any) => {
                  changePublishInfo("question_samples[3]", e.target.value);
                }}
                maxLength={100}
              ></Input>
            </Form.Item>

            <div className={Styles.form_item_title}>
              {t("publish.editable")}
            </div>

            <Form.Item<publishFieldType>
              label=""
              className={Styles.form_item}
              name="public"
              rules={[
                {
                  required: true,
                  type: "number",
                  message: t("请选择是否允许其他用户创建同款"),
                },
              ]}
            >
              <Radio.Group
                value={publishInfo.public}
                onChange={(e: any) => {
                  changePublishInfo("public", e.target.value);
                }}
              >
                <Radio value={2}>{t("common.yes")}</Radio>
                <Radio value={1}>{t("common.no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <div className={Styles.page_inner_bottom}>
            {/* {isPublish ? (
            <div className={Styles.btn_wrap}>
              <Button
                className={Styles.publish_btn}
                onClick={() => {
                  publishAction();
                }}
              >
                {t("publish.btntext")}
              </Button>
              <div className={Styles.rollback_container}>
                <Button
                  className={Styles.rollback_content}
                  onClick={() => {
                    rollBack();
                  }}
                >
                  {t("publish.rollback")}
                </Button>
              </div>
            </div>
          ) : (
            <Button
              className={Styles.publish_btn}
              onClick={() => {
                publishAction();
              }}
            >
              {t("publish.btntext")}
            </Button>
          )} */}

            <div className={Styles.publish_policy}>
              {t("publish.agreement")}
              <span
                className={Styles.publish_policy_link}
                onClick={() => {
                  showPolicyAction();
                }}
              >
                {t("publish.policy")}
              </span>
              +
            </div>
            <div className={Styles.publish_update_time}>
              {t("publish.last.time")}{" "}
              {updateTime
                ? dayjs(updateTime).format("YYYY-MM-DD HH:mm:ss")
                : "--"}
            </div>
          </div>
        </div>
        <PrivatePolicy
          show={showPolicy}
          onClose={() => {
            setShowPolicy(false);
          }}
        />
        <PublishDialog
          isPublishDialog={isPublishDialog}
          setPublishDialog={setPublishDialog}
        />
        <ConfirmModal
          ref={ConfirmModalRef}
          confirmFn={doRollBack}
          title={t("publish.rollback")}
        >
          <Trans i18nKey="publish.rollbackTip" values={{ name: curName }}>
            {{ curName }}
          </Trans>
        </ConfirmModal>
      </div>
    );
  }
);

export default Deployment;
