import { useState } from "react";
import { message, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
import Styles from "./index.module.scss";
import baseURL from "@/request/getFileBseUrl";
import addPic from "@/assets/myAgent/add.svg";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { FormInstance } from 'antd/es/form';
import { createImageUrl } from "@/utils/common";

const folderPic = createImageUrl('knowledge/folder.png');

// 定义IUploadProps接口，明确组件接收的props类型
interface IUploadProps {
    onUploadSuccess?: (url: string) => void; // 上传成功后的回调函数
    handleEditClick: boolean; // 是否启用编辑点击
    form: FormInstance
}

/** 上传图片 */
const HeadImage: React.FC<IUploadProps> = ({
    handleEditClick,
    form
}) => {
    const [loading, setLoading] = useState(false); // 管理上传状态
    const [showClose, setShowClose] = useState(false); // 管理关闭图标显示状态
    const { t } = useTranslation(); // 国际化钩子

    // 将图片文件转为base64字符串
    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    // 图片上传前的文件类型和大小验证
    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = ["image/jpeg", "image/png", "image/svg", "image/jpg"].includes(file.type);
        if (!isJpgOrPng) {
            message.error("仅支持JPG/PNG/SVG~");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("图片大小必须小于2MB!");
        }
        return isJpgOrPng && isLt2M;
    };

    // 处理图片上传状态改变
    const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setLoading(true);
        } else if (info.file.status === "done") {
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    };

    // 自定义上传逻辑
    const customRequest = ({ file, onSuccess, onError }: any) => {
        // 创建表单数据对象
        const formData = new FormData();
        // 将文件附加到表单数据对象
        formData.append("file", file as RcFile);
        formData.append('env', 'c');
        formData.append('path', '/knowLedge/folder/image');

        // 获取存储在LocalStorage或Cookies中的令牌
        const token = localStorage.getItem("token") || Cookies.get("token");

        // 添加令牌到请求头中
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // 发送POST请求到服务器
        fetch(`${baseURL}/upload/image/`, {
            method: "POST",
            headers,
            body: formData,
        })
            .then((response) => response.json()) // 将响应转换为JSON格式
            .then((data) => {
                // 在响应中，进一步处理成功和失败的情况
                if ((data.status = 200)) {
                    // 如果上传成功，调用onSuccess并传递数据和文件
                    onSuccess && onSuccess(data, file);
                    // 更新图片URL
                    form.setFieldsValue({ img_url: data.data.file_url })
                    // 显示上传成功的消息
                    message.success("更新成功");
                }
            })
            .catch((error) => {
                // 处理上传失败的情况，调用onError并传递错误信息和文件
                onError && onError(error);
                // 显示上传失败的消息
                message.error(t("更新失败"));
            });
    };

    const modalImage = form.getFieldValue("img_url") || folderPic;

    return (
        <div
            className={Styles.upload}
            onMouseEnter={() => setShowClose(true)}
            onMouseLeave={() => setShowClose(false)}
        >
            <Upload
                accept="image/jpeg, image/png, image/jpg, image/svg"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={customRequest} // 使用自定义请求逻辑
            >
                <div className={Styles.upload_inside}>
            {loading ? (
                <LoadingOutlined style={{ fontSize: "24px", color: "#66FF78" }} />
            ) : (
                <>
                    {modalImage ? (
                        <>
                            {handleEditClick && (
                                <div className={showClose ? Styles.closeIcon : Styles.closeIcon_hide}>
                                    <EditOutlined size={16} />
                                </div>
                            )}
                            <img
                                src={modalImage}
                                alt=""
                                className={Styles.bg_img}
                                loading="lazy"
                            />
                        </>
                    ) : (
                        <div className={Styles.add}>
                            <img src={addPic} className={Styles.addIcon} alt="" />
                            <div className={Styles.addText}>{t("myagent.uploadImage")}</div>
                        </div>
                    )}
                </>
            )}
        </div>
            </Upload>
        </div>
    );
};

export default HeadImage;