import { Routes, Route, useSearchParams } from "react-router-dom";
import { routerMap } from "./route/index";
import Header from "./components/Header";
import Menu from "./components/Menu";
import { Layout, Modal, theme } from "antd";
import useLoginHooks from "@/hooks/useLoginHooks";
import { routerItem } from "./types/common";
import { ConfigProvider } from "antd";
import { HappyProvider } from "@ant-design/happy-work-theme";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  toUsersIslogin,
  getConfig,
  loginCallBack,
  getUserGuideInfo,
  getCanClaimCoinToday,
  getJsonConfig,
} from "./apis/common";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginModal from "@/components/LoginModal";
import { updateUserInfo } from "@/store/reducer/userInfo";
import { updateConfig } from "@/store/reducer/config";
import { Provider } from "react-redux";
import store from "@/store";
import Cookies from "js-cookie";
import PageLoading from "./components/PageLoading";
import "@/App.css";
import "@/translation/i18n";
import "normalize.css";
import "@/common.scss";
import "@/fonts/font.css";
import "@/magic.min.css";
import { t } from "i18next";
import { setGlobalUserData } from "./store/reducer/userConfig";
import CoinModal from "./components/CoinModal";
import { openCoin } from "./store/reducer/coinRecharge";
import ProductModal from "./components/ProductModal";
import zhCN from 'antd/lib/locale/zh_CN';

const { Content } = Layout;
const clientId =
  "943147563033-3f3a5pe55brll3kpe4afr4lanfubvm7e.apps.googleusercontent.com";

function App() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [params] = useSearchParams();
  const code = params.getAll("code")?.[0] || "";
  const state = params.getAll("state")?.[0] || "";
  const { isLogin, id } = useLoginHooks();
  const hostname = window.location.hostname;

  const getTopLevelAndSecondLevelDomain = (hostname: any) => {
    // 分割主机名
    const parts = hostname.split(".");
    // 确保至少有三部分（例如 sub.example.com）
    if (parts.length > 2) {
      // 提取最后两部分作为顶级域名和二级域名
      return parts.slice(-2).join(".");
    }
    // 如果主机名部分不足，返回原始主机名
    return hostname;
  };
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

    const canIGetCoin = () => {
      const todayKey =
      "canClaimCoinToday_" + id +'_' + new Date().toISOString().split("T")[0]; // 生成今天的日期作为key
      const cachedResult = localStorage.getItem(todayKey);
      if (!cachedResult) {
        // 调用API获取数据
        getCanClaimCoinToday().then((res) => {
          if (res?.data?.status === 200) {
            const { is_first_login, has_claimed_coins_today } =
              res.data.data || {};
            // 将结果存储至localStorage，设置过期时间为第二天
            if (!has_claimed_coins_today) {
              store.dispatch(openCoin(is_first_login));
            }
          }
        });
      }
    };

  useEffect(() => {
    if (code && state === "wechat_redirect") {
      loginCallBack({ token: code, login_type: "weixin" }).then((res: any) => {
        if (res && res?.status === 200) {
          toUsersIslogin().then((res: any) => {
            store.dispatch({
              ...updateUserInfo(res.data.data),
              isUserInfoLoading: false,
              token: localStorage.getItem("token"),
            });
            getUserGuideInfo().then((res) => {
              if (res && res?.status === 200) {
                store.dispatch(setGlobalUserData(res.data.data));
              }
            });
            canIGetCoin()
            Cookies.set("token", localStorage.getItem("token") as string, {
              domain: `${topLevelAndSecondLevelDomain}`,
            });
            const newURL = window.location.pathname;
            window.history.replaceState({}, "", newURL);
            sendRefreshSignal();
          });
        }
      });
    }
  }, [state, code]);

  // 发送信号到其他标签页或窗口
  const sendRefreshSignal = () => {
    localStorage.setItem("refreshSignal", Date.now().toString());
  };

  // 在其他标签页或窗口监听信号，并执行刷新操作
  window.addEventListener("storage", function (event) {
    if (event.key === "refreshSignal") {
      // 这里可以执行刷新操作
      window.location.reload();
    }
  });

  useEffect(() => {
    // do something when route changes
    if (location.pathname !== "/" && !location.pathname) {
      (window as any).gtag("send", "pageview", {
        path: location.pathname,
        userId: id,
      });
    }
  }, [location]);

  useEffect(() => {
    window.onload = () => {
      setIsLoading(false);
    };
  }, []);

  // 获取个人信息
  const getUserInfo = () => {
    if (localStorage.getItem("token") || Cookies.get("token")) {
      store.dispatch({
        ...updateUserInfo({
          isUserInfoLoading: true,
        }),
      });
      toUsersIslogin().then((res: any) => {
        if (res && res?.status === 200) {
          store.dispatch({
            ...updateUserInfo(res.data.data),
            isUserInfoLoading: false,
            token: localStorage.getItem("token") || Cookies.get("token"),
          });
        }
        getUserGuideInfo().then((res) => {
          if (res && res?.status === 200) {
            store.dispatch(setGlobalUserData({...res.data.data}));
          }
        });
        canIGetCoin();
      });
    } else {
      store.dispatch({
        ...updateUserInfo({
          id: 0,
          isUserInfoLoading: false,
          token: "",
        }),
      });

      store.dispatch(setGlobalUserData({
        avatar: '',
        is_tutorial_completed: false,
        nick_name: '',
        subscription_expiry_date: '',
        subscription_plan: 0,
        telephone: '',
        user_source: '',
        zhiji_currency_count: 0,
      }));

    }
  };

  const getConfigInfo = async () => {
    // 通用的获取配置并更新store的函数
    const fetchAndDispatchConfig = async (getConfigFunc: Function, keys: string[]) => {
      try {
        const res = await getConfigFunc({ keys });
        if (res.data && res.data.status === 200) {
          store.dispatch({
            ...updateConfig(res.data.data),
          });
        }
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
  
    // 并行获取配置
    await Promise.all([
      fetchAndDispatchConfig(getConfig, [
        "homepage_agent_id",
        "home_question_show_change",
        "login_google_trigger",
        "login_wechat_trigger",
        "support_phone_login"
      ]),
      fetchAndDispatchConfig(getJsonConfig, [
        "daily_login_coins",
        "first_login_coins",
        "chat_model_list",
        "agent_model_list"
      ])
    ]);
  };

  useEffect(() => {
    getUserInfo();
    getConfigInfo();
    document
      .querySelectorAll("html")[0]
      .setAttribute("theme", localStorage.getItem("theme") || "dark");
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorBgMask: "var(--theme-bg-mask)",
            },
          }}
          locale={zhCN}
        >
          <HappyProvider>
            <GoogleOAuthProvider clientId={clientId}>
              <Layout>
                <Header />
                <Layout
                  style={{
                    paddingTop: "64px",
                  }}
                >
                  <Menu />
                  <Layout>
                    <Content className="content">
                      <Routes>
                        {routerMap.map((item: routerItem, index: number) => {
                          return (
                            <Route
                              key={index}
                              path={item.path}
                              element={item.element}
                            />
                          );
                        })}
                      </Routes>
                    </Content>
                    {/* {isLoading && <PageLoading />} */}
                  </Layout>
                </Layout>
              </Layout>
              <LoginModal />
              <CoinModal />
              <ProductModal/>
            </GoogleOAuthProvider>
          </HappyProvider>
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;
