import Styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import { IAgentInfoProps } from "@/types/common";
import { useNavigate } from "react-router-dom";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { useTranslation } from "react-i18next";
import LikePic from "@/assets/common/like.svg";
import LikeActivePic from "@/assets/common/like-active.svg";
import CardItemDetail from "./CardItemDetail";
import {
  like,
  disLike,
  toGetAgentInfo,
  toGetAgentPublicInfo,
} from "@/apis/common";
import { numberFormat } from "@/utils/common";
import _ from "lodash";
import qs from "qs";
import useLoginHooks from "@/hooks/useLoginHooks";
import Cookies from "js-cookie";
import store, { loginOption } from "@/store";
import { useSearchParams, useLocation } from "react-router-dom";
import classNames from "classnames";

const agentBg = createImageUrl('common/agent-bg.png');
const Avatar = createImageUrl('menu/avator.png');
const agentArr: any = [];
const CardItem = ({
  agentInfo,
  tagNameObj,
  userObj,
  likeStatusObj,
  setLikeStatusObj,
  isSelfItem,
  setNeedUpdateData,
  onDelete,
}: IAgentInfoProps) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const itemRef: any = useRef();
  const [showDetail, setShowDetail] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const { username } = useLoginHooks();

  const token = localStorage.getItem("token") || Cookies.get("token");

  const curId = isSelfItem ? agentInfo?.pub_agent_id : agentInfo?.id;
  const [params] = useSearchParams();
  const location = useLocation();

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };
  // 点赞逻辑
  const likeAction = () => {
    if (isLiking) {
      return;
    }

    if (setNeedUpdateData) {
      setNeedUpdateData(true);
    }

    if (curId && setLikeStatusObj) {
      setIsLiking(true);
      if (!likeStatusObj[curId]?.is_my_like) {
        like({ pub_agent_id: curId })
          .then((res) => {
            setIsLiking(false);
            if (res && res.data && res.status === 200) {
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  is_my_like: true,
                  like_count:
                    newLikeStatusObj[curId]?.like_count &&
                    newLikeStatusObj[curId]?.like_count > 0
                      ? newLikeStatusObj[curId]?.like_count + 1
                      : 1,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      } else {
        disLike({ pub_agent_ids: [curId] })
          .then((res) => {
            if (res && res.data && res.status === 200) {
              setIsLiking(false);
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  is_my_like: false,
                  like_count:
                    newLikeStatusObj[curId]?.like_count &&
                    newLikeStatusObj[curId]?.like_count > 0
                      ? newLikeStatusObj[curId]?.like_count - 1
                      : 0,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      }
    }
  };

  const deleteItem = () => {
    if (onDelete) {
      onDelete(agentInfo);
    }
  };

  const edit = () => {
    let param: any = {
      step: "create",
      id: String(agentInfo?.id),
      type: "private",
    };

    param.mode = agentInfo?.show_mode === 2 ? "card" : "";
    if (!!agentInfo.pub_agent_id) {
      param.publicId = agentInfo?.pub_agent_id;
    }

    navigate(`/workflow?${qs.stringify(param)}`);
  };

  const createSame = () => {
    localStorage.setItem("createAgentInfo", "");
    toGetAgentInfo({
      id: agentInfo.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          localStorage.setItem(
            "createAgentInfo",
            JSON.stringify(res.data.data)
          );
        }
      }
    });
    let data = {
      step: "create",
      mode: "card",
    };
    navigate(`/workflow?${qs.stringify(data)}`);
  };

  const gitPublicInfo = () => {
    toGetAgentPublicInfo({
      id: agentInfo.id,
    }).then((res: any) => {
      if (res && res.data && res.data.status === 200) {
        agentInfo = res.data.data;
        setShowDetail(true);
      }
    });
  };

  useEffect(() => {
    const publicId = params.getAll("publicId")?.[0] || "";
    agentArr.push(agentInfo.id);
    if (publicId) {
      if (
        agentInfo.id === Number(publicId) ||
        (agentArr.length === 12 && isSelfItem === undefined && !agentArr.includes(Number(publicId)))
      ) {
        gitPublicInfo();
      }
    }
    let url = window.location.href;
    window.history.pushState({ path: url }, '', url);
  }, [location]);

  const findUserById = (id: any) => {
    if (!userObj) {
      return null;
    }
    
    const user = userObj.find((user: any) => user.id === id);
    return user ? { username: user.username, avatar: user.avatar } : null;
  };

  return (
    <div className={Styles.agent_item} ref={itemRef}>
      <div className={Styles.agent_item_content}>
        <div className={Styles.agent_item_img_box}>
          {isSelfItem && (
            <div
              className={Styles.agent_item_delete}
              onClick={() => {
                deleteItem();
              }}
            >
              <i className={classNames('iconfont', 'icon-delete_line')}/>
            </div>
          )}
          {isSelfItem && (
            <>
              <div className={Styles.agent_item_mask}></div>
              <div className={Styles.agent_item_action}>
                <div
                  className={Styles.agent_item_action_item}
                  onClick={() => {
                    edit();
                  }}
                >
                  <i className={classNames('iconfont', 'icon-edit_line')}/>
                  <span>{t("common.edit")}</span>
                </div>
                |
                <div
                  className={Styles.agent_item_action_item}
                  onClick={() => {
                    createSame();
                  }}
                >
                  <i className={classNames('iconfont', 'icon-copy_line')}/>
                  <span>{t("common.create.same")}</span>
                </div>
              </div>
            </>
          )}
          <div className={Styles.agent_item_img_padding}></div>
          <img
            className={Styles.agent_item_img}
            onClick={() => {
              if (!token) {
                onLoginAndRegister();
              } else {
                setShowDetail((showDetail: boolean) => !showDetail);
              }
            }}
            src={getImageUrl(agentInfo?.avatar_url || agentBg)}
            onError={(e: any) => {
              e.target.src = agentBg;
            }}
            loading="lazy"
          ></img>
          <div className={Styles.agent_item_padding}></div>
          {/* <div className={Styles.agent_item_type}>
            <img src={SingalAgentPic}></img>
            <span>{t("common.agent.singal")}</span>
          </div> */}
          {/* <div className={Styles.agent_item_number}>
            <img src={FirePic}></img>
            <span>{numberFormat(agentInfo?.hot)}</span>
          </div> */}
          {/* <div className={Styles.agent_item_mask}></div> */}
          {showDetail && (
            <CardItemDetail
              agentInfo={agentInfo}
              tagNameObj={tagNameObj}
              showDetail={showDetail}
              setShowDetail={setShowDetail}
              isSelfItem={isSelfItem}
            />
          )}
        </div>
        {/* <div className={Styles.tag_list}>
        {agentInfo.tags?.map((item, index) => {
          return (
            <div className={Styles.tag_list_item} key={index}>
              {tagNameObj[item]}
            </div>
          );
        })}
      </div> */}
        <div className={Styles.agent_item_info}>
          {/* <img className={Styles.avator} src={Avatar}></img> */}
          {/* <div> */}
          <div className={Styles.name}>{agentInfo?.name}</div>
          <div className={Styles.user_info}>
            <div className={Styles.author}>
              <img className={Styles.avator} alt="" src={findUserById(agentInfo?.author)?.avatar || Avatar} loading="lazy"></img>
              <span>
                {isSelfItem
                  ? username
                  : findUserById(agentInfo?.author)?.username|| "智纪用户"}
              </span>
            </div>

            {isSelfItem && !agentInfo.pub_agent_id ? null : (
              <div className={Styles.like}>
                <img
                  src={
                    likeStatusObj[curId || ""]?.is_my_like
                      ? LikeActivePic
                      : LikePic
                  }
                  onClick={likeAction}
                  loading="lazy"
                ></img>
                {numberFormat(likeStatusObj[curId || ""]?.like_count || 0)}
              </div>
            )}
          </div>
          {/* </div> */}
          {/* <div className={Styles.action}>
          <Button style={{ marginLeft: "20px" }} size="small" onClick={toChat}>
            {t("对话")}
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            size="small"
            onClick={createSame}
          >
            {t("创建同款")}
          </Button>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardItem;
