import Styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { getImageUrl } from "@/utils/common";

type IArticleItem = {
  articleItemData: {
    cover_link: string; // 文章图片
    description: string; // 文章摘要
    link: string; // 文章外链
    title: string; //  文章标题
    [property: string]: any;
  };
};

const CaseItem = ({ articleItemData }: IArticleItem) => {
  return (
    <Link to={articleItemData.link}>
      <div className={Styles.article_item_context}>
        <div className={Styles.left}>
          <img
            src={getImageUrl(articleItemData.cover_link)}
            className={Styles.img}
            loading="lazy"
            alt=""
          ></img>
        </div>
        <div className={Styles.right}>
          <div className={Styles.title}>{articleItemData.title}</div>
          <div className={Styles.desc}>{articleItemData.description}</div>

        </div>
      </div>
    </Link>
  );
};

export default CaseItem;
