import { Modal, Card, Button, QRCode, message, ConfigProvider } from "antd";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import OK from "@/assets/common/ok.svg";
import NotOK from "@/assets/common/notOk.svg";
import store from "@/store";
import { closeRecharge } from "@/store/reducer/coinRecharge";
import useGlobalUserData from "@/hooks/useUserConfigHook";
import { getProductList, getUserGuideInfo } from "@/apis/common";
import {
  calculateDaysUntil,
  createImageUrl,
  formatPriceInCNY,
} from "@/utils/common";
import WechatPaySvg from "@/assets/common/wechatPay.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import { createOrder, getOrderInfo } from "@/apis/common";
import { setGlobalUserData } from "@/store/reducer/userConfig";
import useLoginHooks from "@/hooks/useLoginHooks";
import { openModal } from "@/store/reducer/loginConfig";
import QuestionDark from "@/assets/community/questionDark.svg";
import QuestionLight from "@/assets/community/questionLight.svg";
import useLoginEffect from "@/hooks/useLoginEffect";

const SuccessPng = createImageUrl("common/payMent.png");
const SuccessGif = createImageUrl("common/paySuccess.gif");

interface Product {
  billing_cycle: string;
  id: number;
  is_recommended: boolean;
  product_name: string;
  product_price_fen: number;
  product_detail: { has_entitlement: boolean; text: string }[];
  original_price_fen?: number;
  plan_type: number;
}

const ProductModal = () => {
  const [list, setList] = useState<Product[]>([]);
  const [tipShow, setTipShow] = useState(false);
  const [payInfo, setPayInfo] = useState<any>({
    show: false,
    info: {},
    url: "-1",
    isTimeOut: false,
  });
  const [success, setSuccess] = useState(false);
  const modalVisible =
    useSelector((state: any) => state?.coinRecharge.rechargeModalVisible) ||
    false;
  const { subscription_expiry_date } = useGlobalUserData();
  const [messageApi, contextHolder] = message.useMessage();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [showGif, setShowGif] = useState(true);
  const contentRef = useRef(null);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const { isLogin } = useLoginHooks();
  const theme = useThemeHooks();

  const handleClickBuy = (product: Product) => {
    setPayInfo({
      show: true,
      info: product,
      url: "-1",
      isTimeOut: false,
    });
    setSuccess(false);
    setShowGif(true);

    // 获取支付URL
    fetchPaymentUrl(product.id)
      .then(({ url, orderNo }) => {
        setPayInfo((prev: any) => ({
          ...prev,
          url: url,
        }));
        startPolling(orderNo); // 使用 orderNo 进行轮询
      })
      .catch((error) => {
        messageApi.error(`创建订单失败: ${error.message}`);
        handleClosePayModal();
      });
  };

  const handleClosePayModal = () => {
    setPayInfo({
      show: false,
      info: {},
      url: "-1",
      isTimeOut: false,
    });
    setSuccess(false);
    setShowGif(true);
  };

  const fetchPaymentUrl = async (productId: number) => {
    const response: any = await createOrder({ product_id: productId });
    if (response.data?.status === 200) {
      return {
        url: response.data.data.code_url,
        orderNo: response.data.data.order_no,
      };
    } else {
      throw new Error(
        response.data.message || response.message || "创建二维码失败"
      );
    }
  };

  const fetchPaymentStatus = async (orderNo: string) => {
    const response: any = await getOrderInfo({ order_no: orderNo });
    if (response.data.status === 200) {
      const { order_status, order_status_desc } = response.data.data;
      const status =
        order_status === 2
          ? "success"
          : order_status === 3
          ? "error"
          : // 轮询查询到wait 则什么都不做
            "wait";
      let reason: any = {};
      try {
        reason = order_status_desc
          ? JSON.parse(order_status_desc.replace(/'/g, '"'))
          : null;
      } catch (e) {
        console.log(e);
      }
      return { status, reason };
    } else {
      throw new Error(
        response.data.message ||
          response.message ||
          "订单状态查询失败, 反复出现该问题请联系管理员"
      );
    }
  };

  const startPolling = (orderNo: string) => {
    intervalIdRef.current = setInterval(() => {
      fetchPaymentStatus(orderNo)
        .then(({ status, reason }) => {
          if (status === "success") {
            setSuccess(true);
            clearInterval(intervalIdRef.current!);
            // 更新用户信息 也就是更新用户智纪币数量
            getUserGuideInfo().then((res) => {
              if (res && res?.status === 200) {
                store.dispatch(setGlobalUserData({ ...res.data.data }));
              }
            });
            // 动画播放完两秒后自动关闭所有弹窗
            setTimeout(() => {
              store.dispatch(closeRecharge());
              handleClosePayModal();
              messageApi.destroy();
            }, 4000);
            // 暂时后端没有超时这种状态 不支持超时刷新接口 但是功能已经开发 只要status === "timeout"就可以触发超时态
          } else if (status === "timeout") {
            setPayInfo((prev: any) => ({ ...prev, isTimeOut: true }));
            clearInterval(intervalIdRef.current!);
          } else if (status === "error") {
            messageApi.error(
              reason?.trade_state_desc
                ? `支付失败: ${reason?.trade_state_desc}`
                : "支付出现问题, 反复出现该情况请联系管理员~",
              0
            );
            handleClosePayModal();
            clearInterval(intervalIdRef.current!);
          }
        })
        .catch((error) => {
          messageApi.error(`${error.message}`);
          handleClosePayModal();
          clearInterval(intervalIdRef.current!);
        });
    }, 2000);
  };

  const stopPolling = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  useLoginEffect(() => {
      getProductList().then((res) => {
        if (res?.data?.status === 200) {
          setList(res.data.data);
        }
      });
  }, []);

  useEffect(() => {
    // 让弹窗显示但是没有登陆的时候 弹出登录弹窗 设置这个充值弹窗为关闭态
    if (modalVisible && !isLogin) {
      store.dispatch(openModal());
      store.dispatch(closeRecharge());
    }
  }, [modalVisible, isLogin]);

  useEffect(() => {
    // 关闭弹窗时 停止轮询
    if (!payInfo.show) {
      stopPolling();
    }
  }, [payInfo.show]);

  useEffect(() => {
    if (contentRef.current) {
      const { offsetWidth, offsetHeight } = contentRef.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, [payInfo]);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (success) {
      timer = setTimeout(() => {
        setShowGif(false);
      }, 2000); // 显示GIF图片2秒
    }
    return () => clearTimeout(timer); // 清理计时器
  }, [success]);

  const days = calculateDaysUntil(subscription_expiry_date);

  return (
    <ConfigProvider
      theme={{
        components: {
          Message: {
            zIndexPopup: 10002,
          },
        },
      }}
    >
      {contextHolder}
      <Modal
        // 登录时才根据这个值对弹窗做显隐 未登录时恒定不显示此弹窗
        open={isLogin ? modalVisible : false}
        onCancel={() => {
          store.dispatch(closeRecharge());
          messageApi.destroy();
        }}
        width={1000}
        footer={null}
        className={styles.modal}
        zIndex={10001}
      >
        <div className={styles.title}>选择套餐</div>
        <div className={styles.cardContainer}>
          {list.map((product) => {
            const {
              id,
              product_name,
              product_price_fen,
              is_recommended,
              product_detail,
              original_price_fen,
              plan_type,
            } = product;

            const price = (product_price_fen / 100).toFixed(2);
            const [integerPart, decimalPart] = price.split(".");
            const isPaidPlan = plan_type === 3 || plan_type === 4;
            const isCurrent = days > 0 && isPaidPlan;

            const linePrice = (original_price_fen || 0) / 100; // 防止 original_price_fen 是 NaN
            const linePriceStr =
              linePrice % 1 === 0 ? linePrice.toString() : linePrice.toFixed(2);

            const lineIntegerPart = linePriceStr.split(".")[0];
            const lineDecimalPart = linePriceStr.split(".")[1]; // 获取小数部分

            const displayLinePrice =
              lineDecimalPart === "00" ? lineIntegerPart : linePriceStr;

            const buttonText =
              days > 0
                ? plan_type === 1
                  ? isPaidPlan
                    ? "续费"
                    : "基础套餐"
                  : isPaidPlan
                  ? "续费"
                  : "订阅套餐"
                : plan_type === 1
                ? "当前方案"
                : isPaidPlan
                ? "订阅套餐"
                : "购买";

            return (
              <Card
                key={id}
                className={`${styles.card} ${
                  plan_type === 2 ? styles.pack : ""
                }`}
              >
                {is_recommended && <div className={styles.flag}>推荐</div>}
                <div className={styles.cardHeader}>{product_name}</div>
                <div className={styles.cardContent}>
                  <div className={styles.priceContainer}>
                    <span className={styles.currency}>¥</span>
                    <span className={styles.price}>{integerPart}</span>
                    {decimalPart !== "00" && (
                      <span className={styles.decimal}>.{decimalPart}</span>
                    )}
                    {plan_type !== 2 && (
                      <span className={styles.billingCycle}>
                        /{" "}
                        {plan_type === 1 || plan_type === 4
                          ? "年"
                          : plan_type === 3
                          ? "月"
                          : ""}
                      </span>
                    )}
                  </div>
                  <div className={styles.linePrice}>
                    {displayLinePrice !== "0" && `原价￥${displayLinePrice}`}
                  </div>
                  <div className={styles.cardFooter}>
                    <Button
                      className={styles.button}
                      disabled={plan_type === 1}
                      onClick={() => {
                        handleClickBuy(product);
                      }}
                    >
                      {buttonText}
                    </Button>
                    {isCurrent && (
                      <div className={styles.days}>
                        您的权益剩余{" "}
                        <span style={{ color: "#FF2E4D" }}>{days}</span> 天
                      </div>
                    )}
                  </div>
                  {product_detail.map(({ has_entitlement, text }, index) => (
                    <p key={index} className={styles.detail}>
                      <img
                        src={has_entitlement ? OK : NotOK}
                        alt=''
                        className={`${styles.icon} ${
                          !has_entitlement ? styles.no : ""
                        }`}
                      />
                      {text}
                    </p>
                  ))}
                </div>
              </Card>
            );
          })}
        </div>
        <div className={styles.rule}>
          <img src={theme !== "dark" ? QuestionDark : QuestionLight} alt='' />
          <div>智纪币消耗规则: 1次GPT4o/Claude3.5对话消耗1智纪币</div>
        </div>
        <div className={styles.tip}>
          订阅即表示同意
          <span
            style={{ color: "#00D44B", cursor: "pointer" }}
            onClick={() => {
              setTipShow(true);
            }}
          >
            《用户协议》
          </span>
        </div>
      </Modal>
      <Modal
        title='用户协议'
        onCancel={() => {
          setTipShow(false);
        }}
        open={tipShow}
        footer={null}
        className={styles.tipModal}
        width={600}
        centered
        zIndex={10002}
      >
        <div>充值条款和条件</div>
        <ol>
          <li>
            <strong>充值确认:</strong>{" "}
            用户在充值智纪币后，充值即视为成功完成。用户应在充值前仔细确认充值金额和相关信息，一旦充值成功，平台将不提供任何更改、修正或退款服务。
          </li>
          <li>
            <strong>智纪币使用规则:</strong>{" "}
            一个智纪币对应一次Claude3.5和GPT4o对话额度。
          </li>
          <li>
            <strong>不可退款:</strong>{" "}
            智纪币作为虚拟货币，一旦充值完成，用户将无法申请退款。用户理解并同意，充值智纪币后，平台不适用无理由退换政策。
          </li>
          <li>
            <strong>法律责任:</strong>{" "}
            用户不得以任何理由要求退款，包括但不限于误操作、未成年人消费等。平台保留在合理确认用户违反本协议的情况下，拒绝退款并依法追究法律责任的权利。
          </li>
          <li>
            <strong>特别说明: </strong>{" "}
            用户在提交充值申请前，请仔细阅读并理解本协议条款。一旦提交充值申请，即表示用户已阅读、理解并同意本协议的所有条款。
          </li>
        </ol>
      </Modal>
      <Modal
        open={payInfo.show}
        onCancel={handleClosePayModal}
        footer={null}
        className={styles.payModal}
        zIndex={10002}
        centered
        width={400}
      >
        <div className={styles.content} ref={!success ? contentRef : null}>
          {!success ? (
            <>
              <div className={styles.header}>
                扫码支付
                <span style={{ color: "#29CC6A" }}>
                  {formatPriceInCNY(payInfo.info.product_price_fen)}
                </span>
                元
              </div>
              <div className={styles.image}>
                <QRCode
                  size={200}
                  value={payInfo.url}
                  color={theme === "dark" ? "#e8e8e6" : "#272b30"}
                  bgColor={theme === "dark" ? "#10100f" : "#ffffff"}
                  status={
                    payInfo.url === "-1"
                      ? "loading"
                      : payInfo.isTimeOut
                      ? "expired"
                      : "active"
                  }
                  onRefresh={() => {
                    setPayInfo((prev: any) => ({
                      ...prev,
                      url: "-1",
                      isTimeOut: false,
                    }));
                    fetchPaymentUrl(payInfo.info.id).then(
                      ({ url, orderNo }) => {
                        setPayInfo((prev: any) => ({
                          ...prev,
                          url: url,
                          isTimeOut: false,
                        }));
                        startPolling(orderNo);
                      }
                    );
                  }}
                />
              </div>
              <div className={styles.footer}>
                请扫码完成支付
                <img src={WechatPaySvg} alt='' />
              </div>
            </>
          ) : (
            <div
              className={styles.paySuccess}
              style={
                success
                  ? { width: dimensions.width, height: dimensions.height }
                  : {}
              }
            >
              {showGif ? (
                <img
                  src={SuccessGif}
                  alt=''
                  style={{ width: 200, height: 200 }}
                />
              ) : (
                <img
                  src={SuccessPng}
                  alt=''
                  style={{ width: 200, height: 200 }}
                />
              )}
              <div className={styles.paymentText}>支付成功</div>
            </div>
          )}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default ProductModal;
