import baseURL from "@/request/getBaseURL";

interface ModelData {
  model_name: string;
  model_value: string;
  display_name: string;
  is_premium_model: boolean;
}

export const getImageUrl = (url?: string) => {
  const regex = new RegExp("^((https|http)?://)");
  let imgReg = new RegExp(
    /^\s*data:(?:[a-z]+\/[a-z0-9-+.]+(?:;[a-z-]+=[a-z0-9-]+)?)?(?:;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*?)\s*$/i
  );

  let mediaReg = new RegExp(/media/);

  if (!url) {
    return "";
  }

  if (imgReg.test(url)) {
    return url;
  }

  if (regex.test(url)) {
    return url;
  } else {
    let imgurl = baseURL + url;
    if (!mediaReg.test(url)) {
      imgurl = baseURL + "/media/" + url;
    }
    imgurl.replaceAll("//", "/");
    return imgurl;
  }
};

// 当要在项目中使用较大体积的图片/svg时，联系后端同学将文件上传至对应文件夹，然后使用这个方法获取url
export const createImageUrl = (filePath: string, endPointType = 'c' ) => {
  const baseUrl = 'https://synerais3.blob.core.windows.net/synerai/web';
  return `${baseUrl}/${endPointType}/assets/${filePath}`;
};

export const unique = (arr: Array<any>) => {
  return Array.from(new Set(arr));
};

export const numberFormat = (num?: number) => {
  if (!num) {
    return num;
  }

  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k";
  } else {
    return num;
  }
};

// 根据模型value获取实际id和名称
export const getModalName = (id: number | string, data?: ModelData[]): { modelName: string; modelValue: string; displayName: string; isPremiumModel: boolean } => {
  const numericId = id;
  
  if (!data) {
    if (numericId == 1035) {
      return {
        modelName: "gpt-4o-mini",
        modelValue: "1040",
        displayName: "GPT4omini",
        isPremiumModel: false,
      };
    } else if (numericId == 1040) {
      return {
        modelName: "gpt-4o",
        modelValue: "1040",
        displayName: "GPT4o",
        isPremiumModel: true,
      };
    } else if (numericId == 1041) {
      return {
        modelName: "claude-3.5",
        modelValue: "1041",
        displayName: "claude-3.5",
        isPremiumModel: true,
      };
    } else {
      return {
        modelName: "",
        modelValue: "",
        displayName: "",
        isPremiumModel: false,
      };
    }
  } else {
    const matchedModel = data.find((item: any) => item.model_value == numericId || item.model_name === id || item.display_name === id);
    if (matchedModel) {
      return {
        modelName: matchedModel.model_name,
        modelValue: matchedModel.model_value,
        displayName: matchedModel.display_name,
        isPremiumModel: matchedModel.is_premium_model,
      };
    } else {
      return {
        modelName: "",
        modelValue: "",
        displayName: "",
        isPremiumModel: false,
      };
    }
  }
};

// 分为单位的价格转化为元 如果小数点后都是0则直接省略小数点后内容
export const formatPriceInCNY = (fen: number) => {
  const yuan = fen / 100;
  const formattedPrice = yuan.toFixed(2);
  return formattedPrice.endsWith('.00') ? formattedPrice.slice(0, -3) : formattedPrice;
};

// 传入一个yyyy-mm-dd的时间 计算现在距离那天过去了多久
export const calculateDaysUntil = (dateStr: string): number => {
  try {
    const targetDate = new Date(dateStr);
    const today = new Date();
    const diff = targetDate.getTime() - today.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days > 0 ? days : 0;
  } catch {
    return 0;
  }
};
