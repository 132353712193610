import { useState, useEffect } from "react";
import { getCombatList } from "@/apis/common";
import Skeleton from "react-loading-skeleton";
import { Pagination } from "antd";
import "react-loading-skeleton/dist/skeleton.css";
import Styles from "./index.module.scss";
import ArticleItem from "./CardItem";

// 实践教程列表
const ActualList = () => {

    // 每页条数 不变设置为常量
    const pageSize = 12;

    // 当前页码
    const [pageNo, setPageNo] = useState(1);

    // 先定义list为空的12个数组项的数组 用于骨架屏 total为总页码
    const [data, setData] = useState({
        list: new Array(12).fill(undefined),
        total: undefined
    });

    // 设置加载状态
    const [loading, setLoading] = useState(false);

    // 分页器页码改变的回调
    const changePagination = (pageNo: number) => {
        setPageNo(pageNo);
    };

    // 页码改变后发起请求 更新当前页面
    useEffect(() => {
        setLoading(true);
        getCombatList({
            page_number: pageNo,
            page_size: pageSize,
        }).then((res) => {
            setLoading(false);
            if (res.data && res.data.status === 200) {
                setData({
                    list: res.data.data,
                    total: res.data.total_item
                });
            }else {
                // 请求不成功 设置空页面
                setData(preState => ({ ...preState, list: [] }));
            }
        });
    }, [pageNo]);

    return (
        <div className={Styles.article}>
            {data.list?.map((item, index) => {
                const { id, name, task_desc, image_url } = item || {};
                const data = {
                    title: name,
                    description: task_desc,
                    cover_link: image_url,
                    link: `/community/actual?id=${id}`
                };
                return (
                    <div className={Styles.article_item} key={index}>
                        {!loading && item ? (
                            <ArticleItem articleItemData={data as any} />
                        ) : (
                            <Skeleton width="100%" height={100} />
                        )}
                    </div>
                );
            })}

            <div className={Styles.pagination}>
                <Pagination
                    pageSize={pageSize}
                    current={pageNo}
                    total={data.total}
                    showSizeChanger={false}
                    // hideOnSinglePage
                    onChange={changePagination}
                />
            </div>
        </div>
    );

};

export default ActualList;