import {
  getJsonConfig,
  userGuideCompleted,
  getUserGuideInfo,
} from "@/apis/common";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Avatar from "@/assets/common/teacher.svg";
import classnames from "classnames";
import Typed from "react-typed";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import { useTranslation } from "react-i18next";
import confetti from "canvas-confetti";
import { createImageUrl } from "@/utils/common";
import useLoginHooks from "@/hooks/useLoginHooks";

const NewAgent = createImageUrl("menu/newAgent.png");

type props = {
  setIsGuideCompleted: (params: any) => void;
};

const Guide = ({ setIsGuideCompleted }: props) => {
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [questions, setQuestions] = useState([]);
  const [activeQuestions, setActiveQuestions] = useState<any[]>([]);
  const [isComplete, setIsComplete] = useState(false);
  const [userAvatar, setUserAvatar] = useState("");
  const [isAllComplete, setIsAllComplete] = useState(false);
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);
  const getQuestionsInfo = () => {
    getJsonConfig({ keys: ["beginner_tutorial"] }).then((res: any) => {
      if (res.data && res.data.status === 200) {
        const details = res.data.data.beginner_tutorial;
        setWelcomeMessage(details.welcome_message);
        setQuestions(details.question_list);
        setIsComplete(details.is_tutorial_completed);
      }
    });
  };

  const getUserAvatar = () => {
    getUserGuideInfo().then((res: any) => {
      if (res.data && res.data.status === 200) {
        if (res.data.data.avatar !== null) {
          setUserAvatar(res.data.data.avatar);
        }
      }
    });
  };

  const handleClickQuestion = (questionInfo: any) => {
    let updatedActiveQuestions;
    if (!activeQuestions.includes(questionInfo)) {
      updatedActiveQuestions = [...activeQuestions, questionInfo];
    }
    updatedActiveQuestions && setActiveQuestions(updatedActiveQuestions);
    if (updatedActiveQuestions && updatedActiveQuestions.length === 4) {
      setIsComplete(true);
      setIsShow(false);
    }
    if (updatedActiveQuestions && updatedActiveQuestions.length === 5) {
      setIsAllComplete(true);
    }
  };

  const skipStudy = () => {
    if (setIsGuideCompleted) {
      setIsGuideCompleted(true);
      userGuideCompleted();
    }
  };

  const finishStudy = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });

    if (setIsGuideCompleted) {
      userGuideCompleted();
      setIsGuideCompleted(true);
    }
  };

  useEffect(() => {
    getQuestionsInfo();
    getUserAvatar();
  }, []);

  const AvatarImg = (type: string) => {
    return (
      <div className={styles.avator_container}>
        <img
          src={
            type === "user"
              ? userAvatar === ""
                ? Avatar
                : userAvatar
              : NewAgent
          }
          alt='Avatar'
          width={24}
        />
        <div className={styles.avator_text}>
          {type === "user" ? t("common.you") : "智纪"}
        </div>
      </div>
    );
  };

  const handleClickYourZone = () => {
    setIsAllComplete(true);
  };

  return (
    <div className={styles.page}>
      <div className={styles.empty_home_page_title}>
        <div className={classnames("magictime", "spaceInDown")}>
          在智纪，开启您的
          <span className={styles.empty_home_page_title_green}>
            Agent设计师
          </span>
        </div>
        <div className={classnames("magictime", "spaceInDown")}>
          探索成长之旅
        </div>
        <div
          className={classnames(
            styles.empty_home_page_desc,
            "magictime",
            "spaceInDown"
          )}
        >
          <Typed
            strings={[
              "从入门到进阶，定制您的专属Agent设计师进修之路",
              "将有多位智能助理帮助你解决问题",
              "新智Syngents助手",
            ]}
            loop
            style={{ height: 25 }}
            typeSpeed={40}
            startDelay={1000} // 开始打字前的延迟时间
            backDelay={2000} // 开始删除文本前的延迟时间
          />
        </div>
      </div>
      <div className={styles.answerContent}>
        {AvatarImg("智纪")}
        <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[gfm]}>
          {welcomeMessage}
        </ReactMarkdown>
      </div>
      <div className={styles.box_container}>
        {activeQuestions
          .filter((item: any) => item.question !== "你的专区")
          .map((item: any, index: number) => (
            <div
              key={index}
              className={styles.full_width_box_container}
              onClick={() => handleClickQuestion(item)}
            >
              {AvatarImg("user")}
              <div className={styles.full_width_box_question}>
                {item && item.question}
              </div>
              {AvatarImg("智纪")}
              <div className={styles.full_width_box}>
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[gfm]}
                >
                  {item.answer_list[0].content.answer}
                </ReactMarkdown>
              </div>
            </div>
          ))}
      </div>
      <div className={styles.box_container}>
        {isShow &&
          questions
            .filter((item: any) => item.question !== "你的专区")
            .map((item: any, index: number) => {
              const isDisabled = activeQuestions.includes(item);
              return (
                <div
                  key={index}
                  className={classnames(styles.box, {
                    [styles.disable]: isDisabled,
                  })}
                  onClick={() => !isDisabled && handleClickQuestion(item)}
                >
                  {item && item.question}
                </div>
              );
            })}
      </div>
      {isComplete && (
        <div style={{ width: "100%", maxWidth: "730px" }}>
          {questions
            .filter((item: any) => item.question === "你的专区")
            .map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={classnames(
                    isAllComplete ? styles.complete : styles.your_zone_box
                  )}
                  onClick={() => handleClickYourZone()}
                >
                  {isAllComplete ? (
                    <div>
                      {AvatarImg("user")}
                      <div
                        style={{
                          color: " var(--theme-bg6)",
                          backgroundColor: "var(--theme-bg2)",
                          borderRadius: "12px",
                          padding: "20px",
                          lineHeight: "28px",
                          fontSize: "16px",
                          boxSizing: "border-box",
                          marginBottom: " 20px",
                          position: "relative",
                          width: "auto",
                          display: " inline-block",
                        }}
                      >
                        {item && item.question}
                      </div>
                      {AvatarImg("智纪")}
                      <div
                        style={{
                          color: "var(--theme-bg6)",
                          lineHeight: "28px",
                          fontSize: "16px",
                          boxSizing: "border-box",
                          marginBottom: "20px",
                          position: "relative",
                        }}
                        className={styles.your_zone_answer}
                      >
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[gfm]}
                        >
                          {item.answer_list[0].content.answer}
                        </ReactMarkdown>
                      </div>
                    </div>
                  ) : (
                    item && item.question
                  )}
                </div>
              );
            })}
        </div>
      )}
      {isAllComplete ? (
        <button className={styles.complete_button} onClick={finishStudy}>
          完成新手教程
        </button>
      ) : (
        <div className={styles.btn_container}>
          <button className={styles.skip_button} onClick={skipStudy}>
            跳过
          </button>
        </div>
      )}
    </div>
  );
};

export default Guide;
