import {
  getJsonConfig,
  likeStatus,
  toGetSeekingList,
  toGetTagData,
  toGetUserNames,
} from "@/apis/common";
import style from "./index.module.scss";
import CardItem from "@/components/CardItem";
import ToolItem from "@/components/ToolItem";
import { useEffect, useState } from "react";
import { unique } from "@/utils/common";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const SkeletonItem = () => {
  return (
    <div className={style.skeleton}>
      <Skeleton width={200} height={260} />
      <div className={style.skeleton_info}>
        <div>
          <Skeleton width={30} height={30} borderRadius="50%" />
        </div>
        <div className={style.skeleton_info_name}>
          <Skeleton width={100} />
          <Skeleton width={50} />
        </div>
      </div>
    </div>
  );
};

const RecommendItem = () => {
  const [data, setData] = useState(new Array(5).fill(undefined));
  const [toolData, setToolData] = useState(new Array(3).fill(undefined));

  const [loading, setLoading] = useState(false);
  const [userObj, setUserObj] = useState<any>([]);
  const [likeStatusObj, setLikeStatusObj] = useState({});
  const [tagNameObj, setTagNameObj] = useState({});

  const navigate = useNavigate();

  const [advancedLearn, setAdvancedLearn] = useState(
    new Array(5).fill(undefined)
  );

  const getSeekingList = (ids: any) => {
    const param: any = {
      ids,
    };

    // setData([...data, new Array(12).fill(undefined)]);
    toGetSeekingList(param).then((res) => {
      setLoading(true);
      if (res.data && res.data.status === 200) {
        let resdata = res.data.data;

        const cardList = resdata.filter((item: any) => {
          return item.show_mode === 2;
        });

        const toolList = resdata.filter((item: any) => {
          return item.show_mode === 1;
        });

        setData(cardList);
        setToolData(toolList);

        let tagList: Array<number> = [];
        let userIdsList: Array<number> = [];
        let agentIdList: Array<number> = [];

        resdata.forEach((item: any, index: number) => {
          if (item && item?.tags && item?.tags.length) {
            tagList = [...tagList, ...item.tags];
          }
          if (item && item.author) {
            userIdsList = [...userIdsList, item.author];
          }
          if (item && item.agent_id) {
            agentIdList = [...agentIdList, item.id];
          }
        });

        let newLikeStatusObj: any = { ...likeStatusObj };
        let tagObj: any = { ...tagNameObj };

        if (agentIdList.length) {
          likeStatus({
            pub_agent_ids: agentIdList,
          }).then((res: any) => {
            if (res.data && res.data.status === 200) {
              res.data.data.forEach((item: any, index: number) => {
                newLikeStatusObj[item.pub_agent_id] = item;
              });
            }
          });

          setTimeout(() => {
            setLikeStatusObj(newLikeStatusObj);
          });
        }

        if (userIdsList.length) {
          toGetUserNames({ ids: unique(userIdsList) }).then((res: any) => {
            if (res.data && res.data.status === 200 && res.data.data.length) {
              setUserObj([...userObj, ...res.data.data]);
            }
          });
        }
        if (tagList.length) {
          toGetTagData({ ids: unique(tagList) }).then((res: any) => {
            if (res.data && res.data.status === 200 && res.data.data.length) {
              res.data.data.forEach((item: any, index: number) => {
                tagObj[item.id] = item.name;
              });
              setTagNameObj(tagObj);
            }
          });
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getJsonConfig({
      keys: ["home_page_config"],
    }).then((res) => {
      if (res.data && res.data.status === 200) {
        const data = res.data?.data?.home_page_config;
        setAdvancedLearn(data?.advanced_learn);
        getSeekingList([...data?.knowledge_card_ids, ...data?.smart_tool_ids]);
      }
    });
  }, []);

  return (
    <div className={style.recommendWrap}>
      <div className={style.recommendItemWrap}>
        <div className={style.recommendItemTitleWrap}>
          <div>知识卡片</div>
          <div
            className={style.checkMore}
            onClick={() => {
              navigate("/aicard");
            }}
          >
            查看更多
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <g clip-path="url(#clip0_3693_1997)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2932 8.46466C10.1057 8.65219 10.0004 8.9065 10.0004 9.17166C10.0004 9.43683 10.1057 9.69113 10.2932 9.87866L12.4142 11.9997L10.2932 14.1207C10.1056 14.3082 10.0001 14.5625 10 14.8278C9.99991 15.0931 10.1052 15.3475 10.2927 15.5352C10.4802 15.7228 10.7346 15.8283 10.9998 15.8284C11.2651 15.8285 11.5196 15.7232 11.7072 15.5357L14.5362 12.7067C14.7237 12.5191 14.829 12.2648 14.829 11.9997C14.829 11.7345 14.7237 11.4802 14.5362 11.2927L11.7072 8.46466C11.5197 8.27719 11.2654 8.17188 11.0002 8.17188C10.735 8.17188 10.4807 8.27719 10.2932 8.46466Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_3693_1997">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div>
          <div className={style.page_recommend}>
            {data?.map((item, index) => {
              return (
                <div className={style.page_recommend_item} key={index}>
                  {loading ? (
                    <SkeletonItem />
                  ) : (
                    item && (
                      <CardItem
                        agentInfo={item}
                        tagNameObj={tagNameObj}
                        userObj={userObj}
                        likeStatusObj={likeStatusObj}
                        setLikeStatusObj={setLikeStatusObj}
                      />
                    )
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={style.recommendItemWrap}>
        <div className={style.recommendItemTitleWrap}>
          <div>系统学习</div>
          <div
            className={style.checkMore}
            onClick={() => {
              navigate("/advanced");
            }}
          >
            查看更多
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <g clip-path="url(#clip0_3693_1997)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2932 8.46466C10.1057 8.65219 10.0004 8.9065 10.0004 9.17166C10.0004 9.43683 10.1057 9.69113 10.2932 9.87866L12.4142 11.9997L10.2932 14.1207C10.1056 14.3082 10.0001 14.5625 10 14.8278C9.99991 15.0931 10.1052 15.3475 10.2927 15.5352C10.4802 15.7228 10.7346 15.8283 10.9998 15.8284C11.2651 15.8285 11.5196 15.7232 11.7072 15.5357L14.5362 12.7067C14.7237 12.5191 14.829 12.2648 14.829 11.9997C14.829 11.7345 14.7237 11.4802 14.5362 11.2927L11.7072 8.46466C11.5197 8.27719 11.2654 8.17188 11.0002 8.17188C10.735 8.17188 10.4807 8.27719 10.2932 8.46466Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_3693_1997">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div>
          <div className={style.page_recommend}>
            {advancedLearn?.map((item: any, index: number) => {
              return (
                <div className={style.page_recommend_item} key={index}>
                  {loading ? (
                    <SkeletonItem />
                  ) : (
                    item && (
                      <div
                        className={style.advanceWrap}
                        onClick={() => {
                          navigate(
                            `/advanced?id=${item.id}${
                              item?.article_id
                                ? "&articleID=" + item?.article_id
                                : ""
                            }`
                          );
                        }}
                      >
                        <img
                          src={item?.img_url}
                          alt=""
                          className={style.advanceImg}
                        />
                        <div className={style.advanceTitle}> {item?.title}</div>
                        <div className={style.advanceDesc}>{item?.desc}</div>
                      </div>
                    )
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={style.recommendItemWrap}>
        <div className={style.recommendItemTitleWrap}>
          <div>Agent中心</div>
          <div
            className={style.checkMore}
            onClick={() => {
              navigate("/aitool");
            }}
          >
            查看更多
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <g clip-path="url(#clip0_3693_1997)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2932 8.46466C10.1057 8.65219 10.0004 8.9065 10.0004 9.17166C10.0004 9.43683 10.1057 9.69113 10.2932 9.87866L12.4142 11.9997L10.2932 14.1207C10.1056 14.3082 10.0001 14.5625 10 14.8278C9.99991 15.0931 10.1052 15.3475 10.2927 15.5352C10.4802 15.7228 10.7346 15.8283 10.9998 15.8284C11.2651 15.8285 11.5196 15.7232 11.7072 15.5357L14.5362 12.7067C14.7237 12.5191 14.829 12.2648 14.829 11.9997C14.829 11.7345 14.7237 11.4802 14.5362 11.2927L11.7072 8.46466C11.5197 8.27719 11.2654 8.17188 11.0002 8.17188C10.735 8.17188 10.4807 8.27719 10.2932 8.46466Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_3693_1997">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div>
          <div className={style.page_tool_recommend}>
            {toolData?.map((item, index) => {
              return (
                <div className={style.page_recommend_tool_item} key={index}>
                  {loading ? (
                    <SkeletonItem />
                  ) : (
                    item && (
                      <ToolItem
                        agentInfo={item}
                        tagNameObj={tagNameObj}
                        userObj={userObj}
                        likeStatusObj={likeStatusObj}
                        setLikeStatusObj={setLikeStatusObj}
                        top={"-150%"}
                      />
                    )
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendItem;
