import { useEffect, useRef, useState } from "react";
import Styles from "./index.module.scss";
import ChatBox from "@/components/ChatBox";
import { Trans, useTranslation } from "react-i18next";
import classnames from "classnames";
import {
  toGetAgentList,
  toGetModalList,
  toGetMyAgentList,
  toGetPubAgentList,
  toGetTalkedPubAgentList,
  toGetModalHistory,
  toGetMyAgentHistory,
  toGetPubAgentHistory,
  toCreateModal,
  toCreateMyAgent,
  toCreatePubAgent,
  toDeleteTalkedPubAgent,
  toGetAgentPublicInfo,
  toGetAgentInfo,
} from "@/apis/common";
import useLoginHooks from "@/hooks/useLoginHooks";
import axios from "axios";
import { createImageUrl, getImageUrl } from "@/utils/common";
import CloseIcon from "@/assets/chat/close.svg";
import CloseLightIcon from "@/assets/chat/close-light.svg";
import { getModalName } from "@/utils/common";
import useThemeHooks from "@/hooks/useThemeHooks";
import addPic from "@/assets/chat/add.svg";
import addLightPic from "@/assets/chat/add-light.svg";
import Cookies from "js-cookie";
import { CloseOutlined } from "@ant-design/icons";
import Skeleton from "react-loading-skeleton";
import ConfirmModal from "@/components/Modal";
import { useSearchParams } from "react-router-dom";
import store, { loginOption } from "@/store";
import useConfigHooks from "@/hooks/useConfigHooks";

const agentBg = createImageUrl("common/agent-bg.png");
const ChatgptIcon = createImageUrl("chat/chatgpt.png");

let index = 1;

const Chat = () => {
  const { t } = useTranslation();

  const theme = useThemeHooks();

  const { isLogin } = useLoginHooks();

  const [loading, setLoading] = useState({
    toGetPubAgentList: false,
    getMyAgentTabList: false,
    getModalTabList: false,
  });

  const [current, setCurrent] = useState<any>({});

  const [currentAgent, setCurrentAgent] = useState<any>({});

  const [mode, setMode] = useState("agent");

  const [modalList] = useState([
    {
      id: 1000,
      model_ver_id: 1035,
      name: t("chat.withModal"),
      avatar_url: ChatgptIcon,
      ai_framework: 101,
    },
  ]);

  //当前点击的tab内容
  const [currentInfo, setCurrentInfo] = useState<any>({});

  const [myAgentList, setMyAgentList] = useState([]);

  const [pubAgentList, setPubAgentList] = useState([]);

  const [tabMenuList, setTabMenuList] = useState<any>([]);


  const [chatHistory, setChatHistory] = useState<any>([]);
  const [params] = useSearchParams();
  const publicId = params.getAll("publicId")?.[0] || "";
  const agentId = params.getAll("agentId")?.[0] || "";
  const type = params.getAll("type")?.[0] || "";
  const token = localStorage.getItem("token") || Cookies.get("token");
  const [deleteChatId, setDeleteChatId] = useState<any>(0);
  const ConfirmModalRef = useRef<any>(null);
  const [deleteAgentName, setDeleteAgentName] = useState("");
  const [firstShow, setFirstShow] = useState(true);
  const shouldShowSkeleton =
    !pubAgentList.length && !myAgentList.length && firstShow;
  const [agentInfo, setAgentInfo] = useState<any>({});

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const {
    config: { chat_model_list },
  } = useConfigHooks();

  const fromChat = async (list: any) => {
    let toChatInfoObj: any;
    let isSame = false;

    if (publicId || agentId) {
      let api = type === "private" ? toGetAgentInfo : toGetAgentPublicInfo;
      try {
        const res = await api({
          id: type === "private" ? Number(agentId) : Number(publicId),
        });

        if (res?.data?.status === 200 && res.data.data) {
          setAgentInfo(res.data.data);
          toChatInfoObj = res.data.data;

          if (list && list.length) {
            list.forEach((item: any) => {
              if (item.agent_id === agentInfo?.agent_id) {
                isSame = true;
              }
            });
          }

          const result =
            list && isSame
              ? [
                  toChatInfoObj
                    ? [...list, { ...toChatInfoObj, pub_agent: toChatInfoObj }]
                    : list,
                  { ...toChatInfoObj, pub_agent: toChatInfoObj },
                ]
              : toChatInfoObj
              ? [
                  [{ ...toChatInfoObj, pub_agent: toChatInfoObj }],
                  { ...toChatInfoObj, pub_agent: toChatInfoObj },
                ]
              : [[], {}];

          console.log(result, "返回的结果");
          return result;
        } else {
          return [[], {}];
        }
      } catch (error) {
        console.error("Error in fromChat:", error);
        return [[], {}];
      }
    } else {
      return [[], {}];
    }
  };

  const initSider = async () => {
    setLoading({ ...loading, toGetPubAgentList: true });
    try {
      const [myAgentRes, pubAgentRes] = await axios.all([
        toGetAgentList({
          page_number: 1,
          page_size: 100,
        }),
        toGetTalkedPubAgentList({
          page_number: 1,
          page_size: 100,
        }),
      ]);

      const fromChatResult: any = await fromChat(pubAgentRes.data.data);
      if (myAgentRes.data && myAgentRes.data.status === 200) {
        setMyAgentList(myAgentRes.data.data);
        if (type === "private") {
          // addTab(fromChatResult[1], "agent");
        }
      }

      if (pubAgentRes.data && pubAgentRes.data.status === 200) {
        if (type !== "private") {
          if (
            !!fromChatResult &&
            !!fromChatResult[1] &&
            !!fromChatResult[1].pub_agent
          ) {
            setCurrentAgent(fromChatResult[1]);
            setCurrentInfo(fromChatResult[1].pub_agent);
            addTab(fromChatResult[1], "pub_agent");
          } else {
            setCurrentAgent(modalList[0]);
            addTab(modalList[0], "modal");
          }
          if (fromChatResult) {
            setPubAgentList(fromChatResult[0]);
          }
        }
      }
    } catch (error) {
      console.error("Error initializing sider:", error);
    } finally {
      setLoading({ ...loading, toGetPubAgentList: false });
      setFirstShow(false);
    }
  };

  const updatePubAgentList = () => {
    setLoading({ ...loading, toGetPubAgentList: true });
    toGetTalkedPubAgentList({
      page_number: 1,
      page_size: 100,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          const fromChatResult: any = fromChat(res.data.data);
          if (fromChatResult) {
            setPubAgentList(fromChatResult[0]);
          }
          setLoading({ ...loading, toGetPubAgentList: false });
          return res.data.data;
        }
        setLoading({ ...loading, toGetPubAgentList: false });
        setFirstShow(false);
      })
      .catch(() => {
        setLoading({ ...loading, toGetPubAgentList: false });
      });
  };

  const doDelete = () => {
    setLoading({ ...loading, toGetPubAgentList: true });
    toDeleteTalkedPubAgent({ pub_agent_id: deleteChatId })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          setLoading({ ...loading, toGetPubAgentList: false });
          updatePubAgentList();
          return;
        }
        setLoading({ ...loading, toGetPubAgentList: false });
      })
      .catch(() => {
        setLoading({ ...loading, toGetPubAgentList: false });
      });
  };

  const onDelete = (item: any) => {
    setDeleteChatId(item.id);
    setDeleteAgentName(item.name);
    ConfirmModalRef?.current?.showModal();
  };

  const getTab: any = (item: any, type: string) => {
    if (type === "pub_agent") {
      return getPubAgentTabList(item);
    } else if (type === "agent") {
      return getMyAgentTabList(item);
    } else if (type === "modal") {
      return getModalTabList(item);
    }
  };

  const getPubAgentTabList = (item: any) => {
    setLoading({ ...loading, getMyAgentTabList: true });
    return toGetPubAgentList({
      page_number: 1,
      page_size: 4,
      pub_agent_id: item.id,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          setLoading({ ...loading, getMyAgentTabList: false });
          return res.data.data;
        }
        setLoading({ ...loading, getMyAgentTabList: false });
      })
      .catch(() => {
        setLoading({ ...loading, getMyAgentTabList: false });
      });
  };

  const getMyAgentTabList = (item: any) => {
    setLoading({ ...loading, getMyAgentTabList: true });
    return toGetMyAgentList({
      page_number: 1,
      page_size: 4,
      agent_id: item.id,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          setLoading({ ...loading, getMyAgentTabList: false });
          return res.data.data;
        }
        setLoading({ ...loading, getMyAgentTabList: false });
      })
      .catch(() => {
        setLoading({ ...loading, getMyAgentTabList: false });
      });
  };

  const getModalTabList = (item: any) => {
    setLoading({ ...loading, getModalTabList: true });
    return toGetModalList({
      page_number: 1,
      page_size: 4,
      llm_id: item.id,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          setLoading({ ...loading, getModalTabList: false });
          return res.data.data;
        }
        setLoading({ ...loading, getModalTabList: false });
      })
      .catch(() => {
        setLoading({ ...loading, getModalTabList: false });
      });
  };

  const addNewTab = (item: any, type: string, menuList?: any) => {
    const newTabMenuList = menuList ? [...menuList] : [...tabMenuList];
    const menuItem = {
      ...item,
      topicId: "newChat" + index,
    };
    if (type === "agent") {
      menuItem.agentId = item.id;
    }
    if (type === "pub_agent") {
      menuItem.pubAgentId = item.id;
    }
    menuItem.type = type;
    newTabMenuList.push(menuItem);
    setMode(type);
    setCurrent(menuItem);
    setChatHistory([]);
    index++;
    setTabMenuList(newTabMenuList);
  };

  const addTab = (item: any, type: string) => {
    if (currentAgent.id === item.id) {
      return false;
    }
    setCurrentAgent({ ...item, type });
    if (type) {
      if (token) {
        getTab(item, type).then((res: any) => {
          if (res && res.length) {
            res.forEach((item1: any, index: number) => {
              if (item1.topic_id) {
                item1.topicId = item1.topic_id;
                item1.type = type;
                // if (type === "modal") {
                item1.avatar_url = item.avatar_url;
                // }
                if (type === "agent") {
                  item1.agentId = item.id;
                }
                if (type === "pub_agent") {
                  item1.pubAgentId = item.id;
                  const newUrl = new URL(window.location.href);
                  newUrl.searchParams.set('publicId', item.id);
                  window.history.pushState({}, '', newUrl);
                }
              }
            });
            setTabMenuList(res.reverse());
            console.log(res[0]);
            setCurrent(res[0]);
            setMode(type);
            changeHistory(res[0]);
          } else {
            setTabMenuList([]);
            addNewTab(item, type, []);
          }
        });
      } else {
        setTabMenuList([]);
        addNewTab(item, type, []);
      }
    }
  };

  const closeTab = (index: number) => {
    const newTabMenuList = [...tabMenuList];
    newTabMenuList.splice(index, 1);
    setTabMenuList(newTabMenuList);
  };

  const changeTab = (item: any) => {
    if (item.topicId !== current.topicId) {
      changeHistory(item);
      setCurrent(item);
    }
  };

  const changeHistory = (item: any) => {
    if (
      item.topicId &&
      !(typeof item.topicId === "string" && item.topicId.includes("newChat"))
      // &&
      // !(item.topicId === current.topicId)
    ) {
      if (item.type === "pub_agent") {
        getPubAgentHistory(item);
      } else if (item.type === "agent") {
        getMyAgentHistory(item);
      } else if (item.type === "modal") {
        getModalHistory(item);
      }
    } else {
      setChatHistory([]);
    }
  };

  const createTopic = () => {
    if (token) {
      if (current.type === "pub_agent") {
        return createPubAgent();
      } else if (current.type === "agent") {
        return createMyAgent();
      } else if (current.type === "modal") {
        return createModal();
      }
    } else {
      return Promise.resolve({
        id: -1,
      });
    }
  };

  const addSuccess = (topicId: number, oldTopicId: number) => {
    const newTabMenuList = [...tabMenuList];
    newTabMenuList.forEach((item, index) => {
      if (item.topicId === oldTopicId) {
        item.topicId = topicId;
      }
    });
    setTabMenuList(newTabMenuList);
    const newCurrent = { ...current, topicId };
    setCurrent(newCurrent);
  };

  const changeHistoryAction = (data: any) => {
    if (data && data.length) {
      setChatHistory(data);
    } else {
      setChatHistory([]);
    }
  };

  const getModalHistory = (item: any) => {
    setLoading({ ...loading, getModalTabList: true });
    toGetModalHistory({
      page_number: 1,
      page_size: 100,
      topic_id: item.topicId,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          setLoading({ ...loading, getModalTabList: false });
          changeHistoryAction(res.data.data.reverse());
        } else {
          setLoading({ ...loading, getModalTabList: false });
        }
      })
      .catch(() => {
        setLoading({ ...loading, getModalTabList: false });
      });
  };

  const getPubAgentHistory = (item: any) => {
    setLoading({ ...loading, toGetPubAgentList: true });
    toGetPubAgentHistory({
      page_number: 1,
      page_size: 30,
      topic_id: item.topicId,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          setLoading({ ...loading, toGetPubAgentList: false });
          changeHistoryAction(res.data.data.reverse());
        } else {
          setLoading({ ...loading, toGetPubAgentList: false });
        }
      })
      .catch(() => {
        setLoading({ ...loading, toGetPubAgentList: false });
      });
  };

  const getMyAgentHistory = (item: any) => {
    setLoading({ ...loading, toGetPubAgentList: true });
    toGetMyAgentHistory({
      page_number: 1,
      page_size: 30,
      topic_id: item.topicId,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          setLoading({ ...loading, toGetPubAgentList: false });
          changeHistoryAction(res.data.data.reverse());
        } else {
          setLoading({ ...loading, toGetPubAgentList: false });
        }
      })
      .catch(() => {
        setLoading({ ...loading, toGetPubAgentList: false });
      });
  };

  const createPubAgent = () => {
    return toCreatePubAgent({
      pub_agent_id: current.id,
      name: current.name,
    }).then((res) => {
      let data;
      if (res.data && res.data.status === 200) {
        addSuccess(res.data.data.id, current.topicId);
        data = res.data.data;
      }
      return data;
    });
  };

  const createMyAgent = () => {
    return toCreateMyAgent({
      agent_id: current.id,
      name: current.name,
    }).then((res) => {
      let data;
      if (res.data && res.data.status === 200) {
        addSuccess(res.data.data.id, current.topicId);
        data = res.data.data;
      }
      return data;
    });
  };

  const createModal = () => {
    return toCreateModal({
      llm_id: current.id,
      name: current.name,
      model_ver_id: 1035,
    }).then((res) => {
      let data;
      if (res.data && res.data.status === 200) {
        addSuccess(res.data.data.id, current.topicId);
        data = res.data.data;
      }
      return data;
    });
  };

  const modalChoose = (topicId: number, modalId: number) => {
    const newCurrent = { ...current };
    if (newCurrent.topicId === topicId) {
      newCurrent.model_ver_id = modalId;
    }
    setCurrent(newCurrent);

    const newTabMenuList = [...tabMenuList];
    newTabMenuList.forEach((item, index) => {
      if (item.topicId === topicId) {
        item.model_ver_id = modalId;
      }
    });

    setTabMenuList(newTabMenuList);
  };

  useEffect(() => {
    const token = localStorage.getItem("token") || Cookies.get("token");
    if (token) {
      initSider();
    } else {
      // const fromChatResult: any = fromChat(false);
      // if (
      //   !!fromChatResult &&
      //   !!fromChatResult[1] &&
      //   !!fromChatResult[1].pub_agent
      // ) {
      //   setCurrentAgent(fromChatResult[1]);
      //   addTab(fromChatResult[1], "pub_agent");
      // } else {
      //   console.log(modalList[0], 123);
      //   setCurrentAgent(modalList[0]);
      //   addTab(modalList[0], "modal");
      // }
      // if (fromChatResult) {
      //   setPubAgentList(fromChatResult[0]);
      // }
      onLoginAndRegister();
    }
  }, [token]);

  const SkeletonMenuItem = () => {
    return (
      <div className={Styles.skeleton_menu}>
        <Skeleton width={248} height={64} style={{ marginBottom: 28 }} />
      </div>
    );
  };

  const SkeletonContentTab = () => {
    return (
      <div className={Styles.skeleton_tab}>
        <div className={Styles.skeleton_tab_avatar}>
          <Skeleton width={32} height={32} circle />
        </div>
        <div>
          <Skeleton width={600} height={22} style={{ borderRadius: 20 }} />
        </div>
      </div>
    );
  };

  const SkeletonContent = () => {
    return (
      <div className={Styles.skeleton_content}>
        <div className={Styles.skeleton_title}>
          <div className={Styles.skeleton_title_avatar}>
            <Skeleton width={32} height={32} circle />
          </div>
          <div>
            <Skeleton width={607} height={22} />
          </div>
        </div>
        <div className={Styles.skeleton_questions}>
          <Skeleton
            width={640}
            height={16}
            count={4}
            style={{ marginBottom: 8 }}
          />
          <Skeleton width={474} height={16} />
        </div>
        <div className={Styles.skeleton_hints}>
          <div className={Styles.skeleton_hint}>
            <Skeleton width={400} height={56} style={{ borderRadius: 10 }} />
          </div>
          <div className={Styles.skeleton_hint}>
            <Skeleton width={400} height={56} style={{ borderRadius: 10 }} />
          </div>
          <div className={Styles.skeleton_hint}>
            <Skeleton width={400} height={56} style={{ borderRadius: 10 }} />
          </div>
          <div className={Styles.skeleton_hint}>
            <Skeleton width={400} height={56} style={{ borderRadius: 10 }} />
          </div>
        </div>
        <div>
          <Skeleton
            width={900}
            height={54}
            style={{ borderRadius: 10, marginBottom: 80 }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.page}>
      <div className={Styles.page_menu}>
        <div className={Styles.page_menu_dropdown}>
          {/* <div className={Styles.page_menu_title}>{t("chat.withModal")}</div> */}
          <div className={Styles.page_menu_inner}>
            {modalList.map((item: any, index) => {
              return (
                <div
                  className={classnames(
                    Styles.page_menu_item,
                    item.id === currentAgent.id && "modal" === currentAgent.type
                      ? Styles.page_menu_item_active
                      : ""
                  )}
                  onClick={() => {
                    setCurrentInfo(item);
                    addTab(item, "modal");
                  }}
                  key={item.id}
                >
                  <img
                    className={Styles.page_menu_item_logo}
                    src={getImageUrl(item?.avatar_url) || agentBg}
                    loading='lazy'
                  ></img>
                  <div>
                    <div className={Styles.page_menu_item_title}>
                      {item.name}
                    </div>
                    <div className={Styles.page_menu_item_next}>
                      最近一次的聊天内容
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={Styles.page_menu_dropdown}>
          {!!myAgentList.length && (
            <div className={Styles.page_menu_inner}>
              {myAgentList.map((item: any, index) => (
                <div
                  className={classnames(
                    Styles.page_menu_item,
                    item.id === currentAgent.id && "agent" === currentAgent.type
                      ? Styles.page_menu_item_active
                      : ""
                  )}
                  key={item.id}
                  onClick={() => {
                    setCurrentInfo(item);
                    addTab(item, "agent");
                  }}
                >
                  <img
                    className={Styles.page_menu_item_logo}
                    src={getImageUrl(item?.avatar_url) || agentBg}
                    loading='lazy'
                  />
                  <div>
                    <div className={Styles.page_menu_item_title}>
                      {item.name}
                    </div>
                    <div className={Styles.page_menu_item_next}>
                      {item.intro}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {!!pubAgentList.length && (
            <div className={Styles.page_menu_inner}>
              {pubAgentList.map((item: any, index) => (
                <div
                  className={classnames(
                    Styles.page_menu_item,
                    item.pub_agent.id === currentAgent.id &&
                      "pub_agent" === currentAgent.type
                      ? Styles.page_menu_item_active
                      : ""
                  )}
                  key={item.id}
                  onClick={() => {
                    setCurrentInfo(item.pub_agent);
                    addTab(item.pub_agent, "pub_agent");
                  }}
                >
                  <img
                    className={Styles.page_menu_item_logo}
                    src={getImageUrl(item?.pub_agent.avatar_url) || agentBg}
                    loading='lazy'
                  />
                  <div>
                    <div className={Styles.page_menu_item_title}>
                      {item.pub_agent.name}
                    </div>
                    <div className={Styles.page_menu_item_next}>
                      {item.pub_agent.intro}
                    </div>
                  </div>
                  <div
                    className={Styles.page_menu_item_delete}
                    onClick={() => onDelete(item.pub_agent)}
                  >
                    <CloseOutlined />
                  </div>
                </div>
              ))}
            </div>
          )}

          {shouldShowSkeleton && isLogin && (
            <div className={Styles.page_menu_inner}>
              {Array(8)
                .fill(null)
                .map((_, index) => (
                  <SkeletonMenuItem key={index} />
                ))}
            </div>
          )}
          {/* <div className={Styles.page_menu_title}>{t("chat.withAgent")}</div> */}
          {/* <div className={Styles.page_menu_title}>{t("公共Agent")}</div> */}
          {/* <div className={Styles.page_menu_title}>{t("我的Agent")}</div> */}
        </div>
      </div>
      <div className={Styles.page_context}>
        {(loading.getMyAgentTabList || loading.getModalTabList || firstShow) &&
        isLogin ? (
          <SkeletonContentTab key={index} />
        ) : (
          <div className={Styles.page_context_tab}>
            {!!tabMenuList.length &&
              tabMenuList.map((item: any, index: number) => {
                // const displayName = getModalName(item.model_ver_id, chat_model_list).displayName;
                return (
                  <div
                    className={classnames(
                      Styles.page_context_tab_item,
                      item.topicId === current.topicId
                        ? Styles.page_context_tab_item_active
                        : ""
                    )}
                    onClick={() => {
                      changeTab(item);
                    }}
                    key={index}
                  >
                    <img
                      className={Styles.page_context_tab_logo}
                      src={getImageUrl(current?.avatar_url) || agentBg}
                      loading='lazy'
                    />
                    <div>
                      <div>{item.name}</div>
                      {/* {item.model_ver_id && (
                        <div className={Styles.gpt_name}>
                          {"@" + displayName}
                        </div>
                      )} */}
                    </div>
                    <span
                      className={Styles.page_context_tab_close}
                      onClick={() => {
                        closeTab(index);
                      }}
                    >
                      <img
                        src={theme === "dark" ? CloseIcon : CloseLightIcon}
                        className={Styles.page_context_tab_close_icon}
                      />
                    </span>
                  </div>
                );
              })}
            {tabMenuList.length < 4 && tabMenuList.length > 0 && isLogin && (
              <div
                className={Styles.add_box}
                onClick={() => {
                  addNewTab(currentAgent, currentAgent.type);
                }}
              >
                <img src={theme === "dark" ? addPic : addLightPic} />
              </div>
            )}
          </div>
        )}
        {
          <div className={Styles.chat_box}>
            {loading.getMyAgentTabList ||
            loading.getModalTabList ||
            (firstShow && isLogin) ? (
              <SkeletonContent />
            ) : (
              !!tabMenuList.length && (
                <ChatBox
                  scene="talking"
                  mode={mode}
                  boxStyle='chat'
                  topicId={current.topicId}
                  agentId={current.agentId}
                  pubAgentId={current.pubAgentId}
                  history={chatHistory}
                  firstChat={createTopic}
                  currentInfo={currentInfo}
                  botAvator={getImageUrl(current?.avatar_url)}
                  botName={current?.name}
                  modalChoose={modalChoose}
                  modalId={current?.model_ver_id}
                  frameId={currentAgent?.ai_framework}
                  receiverInfo={currentAgent?.flow_config?.receiver?.[0]}
                  questionEx={
                    currentAgent.question_samples
                      ? currentAgent.question_samples
                      : []
                  }
                  greeting={current?.greeting}
                />
              )
            )}
          </div>
        }
      </div>
      <ConfirmModal
        ref={ConfirmModalRef}
        confirmFn={doDelete}
        title={t("chat.delete.title")}
      >
        <Trans i18nKey='chat.delete.content' values={{ name: deleteAgentName }}>
          {{ deleteAgentName }}
        </Trans>
      </ConfirmModal>
    </div>
  );
};

export default Chat;
