import { IAgentInfo } from "@/types/common";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { useTranslation } from "react-i18next";
import outlinebtnPic from "@/assets/explore/outlinebtn.svg";
import fillbtnPic from "@/assets/explore/fillbtn.svg";
import dayjs from "dayjs";
import Styles from "../index.module.scss";
import { toGetAgentPublicInfo } from "@/apis/common";
import { useNavigate } from "react-router-dom";
import { getSkillsName } from "@/components/Create/list";
import LikePic from "@/assets/common/like.svg";
import LikeActivePic from "@/assets/common/like-active.svg";
import { numberFormat } from "@/utils/common";
import qs from "qs";

const agentBg = createImageUrl('common/agent-bg.png');
const Avatar = createImageUrl('menu/avator.png');

type ISeekingDetail = {
  agentInfo: IAgentInfo;
  tagNameObj: any;
  userObj: any;
  currentWidth: any;
  likeStatusObj: any;
  setLikeStatusObj: any;
  likeAction: any;
  curId: any;
  isSelfItem: boolean;
  top?: string;
};
const SeekingItemDetail = ({
  agentInfo,
  tagNameObj,
  userObj,
  currentWidth,
  likeStatusObj,
  setLikeStatusObj,
  likeAction,
  curId,
  isSelfItem,
  top,
}: ISeekingDetail) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  if (typeof agentInfo.skills === "string" && agentInfo.skills) {
    agentInfo.skills = JSON.parse(agentInfo.skills.replace(/'/g, '"'));
  }

  const createSame = () => {
    localStorage.setItem("createAgentInfo", "");
    toGetAgentPublicInfo({
      id: agentInfo.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          const createAgentInfo = res.data.data;

          localStorage.setItem(
            "createAgentInfo",
            JSON.stringify(createAgentInfo)
          );

          navigate(
            `/workflow?${qs.stringify({
              step: "create",
              publicId: agentInfo.id,
              type: "public",
            })}`
          );
        }
      }
    });
  };

  const toChat = () => {
    if (agentInfo?.jump_url) {
      window.open(agentInfo?.jump_url);
      return;
    }
    localStorage.setItem("toChatInfo", "");
    toGetAgentPublicInfo({
      id: agentInfo.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          // const toChatInfo = _.pick(res.data.data, [
          //   "name",
          //   "intro",
          //   "agent_type",
          //   "greeting",
          //   "flow_config",
          //   "skills",
          //   "avatar_url",
          // ]);
          const toChatInfo = res.data.data;

          localStorage.setItem("toChatInfo", JSON.stringify(toChatInfo));

          navigate(
            `/Chat?${qs.stringify({
              publicId: agentInfo.id,
              type: "public",
            })}`
          );
        }
      }
    });
  };
  const findUserById = (id: any) => {
    if (!userObj) {
      return null;
    }
    
    const user = userObj.find((user: any) => user.id === id);
    return user ? { username: user.username, avatar: user.avatar } : null;
  };
  return (
    <div
      className={Styles.agent_detail}
      // style={{
      //   left: HPosition === "left" ? "30%" : "unset",
      //   right: HPosition === "right" ? "30%" : "unset",
      //   top: VPosition === "top" ? "0" : "unset",
      //   bottom: VPosition === "bottom" ? "0" : "unset",
      // }}
      style={{
        left: -(372 - currentWidth) / 2 + "px",
        ...(top ? { top: top } : {}),
      }}
    >
      <div className={Styles.agent_detail_img_box}>
        <img
          src={getImageUrl(agentInfo?.avatar_url) || agentBg}
          onError={(e: any) => {
            e.target.src = agentBg;
          }}
          className={Styles.agent_detail_img}
          loading="lazy"
        ></img>
        <div className={Styles.agent_detail_img_mask}>
          <div className={Styles.user_info_box}>
            <div className={Styles.user_info_box_inner}>
              <div className={Styles.user_info_box_avatar}>
                <img
                  className={Styles.avator}
                  src={findUserById(agentInfo?.author)?.avatar || Avatar}
                  loading="lazy"
                ></img>
                <div>
                  <div className={Styles.name}>{agentInfo?.name}</div>
                  <div className={Styles.author}>
                    @{findUserById(agentInfo?.author)?.username || "智纪用户"}
                  </div>
                </div>
              </div>
              {isSelfItem && !agentInfo.pub_agent_id ? null : (
                <div className={Styles.like}>
                  <img
                    src={
                      likeStatusObj[curId || ""]?.is_my_like
                        ? LikeActivePic
                        : LikePic
                    }
                    onClick={likeAction}
                  ></img>
                  {numberFormat(likeStatusObj[curId || ""]?.like_count || 0)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.agent_detail_info}>
        <div>
          <div className={Styles.agent_detail_intro}>{agentInfo.intro}</div>
          {!!agentInfo?.tags && !!agentInfo?.tags.length && (
            <div className={Styles.agent_detail_skill}>
              <span>{t("common.tags")}</span>
              <div className={Styles.agent_detail_skill_list}>
                {agentInfo?.tags?.map((item: any, index: number) => {
                  return (
                    <div className={Styles.agent_detail_skill_item} key={index}>
                      {tagNameObj[item] || ""}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* {!!agentInfo?.question_samples &&
            !!agentInfo?.question_samples.length &&
            !!agentInfo?.question_samples[0] && (
              <div className={Styles.agent_detail_tool}>
                <span>{t("publish.question.example")}</span>
                <div className={Styles.agent_detail_tool_list}>
                  {agentInfo?.question_samples?.map(
                    (item: string, index: number) => {
                      return (
                        <div className={Styles.agent_detail_tool_item}>
                          {item}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )} */}
          {!!agentInfo?.skills &&
            !!agentInfo?.skills.length &&
            !!agentInfo?.skills[0] && (
              <div className={Styles.agent_detail_tool}>
                <span>{t("create.agent.tools")}</span>
                <div className={Styles.agent_detail_tool_list}>
                  {agentInfo?.skills?.map((item: string, index: number) => {
                    return (
                      <div className={Styles.agent_detail_tool_item}>
                        {getSkillsName(item)}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
        </div>
        <div>
          <div className={Styles.agent_detail_date}>
            <div>
              {t("common.publish.time")}&nbsp;
              {dayjs(agentInfo.create_time).format("YYYY/MM/DD")}
            </div>
            <div>
              {t("common.update.time")}&nbsp;
              {dayjs(agentInfo.update_time).format("YYYY/MM/DD")}
            </div>
          </div>
          <div className={Styles.btn_box}>
            <div
              className={Styles.btn_to_chat}
              onClick={() => {
                toChat();
              }}
            >
              <img src={fillbtnPic}></img>
              <div className={Styles.btn_text}>{t("common.join.chat")}</div>
            </div>

            {agentInfo?.jump_url ? null : (
              <div
                className={Styles.btn_to_create}
                onClick={() => {
                  createSame();
                }}
              >
                <img src={outlinebtnPic}></img>
                <div className={Styles.btn_text}>{t("common.create.same")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeekingItemDetail;
