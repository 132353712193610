import { Button, Modal, message } from "antd";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import store from "@/store";
import { closeCoin } from "@/store/reducer/coinRecharge";
import { claimCoin } from "@/apis/common";
import { setCoinNumber } from "@/store/reducer/userConfig";
import { useState } from "react";
import useConfigHooks from "@/hooks/useConfigHooks";
import useLoginHooks from "@/hooks/useLoginHooks";

const CoinModal = () => {
  const [loading, setLoading] = useState(false);
  const modalInfo =
    useSelector((state: any) => state?.coinRecharge.coinModal) || false;
  const { visible, isFirst } = modalInfo;
  const { id } = useLoginHooks();

  const {
    config: { daily_login_coins, first_login_coins },
  } = useConfigHooks();

  const submitGetCoin = async () => {
    setLoading(true);
    claimCoin(["daily_login_coins", "first_login_coins"]).then((res) => {
      if (res?.data?.status === 200) {
        const todayKey =
        "canClaimCoinToday_" + id +'_' + new Date().toISOString().split("T")[0]; // 生成今天的日期作为key
        const count = res?.data.data.coin_count;
        store.dispatch(setCoinNumber(count));
        message.success("领取成功");
        localStorage.setItem(todayKey, "true");
      } else {
        message.error("领取失败");
      }
      store.dispatch(closeCoin());
    });
    setLoading(false);
  };

  return (
    <Modal
      centered
      width={400}
      open={visible}
      wrapClassName={styles.modal}
      title={null}
      footer={null}
      maskClosable={false}
      closable={false}
      onCancel={() => {
        store.dispatch(closeCoin());
      }}
    >
      <div className={styles.wrap}>
        <div className={styles.welcome}>欢迎登录</div>
        <div className={styles.welcomeText}>
          {!isFirst ? (
            `今日登录获得了 ${daily_login_coins} 个智纪币`
          ) : (
            <>
              首次登录获得了 {first_login_coins} 个智纪币以及
              <br />
              每日获得了 {daily_login_coins} 个智纪币
            </>
          )}
        </div>
        <Button
          className={styles.btn}
          onClick={submitGetCoin}
          disabled={loading}
        >
          一键领取
        </Button>
      </div>
    </Modal>
  );
};
export default CoinModal;
