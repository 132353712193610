import Home from "@/pages/Home";
import HomeChatPage from "@/pages/HomeChatPage";
import CardList from "@/pages/CardList";
import ToolList from "@/pages/ToolList";
import Chat from "@/pages/Chat";
import Canvas from "@/pages/Canvas";
import Community from "@/pages/Community";
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import Advanced from "@/pages/Advanced";
// import Create from "@/components/Create";
// import Publish from "@/components/Publish";
import Workflow from "@/pages/Workflow";
import MyAgent from "@/pages/MyAgent";
import { routerList } from "@/types/common";
import Actual from "@/pages/Actual";
import { Navigate } from "react-router-dom";

const routerMap: routerList = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/HomeChatPage",
    element: <HomeChatPage />,
  },
  {
    path: "/advanced",
    element: <Advanced />,
  },
  {
    path: "/aicard",
    element: <CardList />,
  },
  {
    path: "/aitool",
    element: <ToolList />,
  },
  {
    path: "/chat",
    element: <Chat />,
  },
  {
    path: "/canvas",
    element: <Canvas />,
  },
  {
    path: "/community",
    element: <Community />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  // {
  //   path: "/create",
  //   element: <Create />,
  // },
  // {
  //   path: "/publish",
  //   element: <Publish />,
  // },
  {
    path: "/workflow",
    element: <Workflow />,
  },
  {
    path: "/my_agent",
    element: <MyAgent />,
  },
  {
    path: "/community/actual",
    element: <Actual />,
  },
  {
    path: "/actual",
    element: <Navigate to="/community/actual" replace />,
  },
];

export { routerMap };
