import { Link } from "react-router-dom";
import chat from "@/assets/menu/chat.svg";
import chatLight from "@/assets/menu/chat-light.svg";
import chatActive from "@/assets/menu/chatActive.svg";
import chatLightActive from "@/assets/menu/chat-lightActive.svg";
import community from "@/assets/menu/community.svg";
import communityLight from "@/assets/menu/community-light.svg";
import communityActive from "@/assets/menu/communityActive.svg";
import communityLightActive from "@/assets/menu/community-lightActive.svg";
import explore from "@/assets/menu/explore.svg";
import exploreLight from "@/assets/menu/explore-light.svg";
import exploreActive from "@/assets/menu/exploreActive.svg";
import exploreLightActive from "@/assets/menu/explore-lightActive.svg";
import card from "@/assets/menu/card.svg";
import cardLight from "@/assets/menu/card-light.svg";
import cardActive from "@/assets/menu/cardActive.svg";
import cardLightActive from "@/assets/menu/card-lightActive.svg";
import special from "@/assets/menu/special.svg";
import specialLight from "@/assets/menu/special-light.svg";
import specialLightActive from "@/assets/menu/special-lightActive.svg";
import specialActive from "@/assets/menu/specialActive.svg";
import study from "@/assets/menu/study.svg";
import studyActive from "@/assets/menu/studyActive.svg";
import studyLight from "@/assets/menu/study-light.svg";
import studyLightActive from "@/assets/menu/study-lightActive.svg";
import myAgent from "@/assets/menu/myAgent.svg";
import myAgentLight from "@/assets/menu/myAgent-light.svg";
import myAgentActive from "@/assets/menu/myAgentActive.svg";
import myAgentLightActive from "@/assets/menu/myAgent-lightActive.svg";
import i18n from "@/translation/i18n";
const isMobile = window?.innerWidth <= 600;

const sidebar = isMobile
  ? [
      {
        key: "special",
        link: "/",
        label: i18n.t("common.special"),
        icon: special,
        iconLight: specialLight,
        activeIcon: specialActive,
        lightActiveIcon: specialLightActive,
        desc: "当您带着问题进入你的专区时，您可以在检索框输入问题寻找答案。智能检索模式下，大模型会根据您的问题自动选择联网检索或知识库检索；在自定义检索模式下，你可以自主选择检索方式。当您没有具体问题时，您可以浏览你的专区获得平台各个模块优质内容的个性化推荐。",
      },
    ]
  : [
      {
        key: "special",
        link: "/",
        label: i18n.t("common.special"),
        icon: special,
        iconLight: specialLight,
        activeIcon: specialActive,
        lightActiveIcon: specialLightActive,
        desc: "当您带着问题进入你的专区时，您可以在检索框输入问题寻找答案。智能检索模式下，大模型会根据您的问题自动选择联网检索或知识库检索；在自定义检索模式下，你可以自主选择检索方式。当您没有具体问题时，您可以浏览你的专区获得平台各个模块优质内容的个性化推荐。",
      },
      {
        key: "advanced",
        link: "/advanced",
        label: i18n.t("系统学习"),
        icon: study,
        iconLight: studyLight,
        activeIcon: studyActive,
        lightActiveIcon: studyLightActive,
        desc: "在系统学习版块中，你可以在专属AI导师的带领下开启AI Agent设计师及AI大模型相关知识和应用的个性化学习之旅。学习内容包括智纪开发课程和AI知识。",
      },
      {
        key: "aicard",
        link: "/aicard",
        label: i18n.t("common.aicard"),
        icon: card,
        iconLight: cardLight,
        activeIcon: cardActive,
        lightActiveIcon: cardLightActive,
        desc: "知识卡片是一个碎片化学习社区，可以浏览最新AI资讯，前沿技术发展等。您也可以创建自己的知识卡片。每个知识卡片点开后都使用了RAG技术，以agent为技术基础。",
      },
      {
        key: "aitool",
        link: "/aitool",
        label: i18n.t("common.seeking"),
        icon: explore,
        iconLight: exploreLight,
        activeIcon: exploreActive,
        lightActiveIcon: exploreLightActive,
        desc: "Agent中心为您提供各种生产力工具，您可以使用他人已经创建好的Agent；也可以选择创建同款Agent，学习优秀的Agent设计技巧；也可以直接创建属于自己的Agent来帮助自己执行工作任务。",
      },
      {
        key: "community",
        link: "/community",
        label: i18n.t("common.community"),
        icon: community,
        iconLight: communityLight,
        activeIcon: communityActive,
        lightActiveIcon: communityLightActive,
        desc: "通过真实的业务场景，带你一步步掌握如何设计出高效的agent，再也不怕理论知识不会用，碎片信息学了就忘。 例如第一关将带领大家学习如何设计为电商网站写商品描述的Agent。",
      },
      {
        key: "chat",
        link: "/chat",
        label: i18n.t("common.chat"),
        icon: chat,
        iconLight: chatLight,
        activeIcon: chatActive,
        lightActiveIcon: chatLightActive,
      },
    ];

const userSiderBar = isMobile
  ? []
  : [
      {
        key: "myAgent",
        link: "/my_agent",
        label: i18n.t("common.myAgent"),
        iconLight: myAgentLight,
        icon: myAgent,
        activeIcon: myAgentActive,
        lightActiveIcon: myAgentLightActive,
      },
    ];

export { sidebar, userSiderBar };
