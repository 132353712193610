import { IFolderInfo } from "@/types/common";
import { ListType } from "./type";
import Styles from "./index.module.scss";
import {
  // Tag,
  Button,
} from "antd";
import FileSvg from "@/assets/knowledge/flieIcon.svg";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import NoContent from "@/components/NoContent";
import { createImageUrl } from "@/utils/common";

const folderPic = createImageUrl('knowledge/folder.png');

/**
 * FolderContent组件的属性类型
 */
interface FolderContentProps {
  folders: IFolderInfo[]; // 文件夹数组
  handleFolderClick: (id: number, name?: string, user_id?: number) => void; // 处理文件夹点击事件
  handleEditFolderClick: (
    folder: IFolderInfo
  ) => (event: React.MouseEvent<HTMLElement>) => void; // 处理编辑文件夹事件
  handleDeleteKnowledgeFolder: (
    folder: IFolderInfo
  ) => (event: React.MouseEvent<HTMLElement>) => void; // 处理删除文件夹事件
  type?: ListType; // 列表类型
  requiredData?: { tag?: string; pub_scope?: number };
  selectedFolderList?: number[];
  isModal?: boolean;
}

/**
 * FolderContent组件
 * 负责展示文件夹列表
 */
const FolderContent: React.FC<FolderContentProps> = ({
  folders,
  handleFolderClick,
  handleEditFolderClick,
  handleDeleteKnowledgeFolder,
  type,
  requiredData,
  selectedFolderList,
  isModal,
}) => {
  const showBottomInfo = !isModal;
  console.log("selectedFolderList", selectedFolderList, "folders", folders);
  return (
    <>
      {!!folders?.length ? (
        folders?.map((folder: IFolderInfo, index: number) => {
          // 文件夹属性解构
          const {
            name,
            // tags,
            file_count,
            img_url,
            create_user_name,
            pub_scope,
            desc,
            id,
            user_id
          } = folder;

          return (
            <div
              key={index} // 使用index作为key
              onClick={() => handleFolderClick(id, name, user_id)} // 处理点击事件
              className={
                type === ListType.insert
                  ? Styles.folderItemWrapperInsert
                  : Styles.folderItemWrapper
              }
            >
              <div
                className={Styles.folderItem}
                style={
                  selectedFolderList?.some((item: any) => item.id === id)
                    ? {
                        border: "1px solid #29CC6A",
                      }
                    : {}
                }
              >
                <div
                  className={Styles.content}
                  style={
                    isModal
                      ? {
                          marginBottom: 0,
                        }
                      : {}
                  }
                >
                  <div
                    className={Styles.image}
                    style={
                      isModal
                        ? {
                            width: "48px",
                            height: "48px",
                            flex: "0 0 48px",
                          }
                        : {}
                    }
                  >
                    <img
                      src={img_url || folderPic} // 使用文件夹图片或默认图片
                      onClick={() => handleFolderClick(id, name, user_id)} // 处理点击事件
                      alt=""
                      className={Styles.folderImg}
                    />
                  </div>
                  <div className={Styles.text}>
                    <div className={Styles.folder_title}>
                      <span>{name}</span>
                    </div>
                    {desc && <div className={Styles.description}>{desc}</div>}
                    {/* <div className={Styles.tags}>
                      {tags?.map((item, tagIndex) => (
                        <Tag
                          key={tagIndex} // 使用tagIndex作为key
                          style={{ color: "var(--color-primary-1)" }}
                          color='rgba(0, 122, 147, 0.04)'
                        >
                          {item}
                        </Tag>
                      ))}
                    </div> */}
                    {isModal &&
                      selectedFolderList?.some(
                        (item: any) => item.id === id
                      ) && (
                        <div
                          style={{
                            position: "absolute",
                            right: "16px",
                            bottom: "16px",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Checkbox/Active/Default">
                              <path
                                id="Plate"
                                d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
                                fill="#29CC6A"
                              />
                              <path
                                id="check"
                                d="M3.91308 7.03516H3.91326C3.99256 7.03525 4.07081 7.05331 4.14213 7.08798C4.21341 7.12262 4.27591 7.17295 4.32497 7.2352L3.91308 7.03516ZM3.91308 7.03516H3.09394C2.89031 7.03516 2.7766 7.26975 2.90232 7.4292L2.90237 7.42927L6.11214 11.4957L6.11218 11.4957C6.32247 11.7619 6.72568 11.7611 6.93667 11.4962L6.93708 11.4957L12.6597 4.24398C12.6598 4.24378 12.66 4.24358 12.6602 4.24338C12.7884 4.08295 12.6699 3.85 12.4689 3.85H11.6498C11.4894 3.85 11.3367 3.9235 11.2376 4.05044C11.2375 4.05055 11.2374 4.05066 11.2373 4.05077L6.52403 10.0216M3.91308 7.03516L6.52403 10.0216M6.52403 10.0216L4.32506 7.23531L6.52403 10.0216Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.3"
                              />
                            </g>
                          </svg>
                        </div>
                      )}
                  </div>
                </div>
                {showBottomInfo ? (
                  <div className={Styles.bottomInfo}>
                    <div className={Styles.folderInfo}>
                      {!!create_user_name && requiredData?.pub_scope === 2 && (
                        <div className={Styles.user}>
                          {create_user_name}创建
                        </div>
                      )}
                      {file_count !== undefined && (
                        <div className={Styles.count}>
                          <img src={FileSvg} alt="" />
                          <span>{file_count}</span>
                        </div>
                      )}
                    </div>
                    {/* 当前tab不为企业共享才支持编辑 */}
                    {type !== ListType.insert &&
                      requiredData?.pub_scope !== 2 && (
                        <div className={Styles.actions}>
                          <Button
                            className={Styles.button}
                            icon={<EditOutlined />}
                            onClick={(event) => {
                              event.stopPropagation(); // 阻止事件冒泡
                              handleEditFolderClick(folder)(event);
                            }}
                          >
                            编辑
                          </Button>
                          <Button
                            className={Styles.delete_button}
                            icon={<DeleteOutlined />}
                            onClick={(event) => {
                              event.stopPropagation(); // 阻止事件冒泡
                              handleDeleteKnowledgeFolder(folder)(event);
                            }}
                          >
                            删除
                          </Button>
                        </div>
                      )}
                  </div>
                ) : null}
                {pub_scope === 2 && <div className={Styles.flag}>已共享</div>}
              </div>
            </div>
          );
        })
      ) : (
        <NoContent />
      )}
    </>
  );
};

export default FolderContent;
