import React, { useEffect, useRef, useState } from "react";
import App from "./components/App";
import KnowledgeContent from "@/components/KnowledgeContent";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import AppPic from "@/assets/myAgent/app.svg";
import KnowledgePic from "@/assets/myAgent/knowledge.svg";
import { deleteAllQuery, getUrlParams } from "@/utils/url";
import classnames from "classnames";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListType } from "@/components/KnowledgeContent/component/FolderList/type";
import Styles from "./index.module.scss";
import addPic from "@/assets/myAgent/add.svg";
import editPortraitPic from "@/assets/common/edit_portrait.svg";
import editPic from "@/assets/common/edit_name.svg";
import editHPic from "@/assets/common/edit_name_h.svg";
import crownPic from "@/assets/common/crown.svg";
import crownGPic from "@/assets/common/g_crown.svg";
import emailPic from "@/assets/common/email.svg";
import wxinPic from "@/assets/common/wxin.svg";
import { getUserGuideInfo, editUserInfoApi } from "@/apis/common";
import { Input, message, Upload } from "antd";
import Cookies from "js-cookie";
import hostArr from "@/proxy";
import useThemeHooks from "@/hooks/useThemeHooks";
import Avatar from "@/assets/common/teacher.svg";
import store from "@/store";
import { changeGlobalUserData } from "@/store/reducer/userConfig";
import useLoginHooks from "@/hooks/useLoginHooks";
import { calculateDaysUntil } from "@/utils/common";
import { openModal } from "@/store/reducer/loginConfig";

const MyAgent = () => {
  const { t } = useTranslation();
  const [pageType, setPageType] = useState<string>("app");
  const location = useLocation();
  const listRef = useRef<any>(null);
  const [showAddBtn, setShowAddBtn] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({
    nick_name: "智纪用户",
    avatar: Avatar,
    subscription_plan_type: 1, // "1": "基础版","2": "尊享版"
  });
  const [category] = useState<any>([
    {
      id: "app",
      name: t("myagent.app"),
      icon: AppPic,
    },
    {
      id: "knowledge",
      name: t("myagent.repository"),
      icon: KnowledgePic,
    },
    // {
    //   id: "tool",
    //   name: t("myagent.tools"),
    //   icon: ToolPic,
    //   disabled: true,
    // },
  ]);
  const [params] = useSearchParams();

  const { isLogin } = useLoginHooks();

  const tab = params.getAll("tab")?.[0] || "app";
  const addFolder = params.getAll("addFolder")?.[0] || "0";
  const inputRef = useRef<any>(null);
  const mode: string = process.env.REACT_APP_ENV || "prod";
  let baseURL: any = hostArr[mode].file;
  const theme = useThemeHooks();

  const handleAddFolderClick = (event?: React.MouseEvent) => {
    listRef.current?.handleAddFolderClick(event);
  };

  const changeTypeCallback = (flag: string) => {
    setShowAddBtn(flag === "folder" ? true : false);
  };

  const getUserInfo = () => {
    getUserGuideInfo().then((res: any) => {
      if (res.data && res.data.status === 200) {
        setUserInfo(res.data.data);
      }
    });
  };

  const handleBlur = () => {
    setIsEdit(false);
    editUserInfo(userInfo.avatar);
  };

  const editUserInfo = (avatar: string) => {
    editUserInfoApi({
      nick_name: userInfo.nick_name,
      avatar: avatar,
    }).then((res: any) => {
      if (res.data && res.data.status === 200) {
        message.success("修改成功");
        store.dispatch(
          changeGlobalUserData({ avatar: avatar } as { avatar: string } & void)
        );
      }
    });
  };

  const customRequest = ({ file }: any) => {
    // 在这里自定义上传请求
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", "c/user/avatar");
    formData.append("env", "c");
    const token = localStorage.getItem("token") || Cookies.get("token");

    // 添加令牌到请求头中
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    fetch(`${baseURL}/upload/image/`, {
      method: "POST",
      headers,
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        // 处理上传成功的响应
        if ((res.status = 200)) {
          setUserInfo({
            ...userInfo,
            avatar: res.data.file_url,
          });
          editUserInfo(res.data.file_url);
          message.success("上传成功");
        } else {
          message.error("上传失败");
        }
      })
      .catch((error) => {
        console.log(error);
        // 处理上传失败的情况
        message.error("上传失败");
      });
  };

  const beforeUpload = (file: any) => {
    const isImage = file.type.startsWith("image/");
    if(!isLogin) {
      message.error("未登录!");
      store.dispatch(openModal())
      return null;
    }
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  };

  const userInfoRender = () => {
    return (
      <div className={Styles.userInfo}>
        <div className={Styles.portrait_box}>
          <Upload
            name='avatar'
            accept='image/jpeg, image/png, image/jpg, image/svg'
            action={"upload/image"}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
            showUploadList={false}
          >
            <img
              src={userInfo.avatar || Avatar}
              alt=''
              className={Styles.portrait}
            />
            {isLogin && <img src={editPortraitPic} alt='' className={Styles.editPortrait} />}
          </Upload>
        </div>
        <div>
          <div className={Styles.nameInfo}>
            {!isEdit ? (
              <div className={Styles.nameWrap}>
                {userInfo.nick_name}
                {isLogin && (
                  <img
                    src={theme === "dark" ? editHPic : editPic}
                    alt=''
                    className={Styles.editPic}
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  />
                )}
              </div>
            ) : (
              <div className={Styles.editWrap}>
                <Input
                  ref={inputRef}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setUserInfo({
                      ...userInfo,
                      nick_name: e.target.value,
                    });
                  }}
                  onPressEnter={handleBlur}
                  onBlur={handleBlur}
                  className={Styles.input}
                  value={userInfo.nick_name}
                />
              </div>
            )}
            {userInfo.subscription_plan_type === 2 && !isEdit ? (
              <div className={Styles.detail}>
                您的<span className={Styles.detailInfo}>尊享套餐</span>
                期限还有{calculateDaysUntil(userInfo.subscription_expiry_date)}
                天
              </div>
            ) : null}
          </div>
          <div>
            <div className={Styles.nameInfo_bom}>
              {userInfo.subscription_plan_type === 2 ? (
                <span>
                  <img src={crownPic} alt='' className={Styles.crownPic} />
                  尊享版
                </span>
              ) : (
                <span>
                  <img src={crownGPic} alt='' className={Styles.crownPic} />
                  基础版
                </span>
              )}
              {!!userInfo.user_source && <span className={Styles.line}></span>}
              {userInfo.user_source === "web" ? (
                <span>
                  <img src={emailPic} alt='' className={Styles.crownPic} />
                  邮箱：{userInfo.third_login_unique_id}
                </span>
              ) : userInfo.user_source === "weixin" ? (
                <span>
                  <img src={wxinPic} alt='' className={Styles.crownPic} />
                  微信登陆
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setPageType(tab);
  }, [tab, addFolder]);

  useEffect(() => {
    if (addFolder === "1") {
      handleAddFolderClick();
      deleteAllQuery();
    }
  }, [addFolder, pageType]);

  useEffect(() => {
    getUserInfo();
    const params: any = getUrlParams(window.location.href);

    if (params.pageType) {
      setPageType(params.pageType);
    } else {
      setPageType("app");
    }
  }, [location, isLogin]);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  return (
    <div className={classnames(Styles.page, "scroll-hide")}>
      {userInfoRender()}
      <div className={Styles.category}>
        {category.map((item: any, index: number) => (
          <div
            key={index}
            className={classnames(
              Styles.category_item,
              item.disabled && Styles.category_item_disabled
            )}
            onClick={() => {
              if (!item.disabled) {
                setPageType(item.id);
              }
            }}
          >
            <div className={classnames(Styles.category_item_name_box)}>
              <img
                src={item.icon}
                className={classnames(
                  Styles.category_img,
                  pageType === item.id && Styles.category_img_active
                )}
                loading='lazy'
                alt='category icon'
              />
              <div
                className={classnames(
                  Styles.category_name,
                  pageType === item.id && Styles.category_name_active
                )}
              >
                {item.name}
              </div>
            </div>
            <div
              className={classnames(
                Styles.category_item_bottom_active,
                pageType === item.id && Styles.category_item_bottom
              )}
            />
          </div>
        ))}
      </div>
      {pageType === "app" && <App />}
      {pageType === "knowledge" && (
        <>
          {showAddBtn && (
            <div className={Styles.page_list_item_add}>
              <div
                className={Styles.page_list_item_add_inner}
                onClick={(e) => handleAddFolderClick(e)}
              >
                <img src={addPic} alt='' />
                <span>{t("knowledge.newFolder")}</span>
              </div>
            </div>
          )}
          <KnowledgeContent
            type={ListType.page}
            ref={listRef}
            changeType={changeTypeCallback}
          />
        </>
      )}
    </div>
  );
};

export default MyAgent;
