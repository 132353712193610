import { getImageUrl } from "@/utils/common";
import { mainQuestionList } from "@/apis/common";
import { useEffect, useState } from "react";
import useConfigHooks from "@/hooks/useConfigHooks";
import style from "./index.module.scss";
import classnames from "classnames";
import Typed from "react-typed";
import { Input, Popover, Switch } from "antd";
import { useTranslation } from "react-i18next";
import SendPic from "@/assets/chat/send-light.svg";
import SendPicDark from "@/assets/chat/send.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import RecommendItem from "./recommendItem";
import AiSearchInput from "@/components/AiSearchInput";
import useLoginHooks from "@/hooks/useLoginHooks";
import store, { loginOption } from "@/store";

const RecommendPage = (props: any) => {
  const { setShowRecommend } = props;
  const { config } = useConfigHooks();
  const [chatMsg, setChatMsg] = useState("");
  const { t } = useTranslation();
  const theme = useThemeHooks();
  const [webSearch, setWebSearch] = useState(true);
  const [knowledgeSearch, setKnowledgeSearch] = useState(true);
  const [smartSearch, setSmartSearch] = useState(true);
  const { isLogin } = useLoginHooks();
  const onChat = () => {
    if (!isLogin) {
      store.dispatch(loginOption.openModal());
      return;
    }
    setShowRecommend(false);
    localStorage.setItem("curChatMsg", chatMsg);
  };

  useEffect(() => {
    if (smartSearch) {
      setWebSearch(true);
      setKnowledgeSearch(true);
    }
  }, [smartSearch]);

  return (
    <div className={style.page}>
      <div className={style.empty_home_page_title}>
        <div className={classnames("magictime", "spaceInDown")}>
          <div>
            在智纪，开启您的
            <span className={style.empty_home_page_title_green}>
              Agent设计师
            </span>
          </div>
          <div>探索成长之旅</div>
        </div>
        <div
          className={classnames(
            style.empty_home_page_desc,
            "magictime",
            "spaceInDown"
          )}
        >
          <Typed
            strings={[
              "从入门到进阶，定制您的专属AI技能学习路径",
              "将有多位智能助理帮助你解决问题",
              "新智Syngents助手",
            ]}
            loop
            typeSpeed={40}
            startDelay={1000} // 开始打字前的延迟时间
            backDelay={2000} // 开始删除文本前的延迟时间
          />
        </div>
      </div>
      <AiSearchInput setChatMsg={setChatMsg} onChat={onChat}></AiSearchInput>
      <RecommendItem />
    </div>
  );
};

export default RecommendPage;
