import { IAgentInfo } from "@/types/common";
import Styles from "./index.module.scss";
import classnames from "classnames";
import { Carousel } from "antd";
import ChatBox from "@/components/ChatBox";
import CardClosePic from "@/assets/card/card-close.svg";
import CardCloseLightPic from "@/assets/card/card-close-light.svg";
import LeftArrowPic from "@/assets/card/left-arrow.svg";
import RightArrowPic from "@/assets/card/right-arrow.svg";
import { CloseOutlined } from "@ant-design/icons";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { useEffect, useRef, useState } from "react";
import useThemeHooks from "@/hooks/useThemeHooks";
import {
  toGetAgentPublicInfo,
  toGetPubAgentHistory,
  toGetAgentInfo,
  toGetMyAgentHistory,
} from "@/apis/common";

const agentBg = createImageUrl('common/agent-bg.png');

type ICardItemDetail = {
  agentInfo: IAgentInfo;
  tagNameObj: any;
  // userNameObj: any;
  showDetail: boolean;
  setShowDetail: any;
  isSelfItem?: boolean;
};

const CardItemDetail = ({
  agentInfo,
  tagNameObj,
  // userNameObj,
  showDetail,
  setShowDetail,
  isSelfItem,
}: ICardItemDetail) => {
  const [show, setShow] = useState(false);
  const carousel = useRef<any>(null);
  const [carouselIndex, setCarouselIndex] = useState(1);
  const [cardImages, setCardImages] = useState([]);
  const [chatHistory, setChatHistory] = useState<any>([]);
  const [magnifier, setMagnifier] = useState(false);

  const theme = useThemeHooks();
  const [activeTab, setActiveTab] = useState("1");
  const [url, setUrl] = useState("");

  const onChange = (currentSlide: number, next: number) => {
    setCarouselIndex(next + 1);
  };

  const changeHistoryAction = (data: any) => {
    if (data && data.length) {
      setChatHistory(data);
    } else {
      setChatHistory([]);
    }
  };

  const getPubAgentHistory = () => {
    if (isSelfItem) {
      toGetMyAgentHistory({
        page_number: 1,
        page_size: 30,
        agent_id: agentInfo.id,
        topic_id: -2,
      })
        .then((res) => {
          if (res.data && res.data.status === 200) {
            changeHistoryAction(res.data.data.reverse());
          } else {
          }
        })
        .catch(() => {});
      return;
    }

    toGetPubAgentHistory({
      page_number: 1,
      page_size: 30,
      agent_id: agentInfo.id,
      topic_id: -2,
    })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          changeHistoryAction(res.data.data.reverse());
        } else {
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    setShow(showDetail);
  }, [showDetail]);

  const isCarouselArray = () => {
    return Array.isArray(cardImages) && cardImages!.length > 1;
  };

  useEffect(() => {
    window.history?.pushState({ path: url }, "", url);
  }, [url]);

  const removeQueryParams = (url: string) => {
    const index = url.indexOf("?");
    if (index !== -1) {
      return url.substring(0, index);
    }
    return url;
  };

  useEffect(() => {
    if (showDetail) {
      if (isSelfItem) {
        toGetAgentInfo({
          id: agentInfo.id,
        }).then((res: any) => {
          if (res && res.data && res.data.status === 200) {
            let newCardImages: any = [];

            if (
              res.data.data &&
              res.data.data?.flow_config?.card_images.length > 0
            ) {
              newCardImages = res.data.data?.flow_config?.card_images.filter(
                (item: any) => item !== ""
              );
            }
            setCardImages(newCardImages || []);
          }
        });
        getPubAgentHistory();
        return;
      }

      toGetAgentPublicInfo({
        id: agentInfo.id,
      }).then((res: any) => {
        if (res && res.data && res.data.status === 200) {
          let newCardImages: any = [];
          let url = window.location.href;
          if (url.includes("publicId")) {
            url = removeQueryParams(url);
          }
          setUrl(url + `?publicId=${agentInfo.id}`);
          if (
            res.data.data &&
            res.data.data?.flow_config?.card_images.length > 0
          ) {
            newCardImages = res.data.data?.flow_config?.card_images.filter(
              (item: any) => item !== ""
            );
          }
          setCardImages(newCardImages || []);
        }
      });
      getPubAgentHistory();
    }
  }, [showDetail]);

  return show ? (
    <div
      className={classnames(
        Styles.card_detail,
        show ? Styles.card_detail_show : Styles.card_details_box_hidden
      )}
    >
      <div className={Styles.card_details_box}>
        <div className={Styles.card_details_inner}>
          {agentInfo?.flow_config?.card_video && (
            <div className={Styles.switchWrap}>
              <div
                className={
                  activeTab === "1" ? Styles.activeTab : Styles.normalTab
                }
                onClick={() => setActiveTab("1")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M1.04126 9.99977C1.04126 7.62376 1.98513 5.34506 3.66522 3.66497C5.34531 1.98488 7.624 1.04102 10 1.04102C12.376 1.04102 14.6547 1.98488 16.3348 3.66497C18.0149 5.34506 18.9588 7.62376 18.9588 9.99977C18.9588 12.3758 18.0149 14.6545 16.3348 16.3346C14.6547 18.0146 12.376 18.9585 10 18.9585C7.624 18.9585 5.34531 18.0146 3.66522 16.3346C1.98513 14.6545 1.04126 12.3758 1.04126 9.99977ZM11.2563 7.48727C10.7088 7.14977 10.2488 6.86602 9.86876 6.68977C9.48126 6.51102 9.04126 6.37602 8.58126 6.51727C8.27929 6.61203 8.00822 6.78598 7.79626 7.02102C7.48626 7.36102 7.38376 7.80602 7.33751 8.25102C7.29126 8.69352 7.29126 9.26727 7.29126 9.96352V10.036C7.29126 10.7323 7.29126 11.306 7.33751 11.7485C7.38376 12.1935 7.48626 12.6385 7.79626 12.9785C8.00876 13.2135 8.27876 13.3885 8.58251 13.4823C9.04126 13.6248 9.48251 13.4885 9.86876 13.3098C10.2488 13.1348 10.7088 12.8498 11.2563 12.511L11.3238 12.4685C11.8913 12.1185 12.365 11.8248 12.7088 11.5523C13.0625 11.271 13.3725 10.9385 13.485 10.476C13.56 10.1635 13.56 9.83602 13.485 9.52352C13.3725 9.06102 13.0625 8.72852 12.7088 8.44851C12.365 8.17477 11.8913 7.88227 11.3238 7.53102L11.2563 7.48852V7.48727Z"
                    fill="currentColor"
                  />
                </svg>
                视频
              </div>
              <div
                className={
                  activeTab === "1" ? Styles.normalTab : Styles.activeTab
                }
                onClick={() => setActiveTab("2")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M2.15016 15.8419L2.1335 15.8586C1.9085 15.3669 1.76683 14.8086 1.7085 14.1919C1.76683 14.8002 1.92516 15.3502 2.15016 15.8419Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.50018 8.65026C8.59554 8.65026 9.48351 7.76229 9.48351 6.66693C9.48351 5.57156 8.59554 4.68359 7.50018 4.68359C6.40481 4.68359 5.51685 5.57156 5.51685 6.66693C5.51685 7.76229 6.40481 8.65026 7.50018 8.65026Z"
                    fill="currentColor"
                  />
                  <path
                    d="M13.4917 1.6665H6.50841C3.47508 1.6665 1.66675 3.47484 1.66675 6.50817V13.4915C1.66675 14.3998 1.82508 15.1915 2.13341 15.8582C2.85008 17.4415 4.38341 18.3332 6.50841 18.3332H13.4917C16.5251 18.3332 18.3334 16.5248 18.3334 13.4915V11.5832V6.50817C18.3334 3.47484 16.5251 1.6665 13.4917 1.6665ZM16.9751 10.4165C16.3251 9.85817 15.2751 9.85817 14.6251 10.4165L11.1584 13.3915C10.5084 13.9498 9.45841 13.9498 8.80841 13.3915L8.52508 13.1582C7.93341 12.6415 6.99175 12.5915 6.32508 13.0415L3.20841 15.1332C3.02508 14.6665 2.91675 14.1248 2.91675 13.4915V6.50817C2.91675 4.15817 4.15841 2.9165 6.50841 2.9165H13.4917C15.8417 2.9165 17.0834 4.15817 17.0834 6.50817V10.5082L16.9751 10.4165Z"
                    fill="currentColor"
                  />
                </svg>
                图片
              </div>
            </div>
          )}
          {agentInfo?.flow_config?.card_video && activeTab === "1" ? (
            <div className={Styles.card_video_box}>
              <video
                style={{ borderRadius: "12px" }}
                width="100%"
                height="100%"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                // playsinline={true}
                x5-video-player-type="h5"
                x5-video-orientation="h5"
                x5-video-player-fullscreen="true"
                preload="auto"
                controls={true}
                src={agentInfo?.flow_config?.card_video}
              ></video>
            </div>
          ) : (
            <div
              className={classnames(
                Styles.card_image_box,
                magnifier ? Styles.card_image_box_fullscreen : ""
              )}
            >
              <div className={Styles.card_image_box_inner}>
                <CloseOutlined
                  className={Styles.card_image_box_close}
                  onClick={() => {
                    setMagnifier(false);
                  }}
                />
                {isCarouselArray() && (
                  <div className={Styles.card_image_index}>
                    {carouselIndex}/{cardImages.length}
                  </div>
                )}
                {isCarouselArray() && (
                  <img
                    style={
                      carouselIndex !== 1
                        ? {}
                        : {
                            cursor: "not-allowed",
                            opacity: 0.3,
                          }
                    }
                    src={LeftArrowPic}
                    onClick={() => {
                      if (carouselIndex !== 1) {
                        carousel.current?.prev();
                      }
                    }}
                    className={Styles.left_arrow}
                    alt=""
                  ></img>
                )}
                <Carousel
                  beforeChange={onChange}
                  ref={carousel}
                  infinite={false}
                >
                  {!isCarouselArray() ? (
                    <div className={Styles.contentStyle}>
                      <img
                        src={
                          getImageUrl(cardImages?.[0]) ||
                          agentInfo?.avatar_url ||
                          agentBg
                        }
                        alt=""
                        onClick={() => {
                          setMagnifier(true);
                        }}
                        loading="lazy"
                      ></img>
                    </div>
                  ) : (
                    cardImages.map((item: any, index: number) => {
                      return (
                        <div className={Styles.contentStyle} key={index}>
                          <img
                            src={getImageUrl(item) || agentBg}
                            draggable="false"
                            onClick={() => {
                              setMagnifier(true);
                            }}
                            alt=""
                            loading="lazy"
                          ></img>
                        </div>
                      );
                    })
                  )}
                </Carousel>
                {isCarouselArray() && (
                  <img
                    style={
                      carouselIndex !== cardImages.length
                        ? {}
                        : {
                            cursor: "not-allowed",
                            opacity: 0.3,
                          }
                    }
                    src={RightArrowPic}
                    onClick={() => {
                      if (carouselIndex !== cardImages.length) {
                        carousel.current?.next();
                      }
                    }}
                    className={Styles.right_arrow}
                    alt=""
                  ></img>
                )}
              </div>
            </div>
          )}
          <div className={Styles.card_chat_box}>
            {agentInfo && agentInfo.id && (
              <div className={Styles.card_chat_box_inner}>
                <ChatBox
                  scene="card"
                  mode={isSelfItem ? "agent" : "pub_agent"}
                  currentInfo={agentInfo}
                  agentId={isSelfItem ? agentInfo.id : agentInfo.agent_id}
                  pubAgentId={agentInfo.id}
                  history={chatHistory}
                  questionEx={
                    agentInfo.question_samples ? agentInfo.question_samples : []
                  }
                  frameId={agentInfo?.ai_framework}
                  botAvator={
                    getImageUrl(cardImages[0]) ||
                    agentInfo?.avatar_url ||
                    agentBg
                  }
                  botName={agentInfo.name}
                  greeting={agentInfo?.greeting}
                  cardShowCoin={agentInfo?.is_paid_model}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={Styles.card_details_close}
          onClick={() => {
            setShow(false);
            setShowDetail((showDetail: boolean) => !showDetail);
          }}
        >
          <img src={theme === "dark" ? CardClosePic : CardCloseLightPic} alt=""></img>
        </div>
      </div>
    </div>
  ) : null;
};
export default CardItemDetail;
