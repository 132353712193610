import AdvancedChatBox from "./AdvancedChatBox";
import CardChatBox from "./CardChatBox";
import HomeChatBox from "./HomeChatBox";
import PreviewChatBox from "./PreviewChatBox";
import TalkingChatbox from "./TalkingChatbox";

type scene = "advanced" | "card" | "home" | "preview" | "talking";

interface ChatBoxProps {
  scene: scene;
  mode?: string;
  agentId?: number | string;
  pubAgentId?: number | string;
  firstChat?: any;
  history?: any;
  topicId?: number | string;
  botAvator?: string;
  botName?: string;
  modalChoose?: any;
  questionEx?: Array<string>;
  modalId?: number;
  frameId?: number;
  receiverInfo?: any;
  boxStyle?: string;
  currentInfo?: any;
  greeting?: string;
  cardShowCoin?: boolean;
  [property: string]: any;
}

const ChatBoxMap = {
  advanced: AdvancedChatBox,
  card: CardChatBox,
  home: HomeChatBox,
  preview: PreviewChatBox,
  talking: TalkingChatbox,
};

const ChatBox = (props: ChatBoxProps) => {
  const { scene } = props;

  const ChatBoxComponent = ChatBoxMap[scene];

  if (!ChatBoxComponent) {
    throw new Error(`Unknown chat box mode: ${scene}`);
  }

  return <ChatBoxComponent {...props}/>;
};

export default ChatBox;