import { useSearchParams, useNavigate } from "react-router-dom";
import {
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { getActualCombat, getSaveAnswer, getUserAnswer } from "@/apis/common";
import { isNumber, merge } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import {
  Button,
  ConfigProvider,
  Flex,
  Input,
  Modal,
  Segmented,
  Spin,
  Tooltip,
  message,
} from "antd";
import StarRating from "./StarRating";
import CaseSvg from "@/assets/community/case.svg";
import baseWsURL from "@/request/getWsBaseURL";
import BackDark from "@/assets/community/backDark.svg";
import BackLight from "@/assets/community/backLight.svg";
import Down from "@/assets/community/down.svg";
import Edit from "@/assets/community/edit.svg";
import ReactMarkdown from "react-markdown";
import Cookies from "js-cookie";
import useThemeHooks from "@/hooks/useThemeHooks";
import EmptyPic from "@/assets/myAgent/empty-dark.svg";
import EmptyLightPic from "@/assets/myAgent/empty.svg";
import ArrowDark from "@/assets/community/arrowBack.svg";
import QuestionDark from "@/assets/community/questionDark.svg";
import QuestionLight from "@/assets/community/questionLight.svg";
import ArrowLight from "@/assets/community/arrowLight.svg";
import StepLine from "./StepLine";
import CoinSvg from "@/assets/common/coin.svg"
import store, { loginOption } from "@/store";
import { openRecharge } from "@/store/reducer/coinRecharge";
import { setCoinNumber } from "@/store/reducer/userConfig";
import { createImageUrl } from "@/utils/common";

const NoPassLight = createImageUrl('community/noPassLight.svg');
const NoPassDark = createImageUrl('community/noPassDark.svg');
const Pass = createImageUrl('community/pass.svg');

type TutorialState = {
  id: null | number;
  name: string;
  case_desc: string;
  task_desc: string;
  expected_copy: string;
  test_prompt: string;
  case_config: {
    rating_rule_prompt: string;
    standard_answer: string;
    next_case_id: string;
    step_list: {
      step_id: number;
      title: string;
      img_url: string;
      tip: string;
    }[];
  };
};

interface UserInputItem {
  step_id: string;
  user_input: string;
}

enum InputState {
  Editable,
  Locked,
  Indeterminate,
}

// 定义接口，包含 handleTrigger 方法
interface LineRef {
  handleTrigger: () => void;
}

const { TextArea } = Input;
let ws: any = null;
let wsData: SetStateAction<{
  evalute: string;
  score: number;
  shouldSave: boolean;
}>;

// 实践教程页面
const Actual = () => {
  // 获取当前实战教程id 如果没有id则直接展示无结果
  const [params] = useSearchParams();
  const id = params.getAll("id")[0];

  // 获取当前主题
  const theme = useThemeHooks();

  // 课程本身数据
  const [tutorial, setTutorial] = useState<TutorialState>({
    id: null,
    name: "",
    case_desc: "",
    task_desc: "",
    expected_copy: "",
    test_prompt: "",
    case_config: {
      rating_rule_prompt: "",
      standard_answer: "",
      step_list: [],
      next_case_id: "",
    },
  });
  const [inputsState, setInputsState] = useState<{
    [key: number]: { value: string; editable: InputState; confirmed: boolean };
  }>({});
  const [answer, setAnswer] = useState("");
  const [tab, setTab] = useState("教学模式");
  const [firstTabHeight, setFirstTabHeight] = useState(0);
  const [showTestPrompt, setShowTestPrompt] = useState(false);
  // const [showViewAnswer, setShowViewAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [AIAnswer, setAIAnswer] = useState("");
  const [noSaveModal, setNoSaveModal] = useState(false);
  const [intermediateAnswer, setIntermediateAnswer] = useState<any>({});
  const [comment, setComment] = useState({
    evalute: "",
    score: -1,
    shouldSave: false,
  });
  const [saveData, setSaveData] = useState<any>({
    is_approved: null,
    star_level: null,
  });
  const [content, setContent] = useState(0);
  const firstTabRef = useRef<any>(null);
  // const haveSeenAnswer = useRef<boolean>(false);
  const contentRefRef = useRef<any>(null);
  const token = localStorage.getItem("token") || Cookies.get("token");
  const [messageApi, contextHolder] = message.useMessage();
  const lineRef = useRef<LineRef>(null);
  const navigate = useNavigate();

  const areAllValuesEmpty = (): boolean => {
    // 使用 Object.values 获取 inputsState 对象的所有值数组
    // 然后使用 Array.every 方法检查每个项的 value 是否为空字符串
    return Object.values(inputsState).every((input) => !input.value);
  };

  const saveAnswer = () => {
    // 从 inputsState 中获取用户回答
    const userAnswer = Object.entries(inputsState)?.map(
      ([step_id, { value }]) => ({
        step_id: Number(step_id),
        user_input: value,
      })
    );

    // 构建请求数据
    const reqData = {
      id: Number(id),
      user_answer: {
        user_input_list: userAnswer,
      },
    } as any;

    // 根据需要添加其他字段
    if (comment.score !== -1) {
      reqData.user_answer.score = comment.score;
    }

    if (!!comment.evalute) {
      reqData.user_answer.suggestion = comment.evalute;
    }

    if (!!AIAnswer) {
      reqData.user_answer.model_generated_answer = AIAnswer;
    }

    if (!!answer) {
      reqData.user_answer.explore_input = answer;
    }

    // 清空保存数据状态
    setSaveData({
      is_approved: null,
      star_level: null,
    });

    // 发送保存请求
    getSaveAnswer(reqData)
      .then((res) => {
        if (res.data?.status === 200) {
          messageApi.success("已保存");
          const { is_approved = null, star_level = null } = res.data.data;
          setSaveData({
            is_approved,
            star_level,
          });
        } else {
          messageApi.error("保存失败, 请检查网络连接");
        }
      })
      .catch((error) => {
        console.error("Error saving answer:", error);
        messageApi.error("保存失败, 请检查网络连接");
      });
  };

  // 回到实战教程列表
  const back = () => {
    if (!areAllValuesEmpty()) {
      setNoSaveModal(true);
    } else {
      navigate("/community?tab=actual");
    }
  };

  // 点击提交
  const handelSubmit = () => {
    // 判断是否按照步骤了
    const canSubMit = isSequentiallyCompleted();
    if (!canSubMit && tab === "教学模式") {
      setSubmitModal(true);
    } else {
      fetchSubmit();
    }
  };

  // 向服务器提交 得到生成的结果和评价
  const fetchSubmit = () => {
    // 如果正在loading了,则不再重复请求
    if (loading) {
      message.info("正在生成，请耐心等待");
      return;
    } else {
      // 未登录则直接拦截 弹出登录弹窗
      if (!token) {
        store.dispatch(loginOption.openModal());
        return;
      }
      // 触发步骤条动画
      triggerLine();
      setLoading(true);
      setSubmitModal(false);
      setTab("探索模式");
      let msgObj: any = {
        chat_mode: "ai_practical",
        test_input: tutorial.test_prompt,
        user_prompt: answer,
        standard_prompt: tutorial.case_config.standard_answer,
        judge_rules: tutorial.case_config.rating_rule_prompt,
        task_desc: tutorial.task_desc,
      };

      // 存在ws的正常连接则直接传输数据
      if (ws && ws.readyState !== 3) {
        //开始通信时的处理
        ws.send(JSON.stringify(msgObj));
        setAIAnswer("");
        setComment({
          evalute: "",
          score: -1,
          shouldSave: false,
        });
      } else {
        // 不存在则建立连接发起请求 未登录则跳登录
        const token_string = `?token=${token}`;
        ws = new WebSocket(
          `${baseWsURL}/ws/agent_chat${token ? token_string : ""}`
        );

        ws.onopen = function () {
          console.log("ws.onopen");
          setAIAnswer("");
          setComment({
            evalute: "",
            score: -1,
            shouldSave: false,
          });
          if (ws.readyState !== 3) {
            //开始通信时的处理
            ws.send(JSON.stringify(msgObj));
          }
        };

        ws.onclose = function () {
          setLoading(false);
          console.log("ws.onclose");
        };

        ws.onmessage = (res: any) => {
          const data = JSON.parse(res.data);
          if (data.status === 40103) {
            console.log("未登录");
            store.dispatch(loginOption.openModal());
          } else if (data.status === 200) {
            const { answer, evalute, score } = data.content;
            setAIAnswer((prev) => prev + answer);
            if (evalute) {
              wsData = {
                evalute,
                score,
                shouldSave: true,
              };
            }
          }
          if (data.status && data.status === 20050) {
            console.log("完成");
            setLoading(false);
            setComment(wsData);
          }
          if (data.status && data.status === 50001) {
            console.log("失败: 50001");
            setLoading(false);
          }
          if (data.status && (data.status === 40030 || data.status === 40031)) {
            store.dispatch(openRecharge())
            message.error(data.status === 40031 ? '没有智纪币啦, 为您打开订购套餐页面~' : '当前对话为付费模型的对话哦, 为您打开订购套餐页面~');
            setLoading(false);
          }
    
          if (data.current_coin_count && isNumber(data.current_coin_count)) {
            store.dispatch(setCoinNumber(data.current_coin_count))
          }
        };
        ws.onerror = function (error: any) {
          setLoading(false);
          console.log("ws.onerror", error);
        };
      }
    }
  };

  // 查看参考答案 打开参考答案弹窗
  const viewAnswer = () => {
    // 看答案掉星星功能注掉 还原的话就用下面那个
    // setShowViewAnswer(true);
    setShowAnswer(true);
  };

  // 双向绑定一下
  const handleChangeAnswer = (value: string) => {
    setAnswer(value);
  };

  // 确认查看参考答案
  // const viewAnswerOK = () => {
  //     setShowViewAnswer(false);
  //     setShowAnswer(true);
  //     haveSeenAnswer.current = true;
  // };

  // 输入框改动
  const handleChange = (key: number, value: string) => {
    setInputsState((prevState) => {
      const inputState = prevState[key];

      return {
        ...prevState,
        [key]: {
          ...inputState,
          value: value,
        },
      };
    });
  };

  // 输入框点击确定
  const handleAddClick = (key: number) => {
    const nextStepId = key + 1; // 获取下一个输入框的ID

    setInputsState((prevState) => {
      // 检查是否存在下一步，如果存在，只更新当前步骤，不强制添加下一步
      const nextStepExists = prevState.hasOwnProperty(nextStepId);
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          editable: InputState.Locked, // 当前输入框变为锁定态
          confirmed: true, // 将当前确认状态设置为 true
        },
        ...(nextStepExists && {
          [nextStepId]: {
            ...prevState[nextStepId],
            editable: InputState.Editable, // 下一个输入框变为可编辑态
          },
        }),
      };
    });

    setIntermediateAnswer({
      ...intermediateAnswer,
      [key]: inputsState[key].value,
    });
  };

  // 输入框点击编辑
  const handleEditClick = (key: number) => {
    setInputsState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        editable: InputState.Editable,
      },
    }));
  };

  // 检查步骤是否都点过确认了
  const isSequentiallyCompleted = () => {
    const inputKeys = Object.keys(inputsState); // 获取inputsState对象中所有的键
    // 检查每个键对应的输入框状态是否已被确认
    const allConfirmed = inputKeys.every(
      (key) => inputsState[Number(key)].confirmed
    );
    return allConfirmed;
  };

  // 触发步骤条动画
  const triggerLine = () => {
    lineRef.current?.handleTrigger();
  };

  // 使用参考答案
  const handelUseAnswer = () => {
    setAnswer(tutorial.case_config.standard_answer);
    setShowAnswer(false);
  };

  // 获取一下tab上应该用什么icon
  const getImageSrc = (tabValue: string, currentTab: string, theme: string) => {
    if (!currentTab) {
      return theme === "dark" ? QuestionLight : QuestionDark;
    } else if (currentTab === tabValue) {
      return theme === "dark" ? QuestionDark : QuestionLight;
    } else {
      return theme === "dark" ? QuestionLight : QuestionDark;
    }
  };

  useEffect(() => {
    // 将标题加星号拼接 答案换行处理
    const newAnswer = tutorial.case_config.step_list
      .filter((step) => intermediateAnswer.hasOwnProperty(step.step_id))
      .map((step) => `**${step.title}**\n${intermediateAnswer[step.step_id]}`)
      .join("\n");
    setAnswer(newAnswer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intermediateAnswer]);

  // 大模型返回评价后 也就是生成了结果后保存一下
  useEffect(() => {
    if (comment.shouldSave) {
      saveAnswer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  // 页面渲染拿两个接口 题目 和 上次回答的结果 渲染到页面 又上次回答的结果则需要匹配到输入框中
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 请求案例数据
        const combatRes = await getActualCombat({ id: Number(id) });
        if (combatRes.data?.status === 200) {
          setTutorial((prevTutorial) =>
            merge({}, prevTutorial, combatRes.data.data)
          );

          // 初始化 inputsState 状态
          const initialInputsState =
            combatRes.data.data.case_config.step_list.reduce(
              (acc: any, step: UserInputItem, index: number) => {
                acc[step.step_id] = {
                  value: "", // 初始值为空字符串
                  editable:
                    index === 0
                      ? InputState.Editable
                      : InputState.Indeterminate, // 第一个输入框为可编辑态，其余为不可确定态
                  confirmed: false, // 初始时没有确认
                };
                return acc;
              },
              {}
            );

          // 请求用户上次回答结果
          const userAnswerRes = await getUserAnswer({ id });
          if (userAnswerRes.data?.status === 200) {
            const { score, star_level, is_approved, user_answer } =
              userAnswerRes.data.data || {};
            const {
              user_input_list,
              suggestion,
              model_generated_answer,
              explore_input,
            } = user_answer || {};
            if (user_input_list && user_input_list.length > 0) {
              user_input_list.forEach((item: UserInputItem) => {
                initialInputsState[item.step_id] = {
                  ...initialInputsState[item.step_id],
                  value: item.user_input, // 设置每个步骤对应的用户输入
                  confirmed: false, // 如果存在用户回答也不设置已确定状态
                };
              });
            }

            setTimeout(() => {
              // 保存生成内容
              setComment({
                evalute:
                  suggestion !== undefined ? suggestion : comment.evalute,
                score: score !== undefined ? score : comment.score,
                shouldSave: false,
              });

              if (model_generated_answer) {
                setAIAnswer(model_generated_answer);
              }

              if (explore_input) {
                setAnswer(explore_input);
              }

              setSaveData({
                star_level:
                  star_level !== undefined ? star_level : saveData.star_level,
                is_approved:
                  is_approved !== undefined
                    ? is_approved
                    : saveData.is_approved,
              });
            }, 500);
          }

          // 设置初始化的 inputsState
          setInputsState(initialInputsState);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (id != null) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useLayoutEffect(() => {
    // 做个延时防止获取不到元素高度
    setTimeout(() => {
      if (firstTabRef.current) {
        const height = firstTabRef.current.offsetHeight;
        setFirstTabHeight(height);
      }
      if (contentRefRef.current) {
        const heightContent = contentRefRef.current.offsetHeight;
        setContent(heightContent);
      }
    }, 500);
    // 第一时间接口还没返回内容 页面不渲染的情况下也要重新拿到正确的高度
  }, [tab, tutorial.id]);

  useEffect(() => {
    return () => {
      ws?.close();
    };
  }, []);

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          // 使用品牌色
          colorPrimary: "#29CC6A",
        },
      }}
    >
      {contextHolder}
      {!tutorial.id ? (
        <div className={styles.spin}>
          <Spin size='large' />
        </div>
      ) : (
        <div className={styles.page}>
          <div className={styles.backButtons}>
            <Button onClick={back} className={styles.backButton}>
              <img src={theme === "dark" ? ArrowLight : ArrowDark} alt='' />
              返回
            </Button>
            <Button onClick={saveAnswer} className={styles.saveButton}>
              保存
            </Button>
          </div>
          <img
            src={theme === "dark" ? BackDark : BackLight}
            alt=''
            className={styles.backGround}
          ></img>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <div style={{ height: 10 }}></div>
              <div className={styles.title}>{tutorial.name}</div>
              <div className={styles.missonTitle}>任务背景: </div>
              <div className={styles.mission}>{tutorial.task_desc}</div>
            </div>
            <div className={styles.headerRight}>
              <div className={styles.wish}>
                <div className={styles.wishScrollable}>
                  <div className={styles.wishTitle}>期望的得到的文案:</div>
                  <div className={styles.wishContent}>
                    <div className={styles.wishText}>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[gfm]}
                      >
                        {tutorial.expected_copy}
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.stepHeader}>
              <img
                className={styles.stepLogoInverse}
                src={CaseSvg}
                alt=''
              ></img>
              <div className={styles.stepLineTitle}>问题拆解</div>
              <img className={styles.stepLogo} src={CaseSvg} alt=''></img>
            </div>
            <div className={styles.stepLineContainer}>
              <StepLine
                inputsState={inputsState}
                ref={lineRef}
                loading={!AIAnswer}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.contentLeft} ref={contentRefRef}>
              <div className={styles.contentLeftCard}>
                <div className={styles.tab}>
                  <Segmented
                    value={tab}
                    options={[
                      {
                        label: (
                          <Flex gap={4}>
                            <div>教学模式</div>
                            <Tooltip title='分步教你怎么写Prompt, 适合新手入门。点击【添加】后, 答案会被添加到探索模式的答题框内'>
                              <img
                                src={getImageSrc("教学模式", tab, theme)}
                                alt=''
                              />
                            </Tooltip>
                          </Flex>
                        ),
                        value: "教学模式",
                      },
                      {
                        label: (
                          <Flex gap={4}>
                            <div>探索模式</div>
                            <Tooltip title='无教学自由探索, 适合大佬挑战。教学模式下的每一步的答案都会被汇集到这里, 点击【生成】后会根据你的Prompt生成的答案进行评分及建议'>
                              <img
                                src={getImageSrc("探索模式", tab, theme)}
                                alt=''
                              />
                            </Tooltip>
                          </Flex>
                        ),
                        value: "探索模式",
                      },
                    ]}
                    onChange={(e) => {
                      setTab(e as SetStateAction<string>);
                    }}
                  />
                </div>
                {tab === "教学模式" ? (
                  <div ref={firstTabRef}>
                    {tutorial.case_config.step_list?.map((field, index) => {
                      const indexStr =
                        Number(field.step_id) < 10
                          ? `0${field.step_id}`
                          : field.step_id;

                      // 从 inputsState 提取状态，如果为空则设置默认状态
                      const state = inputsState[Number(field.step_id)] || {
                        editable:
                          index === 0
                            ? InputState.Editable
                            : InputState.Indeterminate,
                        value: "",
                      };
                      const { editable, value } = state;

                      const isEditable =
                        editable === InputState.Editable ||
                        editable === InputState.Indeterminate;
                      const isLocked = editable === InputState.Locked;
                      const isIndeterminate =
                        editable === InputState.Indeterminate;

                      return (
                        <div className={styles.stepCard} key={field.step_id}>
                          <div className={styles.stepTitle}>
                            <div
                              className={styles.stepTitleNum}
                            >{`Step ${indexStr}`}</div>
                            <div className={styles.stepTitleText}>
                              {field.title}
                            </div>
                          </div>
                          <div
                            className={
                              isEditable
                                ? styles.stepInputWrapper
                                : styles.stepInputWrapperDisable
                            }
                          >
                            <TextArea
                              className={styles.stepInput}
                              onChange={(e) =>
                                handleChange(
                                  Number(field.step_id),
                                  e.target.value
                                )
                              }
                              value={value || ""} // 从 state 中获取 value，没有时使用空字符串
                              autoSize={{ minRows: 3, maxRows: 3 }}
                              placeholder='请输入内容'
                              disabled={isLocked}
                            />
                            <div className={styles.stepsBtn}>
                              {isEditable && (
                                <>
                                  <Tooltip title={field.tip}>
                                    <Button className={styles.stepTips}>
                                      💡 提示
                                    </Button>
                                  </Tooltip>
                                  <Button
                                    className={styles.add}
                                    onClick={() =>
                                      handleAddClick(Number(field.step_id))
                                    }
                                    disabled={isIndeterminate}
                                  >
                                    添加
                                  </Button>
                                </>
                              )}
                              {isLocked && (
                                <Button
                                  className={styles.edit}
                                  onClick={() => handleEditClick(field.step_id)}
                                >
                                  <span
                                    style={{ display: "flex" }}
                                    className={styles.editImgWrapper}
                                  >
                                    <img
                                      src={Edit}
                                      alt=''
                                      className={styles.editImg}
                                    />
                                  </span>
                                  <div>编辑</div>
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className={styles.beforehandCard}
                    style={{ height: firstTabHeight }}
                  >
                    <Button
                      className={styles.testBtn}
                      onClick={() => setShowTestPrompt(!showTestPrompt)}
                    >
                      <Tooltip title='将会用这个提问测试您的Prompt质量'>
                        <img src={getImageSrc("探索模式", "", theme)} alt='' />
                      </Tooltip>
                      <div className={styles.testBtnTitle}>系统预置提问</div>
                      <img
                        src={Down}
                        alt=''
                        className={styles.testBtnLogo}
                        style={{ transform: showTestPrompt ? "" : "scale(-1)" }}
                      />
                    </Button>

                    {showTestPrompt && (
                      <div className={styles.testContent}>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[gfm]}
                        >
                          {tutorial.test_prompt}
                        </ReactMarkdown>
                      </div>
                    )}
                    <div className={styles.afterSplicing}>
                      <TextArea
                        className={styles.afterSplicingInput}
                        autoSize
                        value={answer}
                        onChange={(e) => {
                          handleChangeAnswer(e.target.value);
                        }}
                      ></TextArea>
                      <div className={styles.btnWrapper}>
                        <Button
                          className={styles.afterSplicingBtn}
                          onClick={viewAnswer}
                        >
                          <div className={styles.afterSplicingText}>
                            📋 答案
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.submit} onClick={handelSubmit}>
                <Tooltip
                  title={
                    !answer
                      ? "探索模式的答题框还没有信息QAQ 可以点击【添加】试试哦"
                      : ""
                  }
                >
                  <Button className={styles.submitBtn} disabled={!answer}>
                    <div style={{display: 'flex'}}>
                    {loading ? (
                      <LoadingOutlined
                        style={{ fontSize: 16, marginRight: 4 }}
                      />
                    ) : (
                        <img src={CoinSvg} alt="" style={{width: 24, height: 24,marginRight: 4}}/>
                    )}
                    <div>生成</div>
                    </div>
                    
                    <div className={styles.tootip}>( 由gpt4o驱动, 1智纪币/次 )</div>
                  </Button>
                </Tooltip>
              </div>
            </div>

            <div className={styles.contentSpace} />
            <div
              className={styles.contentRight}
              style={{ maxHeight: content || "" }}
            >
              <div className={styles.answer}>
                <div className={styles.answerHeaderWrapper}>
                  <div className={styles.answerHeader}>
                    <img src={CaseSvg} alt=''></img>
                    <div className={styles.answerTitle}>prompt生成答案</div>
                  </div>
                </div>

                <div className={styles.answerContent}>
                  {!!AIAnswer ? (
                    <span style={{ whiteSpace: "pre-wrap", width: "100%" }}>
                      <ReactMarkdown
                        className={styles.reactMarkdown}
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[gfm]}
                      >
                        {AIAnswer}
                      </ReactMarkdown>
                    </span>
                  ) : (
                    <div className={styles.noData}>
                      {loading ? (
                        <Spin size='large'></Spin>
                      ) : (
                        <>
                          <img
                            src={theme === "dark" ? EmptyPic : EmptyLightPic}
                            alt=''
                          ></img>
                          <div className={styles.noDataText}>
                            点击左侧「生成」按钮进行评价和建议生成
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.comment}>
                <div className={styles.commentHeaderWrapper}>
                  <div className={styles.commentHeader}>
                    <img src={CaseSvg} alt=''></img>
                    <div className={styles.commentTitle}>评分及建议</div>
                  </div>
                </div>

                <div className={styles.commentContent}>
                  {!!comment.evalute ? (
                    <>
                      <span style={{ whiteSpace: "pre-wrap", width: "100%" }}>
                        <ReactMarkdown
                          className={styles.reactMarkdown}
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[gfm]}
                        >
                          {comment.evalute}
                        </ReactMarkdown>
                      </span>
                      <div className={styles.starWrapper}>
                        {saveData.star_level !== null && (
                          <>
                            <div className={styles.starText}>评测结果</div>
                            <div
                              className={styles.starBigText}
                            >{`${saveData.star_level}星`}</div>
                            <StarRating rating={saveData.star_level} />
                            <Tooltip
                              title={
                                tutorial.case_config.next_case_id === "" &&
                                saveData.is_approved
                                  ? "已经是最新一关啦~"
                                  : ""
                              }
                            >
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/community/actual?id=${tutorial.case_config.next_case_id}`,
                                    { replace: true }
                                  );
                                  // 强制重载页面
                                  window.location.reload();
                                }}
                                className={styles.next}
                                disabled={
                                  !saveData.is_approved ||
                                  tutorial.case_config.next_case_id === ""
                                }
                              >
                                下一关
                              </Button>
                            </Tooltip>
                            <img
                              className={styles.passLogo}
                              src={
                                saveData.is_approved
                                  ? Pass
                                  : theme === "dark"
                                  ? NoPassDark
                                  : NoPassLight
                              }
                              alt=''
                            />
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className={styles.noData}>
                      {loading ? (
                        <Spin size='large'></Spin>
                      ) : (
                        <>
                          <img
                            src={theme === "dark" ? EmptyPic : EmptyLightPic}
                            alt=''
                          ></img>
                          <div className={styles.noDataText}>
                            {!!AIAnswer
                              ? "生成失败了x_x, 请再生成一次叭"
                              : "点击左侧「生成」按钮进行评价和建议生成"}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 这段是确认是否看答案的弹窗 暂时不用了 */}
      {/* <Modal title="提示" className={styles.showViewAnswer} open={showViewAnswer} footer={(_, { OkBtn, CancelBtn }) => (
                <>
                    <Button className={styles.showViewAnswerCancel} onClick={() => { setShowViewAnswer(false); }}>取消</Button>
                    <Button className={styles.showViewAnswerKeep} onClick={viewAnswerOK}>继续</Button>
                </>
            )}>
                <div className={styles.viewAnswerTipsText}>看了答案会扣掉一星,您确定吗</div>
            </Modal> */}
      <Modal
        className={styles.showAnswer}
        open={showAnswer}
        onCancel={() => {
          setShowAnswer(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => <></>}
      >
        <div className={styles.showAnswerWrapper}>
          <div className={styles.showAnswerContent}>
            <div className={styles.showAnswerHeader}>📋 答案</div>
            <div className={styles.showAnswerBorder}>
              <div className={styles.showAnswerText}>任务描述:</div>
              <div className={styles.showAnswerText}>
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[gfm]}
                  className={styles.reactMarkdownAnswer}
                >
                  {tutorial.case_config.standard_answer}
                </ReactMarkdown>
              </div>
            </div>
          </div>
          <div className={styles.showAnswerBtns}>
            <Button className={styles.showAnswerBtn} onClick={handelUseAnswer}>
              使用
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title='提示'
        className={styles.showViewAnswer}
        open={submitModal}
        onCancel={() => {
          setSubmitModal(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className={styles.showViewAnswerCancel}
              onClick={() => {
                setSubmitModal(false);
              }}
            >
              取消
            </Button>
            <Button className={styles.showViewAnswerKeep} onClick={fetchSubmit}>
              确定生成
            </Button>
          </>
        )}
      >
        <div className={styles.viewAnswerTipsText}>
          您未按照步骤填写，确定要生成吗？
        </div>
      </Modal>
      <Modal
        title='您还未保存编辑内容'
        className={styles.showViewAnswer}
        open={noSaveModal}
        onCancel={() => {
          setSubmitModal(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className={styles.showViewAnswerCancel}
              onClick={() => {
                setNoSaveModal(false);
              }}
            >
              取消
            </Button>
            <Button
              className={styles.showViewAnswerKeep}
              onClick={() => {
                setNoSaveModal(false);
                navigate("/community?tab=actual");
              }}
            >
              确定退出
            </Button>
          </>
        )}
      >
        <div className={styles.viewAnswerTipsText}>
          如果点击确定返回按钮,您本次编辑的内容将丢失。
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default Actual;
