import React, { useEffect, useState } from "react";
import { Space, Table, Tag } from "antd";
import type { TableProps } from "antd";
import Styles from "./index.module.scss";

interface DataType {
  name: string;
  id: string;
}

const FolderListTable = (props: any) => {
  const { data, unRelate } = props;

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "知识库名称",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: (text) => (
        <div className={Styles.tableText}>
          <span>{text}</span>
        </div>
      ),
    },

    {
      title: "操作",
      key: "action",
      align: "right",
      render: (_, record, index) => (
        <Space size="middle">
          <div className={Styles.tableText} onClick={() => unRelate(record.id)}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5837 2.125V0.291667L4.41699 0.291669V2.125L0.291992 2.125V3.95834H1.89616L1.89616 16.3333C1.89616 17.0927 2.51177 17.7083 3.27116 17.7083L14.7295 17.7083C15.4889 17.7083 16.1045 17.0927 16.1045 16.3333V3.95834L17.7087 3.95834V2.125L13.5837 2.125ZM3.72949 15.875L3.72949 3.95834L14.2712 3.95834V15.875L3.72949 15.875ZM6.25033 6.25V13.125H8.08366V6.25H6.25033ZM9.91699 6.25V13.125H11.7503V6.25H9.91699Z"
                fill={"currentColor"}
              />
            </svg>
          </div>
        </Space>
      ),
    },
  ];

  console.log(data, "data")

  return (
    <>
      {data.length ? (
        <div className={Styles.wrap}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
      ) : null}
    </>
  );
};
export default FolderListTable;
