import React, { useEffect, useMemo, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Styles from "../../../index.module.scss";
import BgUpload from "@/components/Create/components/BgUpload";
import { getImageUrl } from "@/utils/common";
import { useTranslation } from "react-i18next";
import addPic from "@/assets/myAgent/add.svg";
import cusStyle from "./index.module.scss";
import { Carousel, Spin } from "antd";
import arrow from "./arrow.svg";
import arrowDark from "./arrow-dark.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import { LoadingOutlined } from "@ant-design/icons";

interface CardProps {
  id: any;
  column: number;
  onUploadSuccess: (url: string, column: number) => void;
  onRemove: (column: number) => void;
}

const ItemType = "CARD";

const Card: React.FC<CardProps> = React.memo(({ id, column, onUploadSuccess, onRemove }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id, column },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className={Styles.upload_box}>
        <BgUpload
          onUploadSuccess={(url: string) => {
            onUploadSuccess(url, column);
          }}
          handleCloseClick={() => {
            onRemove(column);
          }}
          defaultImageUrl={getImageUrl(id)}
          type="card"
        />
      </div>
    </div>
  );
});

const DropTarget: React.FC<any> = React.memo(({ columnIndex, onDrop, children }) => {
  const [, drop] = useDrop({
    accept: ItemType,
    drop: (item: any) => onDrop && onDrop(item.column, columnIndex),
  });

  return <div ref={drop}>{children}</div>;
});

const Board: React.FC<any> = React.memo(({ cards, onUploadSuccess, onRemove }) => {
  const [hidePrev, setHidePrev] = useState(true);
  const [hideNext, setHideNext] = useState(false);
  const [init, setInit] = useState(false);
  const carouselRef = useRef<any>(null);
  const theme = useThemeHooks();

  useEffect(() => {
    const timer = setTimeout(() => {
      setInit(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      {cards.length > 4 ? (
        <div
          style={{
            position: "relative",
            width: 624,
            margin: "0 auto",
            visibility: init ? "visible" : "hidden",
          }}
        >
          <div
            className={`${cusStyle.onPre} ${hidePrev ? cusStyle.hide : cusStyle.show}`}
          >
            <img
              src={theme === "dark" ? arrowDark : arrow}
              className={cusStyle.onPreIcon}
              onClick={() => carouselRef?.current?.prev()}
              alt=""
            />
          </div>
          <Carousel
            slidesToShow={4}
            dots={false}
            ref={carouselRef}
            infinite={false}
            afterChange={(currentSlide) => {
              if (currentSlide === 0) {
                setHidePrev(true);
              } else {
                setHidePrev(false);
              }
              if (currentSlide === cards.length - 4) {
                setHideNext(true);
              } else {
                setHideNext(false);
              }
            }}
          >
            {cards.map((card: any, columnIndex: any) => (
              <DropTarget key={columnIndex} columnIndex={columnIndex}>
                <Card
                  key={columnIndex}
                  id={card}
                  column={columnIndex}
                  onUploadSuccess={onUploadSuccess}
                  onRemove={onRemove}
                />
              </DropTarget>
            ))}
          </Carousel>
          <div
            className={`${cusStyle.onNext} ${hideNext ? cusStyle.hide : cusStyle.show}`}
          >
            <img
              src={theme === "dark" ? arrowDark : arrow}
              className={cusStyle.onPreIcon}
              onClick={() => carouselRef?.current?.next()}
              alt=""
            />
          </div>
        </div>
      ) : (
        <div className="row" style={{ display: "flex", width: 650, flexWrap: "wrap", gap: 8 }}>
          {cards.map((card: any, columnIndex: any) => (
            <DropTarget key={columnIndex} columnIndex={columnIndex}>
              <Card
                key={columnIndex}
                id={card}
                column={columnIndex}
                onUploadSuccess={onUploadSuccess}
                onRemove={onRemove}
              />
            </DropTarget>
          ))}
        </div>
      )}
    </div>
  );
});

const DragAndDropSort = ({ createInfo, changeCreateInfo, needLoading }: any) => {
  const [cards, setCards] = useState<any>([]);
  const { t } = useTranslation();


  const onUploadSuccess = (url: string, column: number) => {
    const newCards = [...cards];
    newCards[column] = url;
    setCards(newCards);

    const card_images = newCards.filter((item: string) => item !== "");
    changeCreateInfo(
      ["flow_config.card_images", "avatar_url"],
      [card_images, card_images?.[0] || ""]
    );
  };

  const onRemove = (column: number) => {
    const newCards = [...cards];
    newCards.splice(column, 1);
    setCards(newCards);
    const card_images = newCards.filter((item: string) => item !== "");
    changeCreateInfo(
      ["flow_config.card_images", "avatar_url"],
      [card_images, card_images?.[0] || ""]
    );
  };

  useEffect(() => {
    const images = (createInfo?.flow_config && createInfo?.flow_config?.card_images) || [];
    if (images.length < 1 && images.every((item: any) => !!item)) images.push("");
    setCards([...images]);
  }, [createInfo]);

  const board = useMemo(() => {
    return <Board cards={cards} onUploadSuccess={onUploadSuccess} onRemove={onRemove} />;
  }, [cards]);

  return (
    <>
      <div className={cusStyle.header}>
        <div>
          <span className={cusStyle.cardTextWrap}>
            {t("create.card.cardContent")}
          </span>
          <span className={cusStyle.cardLimitTip}>
            {t("create.card.maxCardNumber")}
          </span>
        </div>
        {cards.length < 9 && cards?.length > 0 && cards?.[0] ? (
          <div>
            <BgUpload
              isCustomType
              onUploadSuccess={(url: string) => {
                onUploadSuccess(url, cards.length);
              }}
            >
              <div className={cusStyle.uploadText}>
                <img src={addPic} alt="" />
                {t("create.card.uploadMore")}
              </div>
            </BgUpload>
          </div>
        ) : null}
      </div>
      <DndProvider backend={HTML5Backend}>
        {needLoading ? (
          <div className={Styles.spin}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        ) : (
          board
        )}
      </DndProvider>
    </>
  );
};

export default DragAndDropSort;
