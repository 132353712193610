import Star from "@/assets/community/starRating.svg";
import NoStarLight from "@/assets/community/noStarRatingLight.svg";
import NoStarDark from "@/assets/community/noStarRatingDark.svg";
import useThemeHooks from "@/hooks/useThemeHooks";

const StarRating = (props: any) => {
    const { rating } = props || {};
    const theme = useThemeHooks();
    // 创建一个长度为 5 的数组，用于生成星星组件
    const stars = Array.from({ length: 5 }).map((_, index) => {
        // 如果当前星星的索引小于传入评分，则使用点亮的星星
        const starImg = index < rating ? Star : theme === 'dark' ? NoStarDark : NoStarLight;
        return <img style={{ marginRight: 12 }} key={index} src={starImg} alt={index < rating ? 'light star' : 'dark star'} />;
    });

    return <div style={{ marginTop: 8 }}>{stars}</div>;
};

export default StarRating;