import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ConfigProvider, Modal, Button } from "antd";
import KnowledgeContentFolderList from "@/components/KnowledgeContent/component/FolderList";
import { ListType } from "@/components/KnowledgeContent/component/FolderList/type";
import Cate from "@/pages/Knowledge/components/Cate";
import style from "./index.module.scss";

interface RelateKnowledgeBaseModalProps {
  // Add any additional props here
  setCurrentFolderList: (list: any) => void;
  curFolderList: any;
}

export interface RelateKnowledgeBaseModalRef {
  show: () => void;
  hide: () => void;
}

const RelateKnowledgeBaseModal = forwardRef<
  RelateKnowledgeBaseModalRef,
  RelateKnowledgeBaseModalProps
>((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [folderList, setFolderList] = useState<any>([]);
  const [pageType, setPageType] = useState<string>("knowledge");
  const listRef = useRef<any>(null);
  const category = [
    {
      id: "knowledge",
      name: "我的知识库",
    },
  ];

  const handleOkClick = () => {
    console.log(folderList, "------f-----");
    props.setCurrentFolderList(folderList);
    setVisible(false);
  };

  const requiredData = useMemo(
    () => ({
      tag: "全部",
      pub_scope: pageType === "company" ? 2 : 1,
    }),
    [pageType]
  );

  const handelAdd = (event: any) => {
    window.open("/my_agent?tab=knowledge&addFolder=1");
  };

  useImperativeHandle(ref, () => ({
    show: () => setVisible(true),
    hide: () => setVisible(false),
  }));

  useEffect(() => {
    setFolderList(props.curFolderList);
  }, [props.curFolderList]);

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          // 使用品牌色
          colorPrimary: "transparent",
        },
        components: {
          Button: {
            /* 这里是你的组件 token */
            colorPrimaryHover: "none",
            primaryShadow: "none",
          },
          Input: {
            activeShadow: "none",
          },
        },
      }}
    >
      <Modal
        title="选择关联知识库文件"
        open={visible}
        onOk={handleOkClick}
        onCancel={() => setVisible(false)}
        width={920}
        style={{ height: 700 }}
        className={style.page}
        footer={[
          <div>
            已选择
            <p className={style.footerCount}>{folderList.length}</p>
            个文件夹
          </div>,
          <div className={style.footerBtns}>
            <Button
              style={{
                color: "var(--dot-color)",
                background: "transparent",
                border: "1px solid var(--theme-border-1)",
              }}
              key="back"
              onClick={() => setVisible(false)}
            >
              取消
            </Button>
            <Button
              style={{
                background:
                  "var(--Linear, linear-gradient(90deg, #b7ff00 0%, #00fff1 100%))",
                color: "#252b32",
                border: "none",
              }}
              key="submit"
              type="primary"
              onClick={handleOkClick}
            >
              确定
            </Button>
          </div>,
        ]}
      >
        <Cate
          pageType={pageType}
          category={category}
          handelAdd={handelAdd}
          setPageType={setPageType}
          needFilterBtn={false}
          isTabFolder={true}
        />
        <div
          style={{
            paddingTop: "16px",
            maxHeight: "55vh",
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: "var(--color-bg-1)",
          }}
          className="scroll-hide"
        >
          <KnowledgeContentFolderList
            ref={listRef}
            onFolderClick={(value, name, user_id) => {
              if (folderList.some((item: any) => item.id === value)) {
                setFolderList(
                  folderList.filter((item: any) => item.id !== value)
                );
              } else {
                setFolderList([
                  ...folderList,
                  {
                    id: value,
                    name,
                    user_id,
                  },
                ]);
              }
            }}
            requiredData={requiredData}
            selectedFolderList={folderList}
            isModal={true}
            type={ListType.insert}
          />
        </div>
      </Modal>
    </ConfigProvider>
  );
});

export default RelateKnowledgeBaseModal;
