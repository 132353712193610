import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Form, message, Spin, QRCode } from "antd";
import Styles from "./index.module.scss";
import logoIcon from "@/assets/common/logoIcon.svg";
import googleIcon from "@/assets/common/google.svg";
import wechatIcon from "@/assets/common/wechat.svg";
import { useSelector } from "react-redux";
import { loginOption } from "@/store";
import store from "@/store";
import { Trans, useTranslation } from "react-i18next";
import PrivatePolicy from "../PrivatePolicy";
import {
  toCheckLoginCode,
  toGetLoginCode,
  toUsersIslogin,
  loginCallBack,
  getUserGuideInfo,
  getCanClaimCoinToday,
} from "@/apis/common";
import { updateUserInfo } from "@/store/reducer/userInfo";
import Cookies from "js-cookie";
import { useGoogleLogin } from "@react-oauth/google";
import useThemeHooks from "@/hooks/useThemeHooks";
import { InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import useConfigHooks from "@/hooks/useConfigHooks";
import { setGlobalUserData } from "@/store/reducer/userConfig";
import { openCoin } from "@/store/reducer/coinRecharge";
import useLoginHooks from "@/hooks/useLoginHooks";
import { createImageUrl } from "@/utils/common";

const LoadingIcon = createImageUrl('common/loading.png');

const LoginModal = () => {
  const { t } = useTranslation();
  const { config } = useConfigHooks();
  const theme = useThemeHooks();
  const { id } = useLoginHooks();

  const [supportPhoneLogin, setSupportPhoneLogin] = useState(false);

  const [showPolicy, setShowPolicy] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [pageLoading, setPageLoading] = useState(false);
  const [wechatModalOpen, setWechatModalOpen] = useState(false);
  const currentUrl = encodeURIComponent(window.location.href);
  const hostname = window.location.hostname;

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse: any) => {
      if (tokenResponse?.access_token) {
        loginCallBack({
          token: String(tokenResponse?.access_token),
          login_type: "google",
        }).then((res: any) => {
          if (res.status === 200) {
            getUserInfo();
          } else {
            message.error(res?.response?.data?.message);
          }
        });
      }
    },
    onError: (data) => {
      console.log(data, "-----onError");
    },
  });

  function getTopLevelAndSecondLevelDomain(hostname: any) {
    // 分割主机名
    const parts = hostname.split(".");
    // 确保至少有三部分（例如 sub.example.com）
    if (parts.length > 2) {
      // 提取最后两部分作为顶级域名和二级域名
      return parts.slice(-2).join(".");
    }
    // 如果主机名部分不足，返回原始主机名
    return hostname;
  }
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

  const setCodeBtnTime = () => {
    setIsDisabled(true);
    setSecondsLeft(60);

    const intervalId = setInterval(() => {
      setSecondsLeft((prevSeconds) => {
        const newSeconds = prevSeconds - 1;
        if (newSeconds === 0) {
          clearInterval(intervalId);
          setIsDisabled(false);
        }
        return newSeconds;
      });
    }, 1000);
  };

  const modalVisible =
    useSelector((state: any) => state?.loginConfig?.modalVisible) || false;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const hideModal = () => {
    setPageLoading(false);
    store.dispatch(loginOption.closeModal());
  };

  const canIGetCoin = () => {
    const todayKey =
      "canClaimCoinToday_" + id +new Date().toISOString().split("T")[0]; // 生成今天的日期作为key
    const cachedResult = localStorage.getItem(todayKey);
    if (!cachedResult) {
      // 调用API获取数据
      getCanClaimCoinToday().then((res) => {
        if (res?.data?.status === 200) {
          const { is_first_login, has_claimed_coins_today } =
            res.data.data || {};
          // 将结果存储至localStorage，设置过期时间为第二天
          if (!has_claimed_coins_today) {
            localStorage.setItem(todayKey, "true");
            store.dispatch(openCoin(is_first_login));
          }
        }
      });
    }
  };

  const handleCancel = () => {
    store.dispatch(loginOption.closeModal());
  };
  const handleCodeClick = (type: string, destination: string) => {
    setPageLoading(true);
    setShowCode(true);
    toGetLoginCode({ destination, code_type: type }).then((res: any) => {
      setPageLoading(false);
      if (res?.data?.status === 200) {
        message.success(t("login.getCodeSuccess"));
        setCodeBtnTime();
      } else {
        message.error(t("获取验证码失败，请稍后重试"));
      }
    });
  };

  const getUserInfo = () => {
    message.success(t("common.login.success"));
    toUsersIslogin().then((res: any) => {
      store.dispatch({
        ...updateUserInfo(res.data.data),
        isUserInfoLoading: false,
        token: localStorage.getItem("token"),
      });
      getUserGuideInfo().then((res) => {
        if (res && res?.status === 200) {
          store.dispatch(setGlobalUserData(res.data.data));
        }
      });
      canIGetCoin()
      Cookies.set("token", localStorage.getItem("token") as string, {
        domain: `${topLevelAndSecondLevelDomain}`,
      });
      hideModal();
    });
  };

  const onFinish = (data: any) => {
    if (!data?.code || (supportPhoneLogin ? !data?.phone : !data?.email)) {
      return;
    }

    setPageLoading(true);
    toCheckLoginCode({
      code: data?.code,
      code_type: supportPhoneLogin ? "sms" : "email",
      destination: supportPhoneLogin ? data?.phone : data?.email,
    }).then((res: any) => {
      setPageLoading(false);
      if (res.status === 200) {
        getUserInfo();
      } else {
        message.error(res?.response?.data?.message);
      }
    });
  };

  const onFinishFailed = () => {};

  const WechatModal = () => {
    return (
      <Modal
        title={
          <div className={Styles.wechatTitle}>
            <div
              onClick={() => {
                setWechatModalOpen(false);
              }}
            >
              <LeftOutlined />
            </div>
            <div className={Styles.wechatTitleTextWrap}>
              <div>微信登录</div>
              <div className={Styles.wechatSubText}>使用微信扫码登录</div>
            </div>
            <div></div>
          </div>
        }
        open={wechatModalOpen}
        confirmLoading={confirmLoading}
        className={Styles.modal}
        onCancel={() => {
          setWechatModalOpen(false);
        }}
        centered
        footer={null}
        width={400}
      >
        <div className={Styles.content}>
          <div className={Styles.qrCode}>
            <QRCode
              color={theme === "dark" ? "#fff" : "#272b30"}
              size={240}
              value={"https://syngents.ai/login"}
            ></QRCode>
          </div>
          <div className={Styles.acceptTextWrap}>
            <Trans
              i18nKey="login.continue"
              values={{ policy: t("publish.policy") }}
            >
              <div
                className={Styles.acceptText}
                onClick={() => setShowPolicy(true)}
              >
                {t("publish.policy")}
              </div>
            </Trans>
          </div>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    setSupportPhoneLogin(config?.support_phone_login === "1");
  }, [config?.support_phone_login]);

  return (
    <Modal
      title={null}
      open={modalVisible}
      confirmLoading={confirmLoading}
      className={Styles.modal}
      onCancel={handleCancel}
      centered
      footer={null}
      width={400}
    >
      <div className={Styles.content}>
        <img src={logoIcon} />
        <div className={Styles.title}>{t("login.title")}</div>
        <Form
          name="loginFrom"
          className={Styles.form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {supportPhoneLogin ? (
            <Form.Item
              label={null}
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("login.inputPhone"),
                  pattern: /^1[3456789]\d{9}$/, // 目前只支持中国大陆手机号
                },
              ]}
              className={Styles.form_item}
            >
              <Input
                className={Styles.input}
                onChange={(e: any) => {
                  setPhone(e?.target?.value);
                }}
                placeholder={t("login.inputPhone")}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label={null}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("login.inputEmail"),
                  type: "email",
                },
              ]}
              className={Styles.form_item}
            >
              <Input
                className={Styles.input}
                onChange={(e: any) => {
                  setEmail(e?.target?.value);
                }}
                placeholder={t("login.inputEmail")}
              />
            </Form.Item>
          )}

          <div
            style={{
              display: "flex",
              gap: 16,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 24,
              position: "relative",
            }}
          >
            <Form.Item
              label={null}
              name="code"
              rules={[{ required: showCode, message: t("login.inputCode") }]}
              className={Styles.form_item}
            >
              <Input
                className={Styles.input}
                placeholder={t("login.inputCode")}
              />
            </Form.Item>
            <Button
              type="text"
              disabled={isDisabled}
              onClick={() =>
                handleCodeClick(
                  supportPhoneLogin ? "sms" : "email",
                  supportPhoneLogin ? phone : email
                )
              }
              className={Styles.getCodeBtn}
            >
              <div>
                {pageLoading ? (
                  <div className={Styles.gettingCodeWrap}>
                    <img src={LoadingIcon} className={Styles.icon} />
                    {t("获取中")}
                  </div>
                ) : isDisabled ? (
                  <div className={Styles.gettingCodeWrap}>
                    <Trans
                      i18nKey="login.secondsLeft"
                      values={{ second: String(secondsLeft) }}
                    >
                      {String(secondsLeft)}
                    </Trans>
                  </div>
                ) : (
                  t("login.getCode")
                )}
              </div>
            </Button>
          </div>

          <Button type="primary" htmlType="submit" className={Styles.continue}>
            <div className={Styles.goLogin}>{t("login.goLogin")}</div>
          </Button>
        </Form>

        {config.login_google_trigger === "1" ||
        config.login_wechat_trigger === "1" ? (
          <>
            <div className={Styles.splitWrap}>
              <div className={Styles.splitLine}></div>
              <div className={Styles.splitText}>{t("common.or")}</div>
              <div className={Styles.splitLine}></div>
            </div>
            <div className={Styles.extraWrap}>
              {config.login_google_trigger === "1" && (
                <Button
                  type="primary"
                  onClick={() => {
                    googleLogin();
                  }}
                  htmlType="button"
                  className={Styles.extraItem}
                >
                  <img src={googleIcon}></img>
                  <div className={Styles.extraText}>{t("login.google")}</div>
                </Button>
              )}
              {config.login_wechat_trigger === "1" && (
                <Button
                  type="primary"
                  onClick={() => {
                    const url = `https://open.weixin.qq.com/connect/qrconnect?appid=wx458921a2cb9325bc&redirect_uri=${currentUrl}&response_type=code&scope=snsapi_login&state=wechat_redirect`;
                    window.open(url, "_blank");
                  }}
                  htmlType="button"
                  className={Styles.extraItem}
                >
                  <img src={wechatIcon} />
                  <div className={Styles.extraText}>{t("login.wechat")}</div>
                </Button>
              )}
            </div>
          </>
        ) : null}
        <div className={Styles.acceptTextWrap}>
          <Trans
            i18nKey="login.continue"
            values={{ policy: t("publish.policy") }}
          >
            <div
              className={Styles.acceptText}
              onClick={() => setShowPolicy(true)}
            >
              {t("publish.policy")}
            </div>
          </Trans>
        </div>
      </div>
      <PrivatePolicy
        show={showPolicy}
        onClose={() => {
          setShowPolicy(false);
        }}
      />
      <WechatModal />
    </Modal>
  );
};

export default LoginModal;
