import React, { useState } from "react";
import Card from "./card";
import style from "./index.module.scss";

const iconMap: { [key: string]: string } = {
  ".pdf": "icon-pdf_fill",
  ".csv": "icon-csv_fill",
  ".jpg": "icon-jpg_fill",
  ".jpeg": "icon-jpg_fill",
  ".png": "icon-jpg_fill",
  ".webp": "icon-jpg_fill",
  ".md": "icon-md_fill",
  ".mp3": "icon-mp3_fill",
  ".wav": "icon-mp3_fill",
  ".mp4": "icon-mp4_fill",
  ".txt": "icon-txt_fill",
  ".xlsx": "icon-xlsx_fill",
  ".doc": "icon-word_fill",
  ".docx": "icon-word_fill",
  ".ppt": "icon-ppt_fill",
  ".pptx": "icon-ppt_fill",
};

const getImg = (fileName: string) => {
  const fileType = fileName?.substring(fileName.lastIndexOf("."));
  return iconMap[fileType] || "icon-pdf_fill"; // 使用默认图标类名
};

const UploadFilesList = (props: any) => {
  const { fileList, onDelete, isChat } = props;
  const [showDeleteBtn, setShowDeleteBtn] = useState(!!onDelete);
  const deleteCardFn = (index: number) => {
    onDelete && onDelete(index);
  };

  return (
    <div
      className={style.page}
      style={
        isChat
          ? {
              backgroundColor: "transparent",
              boxShadow: "none",
              paddingLeft: "0",
              maxHeight: "100%",
              overflow: "auto",
            }
          : {}
      }
    >
      {fileList.map((file: any, index: number) => {
        const fileName = file?.response?.data?.file_name || file?.name;
        const fileType = fileName?.substring(fileName.lastIndexOf("."));

        return (
          <Card
            key={file?.response?.data?.file_url || file?.name + index}
            iClass={getImg(fileName)}
            file_url={file?.response?.data?.file_url}
            file_name={file?.response?.data?.file_name || file?.name}
            file_type={file?.response?.data?.file_type || fileType}
            status={
              file.status === "uploading" ||
              isChat ||
              (file?.response?.data && file?.response?.data?.file_url)
                ? file.status
                : "error"
            }
            deleteCardFn={() => {
              deleteCardFn(index);
            }}
            isChat={isChat}
            showDeleteBtn={showDeleteBtn}
          />
        );
      })}
    </div>
  );
};

export default UploadFilesList;
