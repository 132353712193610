import { IFolderInfo } from "@/types/common";
import Styles from "./index.module.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation, Trans } from "react-i18next";
import HeadImage from "./HeadImage";
import {
  Modal,
  Alert,
  message,
  Button,
  Flex,
  Form,
  Input,
  Spin,
  // Radio,
  ConfigProvider,
} from "antd";
import ConfirmModal from "@/components/Modal";
import {
  createKnowledgeFolder,
  deleteKnowledgeFolder,
  getKnowledgeFolderList,
  updateKnowledgeFolder,
  // getKnowledgeFolderTags,
} from "@/apis/common";
import { ListType } from "./type";
// import SelectTool from "./SelectTool";
import FolderContent from "./FolderListContent";
import useLoginHooks from "@/hooks/useLoginHooks";

// 页面分页大小和当前页码常量
const pageSize = 99;
const pageNo = 1;

/** 文件夹列表 */
const FolderList = forwardRef(
  (
    {
      onFolderClick,
      type,
      requiredData,
      selectedFolderList,
      isModal,
    }: {
      onFolderClick: (folderId: number, name?: string, user_id?: number) => void;
      type?: ListType;
      requiredData?: {
        tag?: string;
        pub_scope?: number;
      };
      selectedFolderList?: number[];
      isModal?: boolean;
    },
    ref
  ) => {
    const [folders, setFolders] = useState<IFolderInfo[]>([]);
    const [selectFolderId, setSelectFolderId] = useState<number>(0);
    const [deleteFolderName, setDeleteFolderName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [tags, setTags] = useState<string[]>([]);
    const { t } = useTranslation();
    const ConfirmModalRef = useRef<any>(null);
    const [messageApi] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [actionType, setActionType] = useState<"add" | "edit">("add");
    const [form] = Form.useForm();

    const { isLogin } = useLoginHooks();

    /** 获取文件夹列表 */
    const getFolders = () => {
      setLoading(true);
      getKnowledgeFolderList({
        page_number: pageNo,
        page_size: pageSize,
        tag: requiredData?.tag === "全部" ? undefined : requiredData?.tag,
        // pub_scope: requiredData?.pub_scope,
      }).then((res: any) => {
        setLoading(false);
        setFolders(res?.data?.status === 200 ? res.data.data : []);
      });
    };

    /** 打开文件夹 */
    const handleFolderClick = (
      folderId: number,
      name?: string,
      user_id?: number
    ) => {
      onFolderClick(folderId, name, user_id);
    };

    /** 点击添加文件夹 */
    const handleAddFolderClick = (event?: React.MouseEvent) => {
      event?.stopPropagation();
      event?.preventDefault();
      // 重置表单内容
      form.resetFields();
      setIsModalOpen(true);
      setActionType("add");
    };

    /** 点击编辑文件夹 */
    const handleEditFolderClick =
      (folder: IFolderInfo) => (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        // 将选中的文件夹数据赋值给表单
        form.setFieldsValue({
          folderName: folder.name,
          folderDes: folder.desc,
          // folderPermissions: folder.pub_scope,
          // tags: folder.tags,
          img_url: folder.img_url,
        });
        setIsModalOpen(true);
        setActionType("edit");
        setSelectFolderId(folder.id);
      };

    /** 提交表单 */
    const handleFolderSubmit = (values: any) => {
      // 创建和更新的基本数据结构
      const basicData = {
        name: values.folderName,
        desc: values.folderDes,
        // pub_scope: values.folderPermissions,
        // tags: values.tags,
        img_url: values.img_url,
      };

      if (actionType === "add") {
        // 创建文件夹请求
        const createData = { ...basicData };
        setLoading(true);
        createKnowledgeFolder(createData)
          .then(() => {
            setIsModalOpen(false);
            getFolders();
            form.resetFields();
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (actionType === "edit") {
        // 编辑文件夹请求，确保 `id` 存在且为 Number
        if (typeof selectFolderId === "number") {
          const updateData = { ...basicData, id: selectFolderId };
          setLoading(true);
          updateKnowledgeFolder(updateData)
            .then(() => {
              setIsModalOpen(false);
              getFolders();
              form.resetFields();
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          console.error("Folder ID is invalid.");
        }
      }
    };

    /** 点击删除按钮操作 */
    const handleDeleteKnowledgeFolder =
      (folderInfo: IFolderInfo) => (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setSelectFolderId(folderInfo.id);
        setDeleteFolderName(folderInfo.name);
        ConfirmModalRef.current?.showModal();
      };

    /** 弹窗取消 */
    const handleCancel = () => {
      form.resetFields();
      setIsModalOpen(false);
    };

    /** 确定删除弹窗 */
    const deleteResult = (success = true) => {
      messageApi.open({
        content: (
          <Alert
            message={t(
              success ? "myagent.delete.success" : "myagent.delete.fail"
            )}
            description={
              success ? (
                <Trans
                  i18nKey="myagent.delete.successText"
                  values={{ name: deleteFolderName }}
                >
                  {deleteFolderName}
                </Trans>
              ) : (
                t("myagent.delete.failText")
              )
            }
            type={success ? "success" : "error"}
            showIcon
            closable
          />
        ),
        className: Styles.customClass,
        duration: 3,
      });
    };

    /** 确认删除文件夹 请求接口 */
    const confirmFn = () => {
      deleteKnowledgeFolder({ id: selectFolderId }).then((res: any) => {
        if (res?.data?.status === 200) {
          deleteResult();
          ConfirmModalRef.current?.hideModal();
          getFolders();
        } else {
          deleteResult(false);
        }
      });
    };

    // 导出增加文件夹的方法
    useImperativeHandle(ref, () => ({
      handleAddFolderClick,
    }));

    useEffect(() => {
      // 获取文件夹列表
      getFolders();
      // 当请求参数改变时/登录态改变时重新请求
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredData, isLogin]);

    // useEffect(() => {
    //   // 获取弹窗中的推荐标签
    //   getKnowledgeFolderTags({ keys: ["knowledge_folder_hot_tags"] })
    //     .then((res) => {
    //       const data = res.data?.data?.knowledge_folder_hot_tags;
    //       setTags(data);
    //     })
    //     .catch((err) => {
    //       console.log("获取热门标签出错" + err);
    //     });
    // }, []);

    return (
      <ConfigProvider
        theme={{
          token: {
            // 使用品牌色
            colorPrimary: "#29CC6A",
            colorBgContainer: "var(--color-bg-1)",
            colorBorder: "var(--color-border-1)",
          },
          components: {
            Input: {
              activeShadow: "none",
            },
            Modal: {
              contentBg: "var(--color-bg-2)",
              headerBg: "var(--color-bg-2)",
            },
          },
        }}
      >
        <div className={Styles.page}>
          {loading ? (
            // 如果正在加载，显示加载动画
            <Flex
              style={{ width: "100%", height: "100%" }}
              align="center"
              justify="center"
            >
              <Spin size="large" />
            </Flex>
          ) : (
            // 文件夹内容组件
            <div className={Styles.folder_container}>
              <FolderContent
                folders={folders}
                handleFolderClick={handleFolderClick} // 文件夹点击事件处理函数
                handleEditFolderClick={handleEditFolderClick} // 文件夹编辑事件处理函数
                handleDeleteKnowledgeFolder={handleDeleteKnowledgeFolder} // 文件夹删除事件处理函数
                type={type}
                requiredData={requiredData}
                selectedFolderList={selectedFolderList}
                isModal={isModal}
              />
            </div>
          )}

          {/* 模态框，显示用于创建或编辑知识库 */}
          <Modal
            title={actionType === "add" ? "创建知识库" : "编辑知识库"}
            width={558}
            open={isModalOpen}
            onOk={form.submit}
            onCancel={handleCancel}
            className={Styles.modal}
            footer={[
              <Button key="back" onClick={handleCancel}>
                取消
              </Button>,
              <Button key="submit" type="primary" onClick={form.submit}>
                确定
              </Button>,
            ]}
          >
            <Form layout="vertical" form={form} onFinish={handleFolderSubmit}>
              {/* 图片上传部分 */}
              <Form.Item name="img_url" className={Styles.formImg}>
                <Flex>
                  <HeadImage form={form} handleEditClick />
                </Flex>
              </Form.Item>

              {/* 文件夹名称输入框 */}
              <Form.Item
                name="folderName"
                label="名称"
                rules={[{ required: true, message: "请输入文件夹名称" }]}
              >
                <Input.TextArea
                  placeholder="输入知识库名称"
                  maxLength={100}
                  showCount
                  className={Styles.formName}
                  autoSize={{ minRows: 1 }}
                />
              </Form.Item>

              {/* 文件夹描述输入框 */}
              <Form.Item name="folderDes" label="描述">
                <Input.TextArea
                  placeholder="输入知识库内容的描述"
                  maxLength={2000}
                  showCount
                  autoSize={{ minRows: 3 }}
                  className={Styles.formDesc}
                />
              </Form.Item>

              {/* 文件夹权限选择 */}
              {/* <Form.Item
                name='folderPermissions'
                label='可见权限'
                rules={[{ required: true, message: "请选择权限" }]}
              >
                <Radio.Group>
                  <Radio value={1}>只有我</Radio>
                  <Radio value={2}>企业公开</Radio>
                </Radio.Group>
              </Form.Item> */}

              {/* 文件夹标签选择器 */}
              {/* <Form.Item label='标签' name='tags'>
                <SelectTool form={form} initialTags={tags} />
              </Form.Item> */}
            </Form>
          </Modal>

          {/* 删除确认模态框 */}
          <ConfirmModal
            ref={ConfirmModalRef}
            confirmFn={confirmFn}
            title={t("knowledge.delete.title")}
          >
            <Trans
              i18nKey="knowledge.delete.content"
              values={{ name: deleteFolderName }}
            >
              {deleteFolderName}
            </Trans>
          </ConfirmModal>
        </div>
      </ConfigProvider>
    );
  }
);
export default FolderList;
