import { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import { toGetSeekingList, toGetTagData, likeStatus } from "@/apis/common";
import Skeleton from "react-loading-skeleton";
import { Pagination } from "antd";
import "react-loading-skeleton/dist/skeleton.css";
import ToolItem from "@/components/ToolItem";
import { unique } from "@/utils/common";
import { getAgentCategory, toGetUserNames } from "@/apis/common";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import RecentPic from "@/assets/explore/recent.svg";

const SkeletonItem = () => {
  return (
    <div className={Styles.skeleton}>
      <Skeleton width="100%" height={200} />
      <div className={Styles.skeleton_info}>
        <div>
          <Skeleton width={30} height={30} borderRadius="50%" />
        </div>
        <div className={Styles.skeleton_info_name}>
          <Skeleton width={100} />
          <Skeleton width={50} />
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(new Array(12).fill(undefined));
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState<any>([]);
  const [activeCategory, setActiveCategory] = useState<any>({
    id: -1,
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(12);
  const [tagNameObj, setTagNameObj] = useState({});
  const [userObj, setUserObj] = useState<any>([]);
  const [likeStatusObj, setLikeStatusObj] = useState({});
  const [total, setTotal] = useState(0);

  const getCategory = () => {
    getAgentCategory({
      show_mode: 1,
    }).then((res) => {
      if (res.data && res.data.status === 200 && res.data.data.categories) {
        setCategory(
          [
            {
              id: -1,
              name: "最近",
              key: "common.category.recent",
              icon: RecentPic,
            },
            ...res.data.data.categories,
          ] || []
        );
      }
    });
  };

  const getSeekingList = () => {
    setLoading(true);
    const param: any = {
      page_number: pageNo,
      page_size: pageSize,
      show_mode: 1,
    };
    if (!!activeCategory && activeCategory.id !== -1) {
      param.category1 = activeCategory.id;
    }
    setData(new Array(12).fill(undefined));
    toGetSeekingList(param).then((res) => {
      setLoading(false);
      if (res.data && res.data.status === 200) {
        let data = res.data.data;
        setData(data);
        setTotal(res.data.total_items || []);

        let tagList: Array<number> = [];

        data.forEach((item: any, index: number) => {
          if (item.tags && item.tags.length) {
            tagList = [...tagList, ...item.tags];
          }
        });

        let userIdsList: Array<number> = [];
        let agentIdList: Array<number> = [];

        data.forEach((item: any, index: number) => {
          if (item.author) {
            userIdsList = [...userIdsList, item.author];
          }

          if (item && item.agent_id) {
            agentIdList = [...agentIdList, item.id];
          }
        });

        let newLikeStatusObj: any = { ...likeStatusObj };

        if (agentIdList.length) {
          likeStatus({
            pub_agent_ids: agentIdList,
          }).then((res: any) => {
            if (res.data && res.data.status === 200) {
              res.data.data.forEach((item: any, index: number) => {
                newLikeStatusObj[item.pub_agent_id] = item;
              });
            }
          });

          setTimeout(() => {
            console.log(newLikeStatusObj);
            setLikeStatusObj(newLikeStatusObj);
          });
        }

        if (userIdsList.length) {
          toGetUserNames({ ids: unique(userIdsList) }).then((res: any) => {
            if (res.data && res.data.status === 200 && res.data.data.length) {
              setUserObj([...res.data.data, ...userObj]);
            }
          });
        }
        if (tagList.length) {
          toGetTagData({ ids: unique(tagList) }).then((res: any) => {
            if (res.data && res.data.status === 200 && res.data.data.length) {
              const tagObj: any = {};

              res.data.data.forEach((item: any, index: number) => {
                tagObj[item.id] = item.name;
              });
              setTagNameObj(tagObj);
            }
          });
        }
      }
    });
  };

  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
  };

  const changeCategory = (item: any) => {
    setActiveCategory(item);
    setPageNo(1);
    // if (item.children && item.children.length) {
    //   setActiveCategory2(item.children[0]);
    // }
  };

  useEffect(() => {
    // getSeekingList();
    getCategory();
  }, []);

  useEffect(() => {
    getSeekingList();
  }, [activeCategory.id, pageNo]);

  return (
    <div className={Styles.page}>
      <div className={Styles.page_header}>
        <div className={classnames(Styles.category, "scroll-hide")}>
          {category.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={Styles.category_item}
                onClick={() => {
                  changeCategory(item);
                }}
              >
                <div className={Styles.category_item_name_box}>
                  {item.icon && (
                    <img
                      src={item.icon}
                      className={classnames(
                        Styles.category_img,
                        activeCategory?.id === item.id
                          ? Styles.category_img_active
                          : ""
                      )}
                      loading="lazy"
                    ></img>
                  )}
                  <div
                    className={classnames(
                      Styles.category_name,
                      activeCategory?.id === item.id
                        ? Styles.category_name_active
                        : ""
                    )}
                  >
                    {t(item.key)}
                  </div>
                </div>
                <div
                  className={classnames(
                    Styles.category_item_bottom_active,
                    activeCategory?.id === item.id
                      ? Styles.category_item_bottom
                      : ""
                  )}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className={Styles.category_two}>
        {!!activeCategory.children && !!activeCategory.children.length && (
          <>
            {activeCategory.children.map((item: any, index: number) => {
              return (
                <div
                  className={classnames(
                    Styles.category_two_item,
                    item.id === activeCategory2.id
                      ? Styles.category_two_item_active
                      : ""
                  )}
                  onClick={() => {
                    setActiveCategory2(item);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </>
        )}
      </div> */}
      <div className={Styles.page_recommand}>
        {data?.map((item, index) => {
          return (
            <div className={Styles.page_recommand_item} key={index}>
              {!loading && item ? (
                <ToolItem
                  agentInfo={item}
                  tagNameObj={tagNameObj}
                  userObj={userObj}
                  likeStatusObj={likeStatusObj}
                  setLikeStatusObj={setLikeStatusObj}
                />
              ) : (
                <SkeletonItem />
              )}
            </div>
          );
        })}
      </div>
      <div className={Styles.pagination}>
        <Pagination
          pageSize={pageSize}
          current={pageNo}
          total={total}
          showSizeChanger={false}
          hideOnSinglePage
          onChange={changePagination}
        />
      </div>
    </div>
  );
};

export default Home;
