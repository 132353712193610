// store.ts

import {  createSlice } from "@reduxjs/toolkit";

// 定义初始状态
interface ModalState {
  modalVisible: boolean;
}

const initialState: ModalState = {
  modalVisible: false,
};

// 创建 slice
const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal(state) {
      state.modalVisible = true;
    },
    closeModal(state) {
      state.modalVisible = false;
    },
  },
});

// 导出 action creators
export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
