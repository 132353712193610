export default {
  "common.welcome": "Login AI Agent",
  "common.password": "Password:",
  "common.password.confirm": "Confirm Password:",
  "common.userName": "UserName:",
  "common.login": "Login",
  "common.login.success": "Login Success",
  "common.register": "Register",
  "common.register.success": "Register Success",
  "common.register.title": "Register Account",
  "common.product.name": "Syngents",
  "common.user.placeHolder": "Please Input your username!",
  "common.password.placeHolder": "Please Input your password!",
  "common.seeking": "Explore",
  "common.chat": "Chat",
  "common.canvas": "Canvas",
  "common.community": "Community",
  "common.create": "Create Agent",
  "common.myAgent": "My Agent",
  "common.publish": "Publish Agent",
  "common.submit": "Submit",
  "common.send": "Send",
  "common.tags": "Tags",
  "common.publish.time": "Publication Date:",
  "common.update.time": "Last Updated:",
  "common.join.chat": "Join The Chat",
  "common.create.same": "Create the style",
  "common.agent.singal": "Single-Agent",
  "common.chat.default.greetings": "How can I assist you today?",
  "common.you": "You",
  "common.edit": "Edit",
  "common.back": "Back",
  "common.category": "Category",
  "common.yes": "Yes",
  "common.no": "No",
  "common.category.recent": "Recent",
  "common.category.work": "Work",
  "common.category.learning": "Learning",
  "common.category.lifestyle": "LifeStyle",
  "common.like": "Like",
  "common.agentCard": "Card Agent",
  "common.agent": "Agent",

  create: {
    dialog: {
      agent: {
        desc: "Start creating your Agent, you can either create it directly or go to your Agent homepage",
      },
    },
    normalmode: "Normal Mode",
    professionalmode: "Professional Mode",
    name: {
      agent: "Agent Name",
      placeholder: "Please Enter The Agent Name",
    },
    introduce: {
      agent: "Agent Introduction",
      placeholder: "Please Enter The Model Provider",
    },
    provider: {
      model: "Model Provider",
      placeholder: "Please Enter The Model Provider",
      modelDesc:
        "chatgpt can only chat with large models, but the response is fast,autogen-gpt can achieve networking search, climbing knowledge base, drawing and other functions, but the response is slightly slower",
    },
    clue: {
      edit: "Prompt Word Editor",
      placeholder: "Please Select Prompt Word editor type",
      sender: "Sender",
      humanInput: {
        placeholder: "Please Select Sendor Type",
      },
      receiver: "Receiver",
      senderTip:
        "Act as your avatar to send and receive messages from other agents.",
      receiverTip:
        "Play the role of different agents and chat with your user agent.",
    },
    agent: {
      type: "Agent Type",
      tools: "Agent Tools",
    },
    reply: {
      count: "Maximum Automatic Consecutive Reply Count",
      countTip:
        "Maximum number of replies between the user agent and other agents. The higher the value, the more the reply content, the higher the corresponding quality, but it will consume more computing power.",
    },
    input: {
      format: "Input Format",
      prompts: "System Input Prompts",
      formatTip:
        'Whether the user agent needs your input every time it receives a message: (1) When "ALWAYS", the agent will prompt you for input every time it receives a message. (2) When "TERMINATE", the agent will only prompt you for input if the termination message has been received or the maximum number of consecutive automatic replies has been reached. (3) When "NEVER", the agent will never prompt you for input.',
    },
    emotional: {
      temperature: "Temperature",
    },
    image: {
      create: "Image Generation",
      desc: "You can input a description, such as 'a cat wearing a spacesuit walking on the moon,' and artificial intelligence will generate a corresponding image based on this description. This technology typically relies on complex algorithms and a vast amount of image data for training, enabling it to understand and transform textual information into visual content. This functionality can be used in various creative fields, such as art creation, product design, entertainment, and more.",
      cover: "Image Cover",
    },
    info: {
      search: "Information Retrieval",
    },
    preview: {
      title: "Debugging and Preview",
    },
    singal: {
      agent: "Singal Agent",
    },
    muti: {
      agent: "Muti Agent",
    },
    to: {
      detail: "View the Agent I Created",
    },
    conversation: "Conversation-based",
    greeting: {
      placeholder: "Please Enter a greeting.",
    },
    upload: {
      files: "Upload Files",
      tips: "It supports 'txt', 'json', 'csv', 'tsv', 'md', 'html', 'htm', 'rtf', 'rst', 'jsonl', 'log', 'xml', 'yaml', 'yml', and 'pdf' file formats, with each file not exceeding 10MB.",
    },
    last: {
      updatetime: "Last saved time:",
    },
    category: {
      placeholder: "Please select a category",
    },
    card: {
      title: "Card Title",
      titlePlaceholder: "Please input the card title",
      cardContent: "Card Content",
      maxCardNumber: "(Up to 9)",
      uploadMore: "Upload More",
    },
    receiverNamePlaceholder: "Please enter the Agent name",
    changePortrait: "Change Portrait",
    nickName: "Nickname",
    portrait: "Portrait",
    receiverCode: "Agent Number",
    deleteReceiver: "Delete Agent",
    addReceiver: "Add Agent",
    save: "Save",
    saveTip: "The latest edits will take effect after saving",
    normalDesc: "Single-agent mode, one agent helps you complete tasks",
    professionalDesc:
      "Multi-agent mode, multiple agents work together to complete tasks",
    systemMessageTip: "Please enter the system input prompt",
    saveSuccess: "Save Successful",
    saveFail: "Save Failed, please try again later",
    receiverNameRepeat: "The Agent name already exists, please change it",
    unsavedTitle: "You have not saved the edits",
    unsavedContent: "If you confirm to back, your current edit will be lost.",
    unsavedConfirm: "confirm",
  },

  "publish.setting.title": "Publishing Settings",
  "publish.market.title": "Place Publishing",
  "publish.market.desc":
    "Users can discover published Agents through the Agent Place and make Agents available for use by other users.",
  "publish.api.title": "Generate API",
  "publish.api.desc":
    "Users can proceed with further development through the generated API",
  "publish.link.title": "Generate Publish Link",
  "publish.link.desc":
    "Users can interact directly with the Agent by accessing it through a web page",
  "publish.third.title": "Connect Third Party",
  "publish.third.desc":
    "Users can link a third-party app, and once the configuration is complete, they can use it within the app.",
  "publish.dialog.title": "Publishing Information Settings",
  "publish.dialog.style": "Publishing Style",
  "publish.dialog.stylePlaceHolder": "Please Select a Publishing Style",
  "publish.dialog.permission": "Publish Permissions",
  "publish.dialog.toPlace": "Publish to Agent Place",
  "publish.dialog.toDashBoard": "Only Publish to Dashboard",
  "publish.dialog.edit.permission": "Edit Permissions",
  "publish.dialog.viewOnly": "View Only",
  "publish.dialog.editView": "Edit and View",
  "publish.dialog.Agreement":
    "Click to Read the 《Agent Intelligent Platform Creation Agreement》",
  "publish.copy.success": "Copy Successful!",
  "publish.recommand.tags": "Recommend tags",
  "publish.question.example": "Sample questions",
  "publish.editable": "Editable",
  "publish.tags.placeholder": "Please select a tag",
  "publish.btnText": "Publish to the market",
  "publish.agreement": "Publishing implies your agreement to comply with",
  "publish.policy": "《Content Policy of Sygents.ai》",
  "publish.last.time": "Last publish Time:",
  "publish.rollback": "Rollback",
  "publish.rollbackTip":
    'Are you sure you want to rollback "<0>{{name}}</0>"吗？',
  "publish.success": "Publish Successful!",
  "publish.toCompany": "Publish to Enterprise zone",

  chat: {
    withModal: "Chat with Large Agent",
    withAgent: "Intelligent Agent List",
    input: {
      placeholder: "Enter the content you want to know",
    },
    delete: {
      title: "Delete Conversation?",
      content: 'Are you sure you want to delete "<0>{{name}}</0>"?',
    },
    source: "Source",
    answer: "Answer",
    related: "Related",
    copy: "copy",
    copySuccess: "Copy Successful",
    tryAgain: "Try Again",
    localKnowledge: "Local Knowledge",
    refresh: "refresh",
  },
  "community.explore": "Explore",
  "community.frontier": "AI Frontier",
  "community.cameby": "Came by",
  "community.member": "Members",
  "community.online": "Online",
  "community.watched": "Watched",
  "community.share": "Share",
  "community.days.ago": "d ago",
  login: {
    title: "Log in to Syngents",
    inputEmail: "Enter your email",
    goLogin: "Log in",
    google: "Sign in with Google",
    wechat: "Sign in with WeChat",
    continue: "Continuing signifies that you accept our <0>{{policy}}</0>",
    inputCode: "Enter verification code",
    getCode: "Get Code",
    getCodeSuccess: "Verification code sent successfully",
    secondsLeft: "<0>{{second}}</0> seconds left to resend",
    gettingCode: "Sending code...",
  },
  myagent: {
    app: "App",
    repository: "Repository",
    tools: "Tools",
    newagent: "Create New Agent",
    subtotal: {
      left: "One has created a total of",
      right: "agents",
    },
    delete: {
      title: "Delete Agent",
      content: 'Are you sure you want to delete this agent? "<0>{{name}}</0>"',
      success: "Successfully Deleted",
      successText: '"<0>{{name}}</0>"has been removed from My Agents list!',
      fail: "Deletion failed",
      failText:
        "Deletion failed. Please try again later or contact the administrator.",
    },
    newCardAgent: "Create New Card Agent",
    uploadImage: "Upload Image",
    empty: "No data",
  },

  "explore.tab.tool": "Multimodal Tool",
  "explore.tab.analysis": "Research & Analysis",
  "explore.tab.writing": "Professional Writing",
  "explore.tab.coding": "Coding",
  "explore.tab.prompt": "Prompt",
  "explore.tab.assistant": "Micro Assistant",
  "explore.tab.entertainment": "Entertainment",
  privacy_policy: {
    title: "Syngents.ai Service Terms",
    introduction: "Introduction",
    welcome_message:
      'Welcome to the Syngents.ai platform (hereinafter referred to as "Syngents.ai" or "we"). These Service Terms (the "Terms") govern your access to and use of the websites, mobile applications, and other related products and services provided by Syngents.ai (collectively referred to as the "Service"). By accessing or using our Service, you agree to be bound by these Terms and our Privacy Policy.',
    account_registration_and_security: "Account Registration and Security",
    provide_accuate_information:
      "You must provide true, accurate, and complete information to create an account and regularly update your account information. ",
    responsibility_of_account:
      "You are responsible for all activities that occur under your account. ",
    right_of_refusal:
      "Syngents.ai has the right to refuse service, terminate accounts, or remove content, especially in cases of violation of these Terms.",
    user_content: "User Content ",
    retain_ownership:
      "You retain ownership of the content you submit through Syngents.ai, but you grant Syngents.ai a non-exclusive, royalty-free, worldwide, perpetual, and fully sublicensable right.",
    declare_and_guarantee:
      "You represent and warrant that you own all rights to the content submitted or have obtained the necessary permissions.",
    user_behavior: "User Conduct ",
    agreement_of_behavior:
      "You agree not to use the Service for the following actions: ",
    prohibited_behaviors_string: `{
  "A": "Post or disseminate defamatory, obscene, pornographic, vulgar, offensive, or racially discriminatory content. ",
  "B": "Infringe on intellectual property rights, trade secrets, privacy rights, or other proprietary rights. ",
  "C": "Submit content containing viruses, trojans, spyware, or other destructive code. ",
  "D": "Post unauthorized advertisements, spam, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling. ",
  "E": "Provide false or misleading information, or impersonate any person or entity. ",
  "F": "Violate any applicable laws or regulations, including but not limited to copyright law, trademark law, privacy law, and anti-spam laws. ",
  "G": "Promote illegal, harmful, or violent activities. ",
  "H": "Harass, threaten, bully, deceive, or otherwise interfere with other users. ",
  "I": "Violate any procedures, policies, or regulations of the Service. ",
  "J": "Interfere with or disrupt the normal operation of the Service or related networks. ",
  "K": "Access the Service, other accounts, or connected computer systems or networks without authorization. ",
  "L": "Use any robots, spiders, scraping tools, or other automated methods to access the Service. ",
  "M": "Reverse engineer, decompile, disassemble, or otherwise attempt to obtain the source code of the Service. ",
  "N": "Modify, adapt, crack, or otherwise compromise the integrity of the Service. ",
  "O": "Copy, replicate, sell, resell, or exploit any part of the Service without the explicit written permission of Syngents.ai."
}`,
    prohibited_behaviors: {
      A: "Post or disseminate defamatory, obscene, pornographic, vulgar, offensive, or racially discriminatory content. ",
      B: "Infringe on intellectual property rights, trade secrets, privacy rights, or other proprietary rights. ",
      C: "Submit content containing viruses, trojans, spyware, or other destructive code. ",
      D: "Post unauthorized advertisements, spam, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling. ",
      E: "Provide false or misleading information, or impersonate any person or entity. ",
      F: "Violate any applicable laws or regulations, including but not limited to copyright law, trademark law, privacy law, and anti-spam laws. ",
      G: "Promote illegal, harmful, or violent activities. ",
      H: "Harass, threaten, bully, deceive, or otherwise interfere with other users. ",
      I: "Violate any procedures, policies, or regulations of the Service. ",
      J: "Interfere with or disrupt the normal operation of the Service or related networks. ",
      K: "Access the Service, other accounts, or connected computer systems or networks without authorization. ",
      L: "Use any robots, spiders, scraping tools, or other automated methods to access the Service. ",
      M: "Reverse engineer, decompile, disassemble, or otherwise attempt to obtain the source code of the Service. ",
      N: "Modify, adapt, crack, or otherwise compromise the integrity of the Service. ",
      O: "Copy, replicate, sell, resell, or exploit any part of the Service without the explicit written permission of Syngents.ai.",
    },
    intellectual_property: "Intellectual Property ",
    protection_of_intellectual_property:
      "The content, software, and trademarks of Syngents.ai are legally protected and may not be used without authorization. ",
    acknowledge_and_agree:
      "You acknowledge and agree not to infringe on the intellectual property rights of Syngents.ai or any third party.",
    disclaimer_and_limitation_of_liability:
      "Disclaimer of Warranties and Limitation of Liability ",
    service_provided_as_is:
      'The Service is provided "as is" and Syngents.ai assumes no responsibility for any interruptions, errors, or loss of user data. ',
    no_liability_for_damage:
      "Under no circumstances will Syngents.ai be liable for any direct, indirect, special, consequential, or punitive damages, including but not limited to profits, goodwill, loss of use, data loss, or other intangible losses.",
    changes_to_terms: "Term Changes ",
    right_to_modify_terms:
      "Syngents.ai may modify these Terms at any time and will notify you through the website or email. ",
    acceptance_of_new_terms:
      "Continued use of the Service indicates your acceptance of the new Terms. If you do not agree to the new Terms, you must stop using the Service.",
    applicable_law_and_dispute_resolution:
      "Applicable Law and Dispute Resolution",
    governing_law:
      "These Terms are governed by the laws of the jurisdiction where Syngents.ai operates.",
    dispute_resolution:
      "Any disputes should first be resolved through negotiation, and if not resolved, should be submitted to binding arbitration.",
    miscellaneous: "Other",
    invalid_terms:
      "If any part of these Terms is unenforceable or invalid, that part will be limited or eliminated to the minimum extent necessary. ",
    no_assignment:
      "You may not assign or transfer these Terms or any rights and permissions granted under these Terms.",
    contact_information: "Contact Information ",
    contact_us:
      "If you have any questions or concerns regarding these Terms, please contact us at <0>{{email}}</0>",
  },
  workflow: {
    fileName: "File Name",
  },
  status: "Status",
  size: "Size",
  action: "Action",
  cancel: "cancel",
  confirm: "confirm",
  logout: "logout",
  companyManage: "enterprise manage",
};
