import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import header from "@/common/headerbar";
import langList from "@/common/langList";
import LanguagePic from "@/assets/header/language.svg";
import LanguageLightPic from "@/assets/header/language-light.svg";
import SettingIcon from "@/assets/header/setting.svg";

import ZhijiCnLight from "@/assets/header/zhiji-cn-light.svg";
import ZhijiCnDark from "@/assets/header/zhiji-cn-dark.svg";
import ZhijiEnLight from "@/assets/header/zhiji-en-light.svg";
import ZhijiEnDark from "@/assets/header/zhiji-en-dark.svg";

import MessagePic from "@/assets/header/message.svg";
import { useNavigate } from "react-router-dom";
import MoonPic from "@/assets/header/moon.svg";
import MoonLightPic from "@/assets/header/moon-light.svg";
import Styles from "./index.module.scss";
import classnames from "classnames";
import { Select, Popover } from "antd";
import { useTranslation } from "react-i18next";
import useThemeHooks from "@/hooks/useThemeHooks";
// import { FeedbackFish } from "@feedback-fish/react";
import useLoginHooks from "@/hooks/useLoginHooks";
import store, { loginOption } from "@/store";
import Avatar from "@/assets/common/teacher.svg";
import unLogin from "@/assets/menu/unLogin.svg";
import { getUserCurrentCompany } from "@/apis/common";
import LogoutLightPic from "@/assets/common/logout-light.svg";
import Cookies from "js-cookie";
import useGlobalUserData from "@/hooks/useUserConfigHook";

function getTopLevelAndSecondLevelDomain(hostname: any) {
  // 分割主机名
  const parts = hostname.split(".");
  // 确保至少有三部分（例如 sub.example.com）
  if (parts.length > 2) {
    // 提取最后两部分作为顶级域名和二级域名
    return parts.slice(-2).join(".");
  }
  // 如果主机名部分不足，返回原始主机名
  return hostname;
}

const { Header } = Layout;
const { Option } = Select;

const HeaderBar = () => {
  let navigate = useNavigate();

  const { isLogin, username, isUserInfoLoading } = useLoginHooks();
  const { avatar } = useGlobalUserData();

  const { t, i18n } = useTranslation();

  const [themetype, setThemeType] = useState(
    localStorage.getItem("theme") || "dark"
  );

  const hostname = window.location.hostname;
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

  const [lang, setLang] = useState(i18n.language);

  const headerLogo =
    lang === "zh-CN"
      ? themetype === "dark"
        ? ZhijiCnDark
        : ZhijiCnLight
      : themetype === "dark"
      ? ZhijiEnDark
      : ZhijiEnLight;

  const theme = useThemeHooks();

  const themeList = [
    {
      value: "light",
      label: "白天",
    },
    {
      value: "dark",
      label: "黑夜",
    },
  ];

  const [isAdmin, setIsAdmin] = useState(false);

  const changeTheme = () => {
    if (themetype === "dark") {
      localStorage.setItem("theme", "light");
      document.querySelectorAll("html")[0].setAttribute("theme", "light");
    } else {
      localStorage.setItem("theme", "dark");
      document.querySelectorAll("html")[0].setAttribute("theme", "dark");
    }
    setThemeType(themetype === "dark" ? "light" : "dark");
  };

  const changeLanguage = (item: any) => {
    setLang(item.value);
    i18n.changeLanguage(item.value);
    window.location.reload();
  };

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const logout = (type: string) => {
    Cookies.set("token", "", {
      domain: `${topLevelAndSecondLevelDomain}`,
    });

    localStorage.removeItem("token");

    navigate("/");
    window.location.reload();
  };

  const languagePopover = () => {
    return (
      <div className={Styles.language_popover_inside}>
        {langList.map((item, index) => {
          return (
            <div
              className={classnames(
                Styles.language_popover_inside_item,
                lang === item.value
                  ? Styles.language_popover_inside_item_active
                  : ""
              )}
              onClick={() => {
                changeLanguage(item);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    );
  };

  const userPopover = () => {
    return (
      <div className={Styles.userWrap}>
        {isAdmin && (
          <div className={Styles.logout}>
            <img src={SettingIcon}></img>
            {t("companyManage")}
          </div>
        )}
        <div
          className={Styles.logout}
          onClick={() => {
            logout("logout");
          }}
        >
          <img src={LogoutLightPic}></img>
          {t("logout")}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // getUserCurrentCompany().then((res: any) => {
    //   if (res.data && res?.data.is_admin) {
    //     setIsAdmin(true);
    //   }
    // });
  }, []);

  return (
    <Header className={Styles.header}>
      <div className={Styles.left_box}>
        <div
          className={Styles.logo_box}
          onClick={() => {
            navigate("/");
          }}
        >
          <img className={Styles.logo} src={headerLogo} alt="" loading='lazy'></img>
          {/* <span className={Styles.title}>{t("common.product.name")}</span> */}
        </div>
      </div>
      <div className={Styles.right_box}>
        {/* <img className={Styles.right_icon} src={MessagePic}></img> */}
        {/* <Popover
          content={languagePopover}
          placement='bottom'
          className={Styles.language_popover}
        >
          <img
            className={Styles.right_icon}
            src={theme === "dark" ? LanguagePic : LanguageLightPic}
          ></img>
        </Popover> */}

        <img
          className={Styles.right_icon}
          src={theme === "dark" ? MoonPic : MoonLightPic}
          alt=""
          onClick={() => {
            changeTheme();
          }}
        ></img>

        {isLogin ? (
          <Popover
            content={userPopover}
            placement='bottomLeft'
            className={Styles.language_popover}
          >
            <div
              style={{
                background: `url(${
                  isLogin ? avatar || Avatar : unLogin
                }) center center / cover`,
              }}
              className={Styles.user_icon}
            ></div>
          </Popover>
        ) : (
          !isUserInfoLoading && (
            <div onClick={onLoginAndRegister}>
              <span className={Styles.login}>{t("common.login")}</span>
            </div>
          )
        )}

        {/* <FeedbackFish projectId="fa01aa098d108b">
          <div>Send feedback</div>
        </FeedbackFish> */}
        {/* <div className={Styles.theme_box}>
          <Select
            className={Styles.theme_select}
            onChange={(value) => {
              changeTheme(value);
            }}
            value={themetype}
          >
            {themeList.map((item) => {
              return <Option key={item.value}>{item.label}</Option>;
            })}
          </Select>
        </div> */}

        {/* <div className={Styles.lang_box}>
          <Select
            className={Styles.lang_select}
            onChange={(value) => {
              setLang(value);
              i18n.changeLanguage(value);
              window.location.reload();
            }}
            value={lang}
          >
            {langList.map((item) => {
              return (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
        </div> */}
      </div>
    </Header>
  );
};

export default HeaderBar;
