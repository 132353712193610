export default {
  "common.welcome": "AI 에이전트 로그인",
  "common.password": "비밀번호:",
  "common.password.confirm": "비밀번호 확인:",
  "common.userName": "사용자 이름:",
  "common.login": "로그인",
  "common.login.success": "로그인 성공",
  "common.register": "등록",
  "common.register.success": "등록 성공",
  "common.register.title": "계정 등록",
  "common.product.name": "새로운 지식체",
  "common.user.placeHolder": "사용자 이름을 입력하세요!",
  "common.password.placeHolder": "비밀번호를 입력하세요!",
  "common.seeking": "탐색",
  "common.chat": "대화",
  "common.canvas": "캔버스",
  "common.community": "커뮤니티",
  "common.create": "지능체 생성",
  "common.myAgent": "내 지능체",
  "common.publish": "지능체 공개",
  "common.submit": "제출",
  "common.send": "전송",
  "common.tags": "태그",
  "common.publish.time": "게시 시간:",
  "common.update.time": "최근 업데이트:",
  "common.join.chat": "대화 참가",
  "common.create.same": "동일한 것 생성",
  "common.agent.singal": "단일 지능체",
  "common.back": "뒤로",
  "common.chat.default.greetings": "오늘 무엇을 도와 드릴까요?",
  "common.you": "당신",
  "common.edit": "편집",
  "common.category": "카테고리",
  "common.yes": "예",
  "common.no": "아니요",
  "common.category.recent": "최근",
  "common.category.work": "작업",
  "common.category.learning": "학습",
  "common.category.lifestyle": "라이프스타일",

  "create.dialog.agent.desc":
    "Agent 생성을 시작하고 직접 생성하거나 Agent 홈페이지로 이동할 수 있습니다",
  "create.normalmode": "일반 모드",
  "create.professionalmode": "전문가 모드",
  "create.name": "지능체 이름",
  "create.name.placeholder": "지능체 이름을 입력하세요",
  "create.introduce": "지능체 소개",
  "create.introduce.placeholder": "지능체 소개를 입력하세요",
  "create.provider": "모델 제공 업체",
  "create.provider.placeholder": "모델 제공 업체 선택",
  "create.clue.edit": "힌트 편집",

  "create.clue.placeholder": "힌트 편집 유형 선택",
  "create.clue.sender": "보낸 사람",
  "create.humanInput.placeholder": "보낸 사람 형식 선택",
  "create.clue.receiver": "받는 사람",
  "create.agent.type": "Agent 유형",
  "create.repository.upload": "지식 저장소 업로드",
  "create.repository.greetings": "인사말",
  "create.agent.tools": "Agent 도구",
  "create.reply.count": "최대 자동 연속 응답 횟수:",
  "create.input.format": "입력 형식",
  "create.input.prompts": "시스템 입력 프롬프트",
  "create.emotional.temperature": "감정 온도:",
  "create.image.create": "이미지 생성",
  "create.info.search": "정보 검색",
  "create.image.desc":
    "예를 들어 '우주복을 입은 고양이가 달 위를 걷는'과 같은 설명을 입력하면 인공 지능은 이 설명을 기반으로 해당하는 이미지를 생성합니다. 이 기술은 일반적으로 복잡한 알고리즘과 대량의 이미지 데이터를 사용하여 훈련되어 텍스트 정보를 시각적 콘텐츠로 변환할 수 있습니다. 이러한 기능은 예술 작품, 제품 디자인, 엔터테인먼트 등 다양한 창조적 분야에 활용할 수 있습니다.",
  "create.preview.title": "디버그 및 미리 보기",
  "create.singal.agent": "단일 Agent",
  "create.muti.agent": "다중 Agent",
  "create.to.detail": "내가 생성한 Agent 보기",
  "create.conversation": "대화 유형",
  "create.image.cover": "배경 이미지",
  "create.upload": "파일 업로드",
  "create.greeting.placeholder": "인사말을 입력하세요",
  "create.upload.tips":
    "지원되는 파일 형식: 'txt', 'json', 'csv', 'tsv', 'md', 'html', 'htm', 'rtf', 'rst', 'jsonl', 'log', 'xml', 'yaml', 'yml', 'pdf', 각 파일 크기 제한은 10MB 미만입니다.",
  "create.last.updatetime": "마지막 저장 시간:",
  "create.category.placeholder": "카테고리 선택",

  "publish.setting.title": "게시 설정",
  "publish.market.title": "시장에 공개",
  "publish.market.desc":
    "사용자는 에이전트 시장에서 공개된 에이전트를 찾아 다른 사용자에게 공개할 수 있습니다",
  "publish.api.title": "API 생성",
  "publish.api.desc": "사용자는 API를 생성하여 추가 개발이 가능합니다",
  "publish.link.title": "공개 링크 생성",
  "publish.link.desc":
    "사용자는 웹 페이지를 통해 직접 에이전트에 접근할 수 있습니다",
  "publish.third.title": "제3자 연동",
  "publish.third.desc":
    "사용자는 제3자 앱을 연동할 수 있으며 구성을 완료한 후 해당 앱에서 사용할 수 있습니다",
  "publish.dialog.title": "게시 정보 설정",
  "publish.dialog.style": "게시 스타일 선택",
  "publish.dialog.stylePlaceHolder": "게시 스타일 선택",
  "publish.dialog.permission": "게시 권한 설정",
  "publish.dialog.toPlace": "Agent 플라자에 공개",
  "publish.dialog.toDashBoard": "개인 센터에만 공개",
  "publish.dialog.edit.permission": "편집 권한 설정",
  "publish.dialog.viewOnly": "보기 전용",
  "publish.dialog.editView": "편집 및 보기",
  "publish.dialog.Agreement": "『Agent 지능체 플랫폼 생성 계약』을 읽으세요",
  "publish.copy.success": "복사 성공",
  "publish.recommand.tags": "추천 태그",
  "publish.question.example": "질문 예시",
  "publish.editable": "편집 가능",
  "publish.tags.placeholder": "태그 선택",
  "publish.btntext": "시장에 공개",
  "publish.agreement": "게시는 귀하는 동의하는 것으로 간주됩니다",
  "publish.policy": "『Sygents.ai 콘텐츠 정책』",
  "publish.last.time": "최종 게시 시간:",

  chat: {
    withModal: "대형 모델과 대화",
    withAgent: "에이전트 목록",
    input: {
      placeholder: "알고 싶은 내용을 입력하세요",
    },
    delete: {
      title: "대화 삭제?",
      content: "“<0>{{name}}</0>” 대화를 삭제하시겠습니까?",
    },
  },

  "community.explore": "탐색",
  "community.frontier": "AI 프론티어",
  "community.cameby": "방문자",
  "community.member": "멤버",
  "community.online": "온라인",
  "community.watched": "조회수",
  "community.share": "공유수",

  myagent: {
    app: "앱",
    repository: "지식 저장소",
    tools: "도구",
    newagent: "새로운 에이전트 생성",
    subtotal: {
      left: "총 생성된 에이전트 수:",
      right: "에이전트",
    },
    delete: {
      title: "에이전트 삭제",
      content: '이 에이전트를 삭제하시겠습니까? "<0>{{name}}</0>"',
      success: "성공적으로 삭제되었습니다",
      successText:
        '"<0>{{name}}</0>" 가 마이 에이전트 목록에서 삭제되었습니다!',
      fail: "삭제 실패",
      failText: "삭제 실패. 나중에 다시 시도하거나 관리자에게 문의하십시오.",
    },
  },

  "explore.tab.tool": "다중 모드 도구",
  "explore.tab.analysis": "연구 분석",
  "explore.tab.writing": "전문 글쓰기",
  "explore.tab.coding": "코딩",
  "explore.tab.prompt": "프롬프트 워드",
  "explore.tab.assistant": "어시스턴트",
  "explore.tab.entertainment": "엔터테인먼트와 휴식",
  privacy_policy: {
    title: "Syngents.ai 서비스 약관",
    introduction: "소개",
    welcome_message:
      '"Syngents.ai" 또는 "우리"로 통칭되는 Syngents.ai 플랫폼에 오신 것을 환영합니다. 이 서비스 약관("약관")은 Syngents.ai가 제공하는 웹 사이트, 모바일 애플리케이션 및 기타 관련 제품 및 서비스(통틀어 "서비스"라 함)에 대한 귀하의 접속 및 이용을 규제합니다. 서비스에 접속하거나 이용함으로써 귀하는 이러한 약관과 개인 정보 보호 정책에 동의하는 것으로 간주됩니다.',
    account_registration_and_security: "계정 등록 및 보안",
    provide_accuate_information:
      "귀하는 계정을 생성하기 위해 진실하고 정확하며 완전한 정보를 제공해야 합니다. 그리고 정기적으로 계정 정보를 업데이트해야 합니다.",
    responsibility_of_account:
      "귀하는 귀하의 계정 하에서 발생하는 모든 활동에 대해 책임을 집니다.",
    right_of_refusal:
      "특히 이러한 약관을 위반한 경우, Syngents.ai는 서비스 제공을 거부하거나 계정을 종료하거나 콘텐츠를 삭제할 수 있습니다.",
    user_content: "사용자 콘텐츠",
    retain_ownership:
      "Syngents.ai를 통해 제출한 콘텐츠에 대한 소유권은 귀하에게 있지만, Syngents.ai에게는 비독점적이고 로열티 없는, 전 세계적이고 영구적이며 완전하게 하위 라이선스가 부여됩니다.",
    declare_and_guarantee:
      "귀하는 제출한 콘텐츠에 대한 모든 권리를 소유하거나 필요한 허가를 얻었음을 선언하고 보증합니다.",
    user_behavior: "사용자 행동",
    agreement_of_behavior:
      "귀하는 다음과 같은 행위를 위해 서비스를 사용하지 않겠다는 데 동의합니다:",
    prohibited_behaviors_string: `{
  "A": "명예를 훼손하거나 외설, 음란, 음란, 무례하거나 인종 차별적인 콘텐츠를 게시하거나 배포하지 않습니다.",
  "B": "지적 재산권, 영업 비밀, 개인 정보 보호 권 또는 기타 소유권을 침해하지 않습니다.",
  "C": "바이러스, 트로이 목마, 스파이웨어 또는 기타 파괴적인 코드를 포함하는 콘텐츠를 제출하지 않습니다.",
  "D": "승인되지 않은 광고, 스팸, 연쇄 메일, 기타 형태의 승인되지 않은 권유 또는 복권 또는 도박의 어떤 형태도 게시하지 않습니다.",
  "E": "허위 또는 오해를 불러일으키는 정보를 제공하거나 어떠한 개인 또는 엔터티를 가장하지 않습니다.",
  "F": "저작권 법, 상표 법, 개인 정보 보호 법, 스팸 방지 법을 포함하여 적용 가능한 법률이나 규정을 위반하지 않습니다.",
  "G": "불법, 유해 또는 폭력적인 활동을 선전하지 않습니다.",
  "H": "다른 사용자를 괴롭히거나 위협하거나 괴롭히거나 속이거나 기타 방법으로 방해하지 않습니다.",
  "I": "서비스의 절차, 정책 또는 규정을 위반하지 않습니다.",
  "J": "서비스 또는 관련 네트워크의 정상적인 운영을 방해하거나 방해하지 않습니다.",
  "K": "승인되지 않은 서비스, 다른 계정 또는 연결된 컴퓨터 시스템 또는 네트워크에 액세스하지 않습니다.",
  "L": "로봇, 스파이더, 스크래핑 도구 또는 기타 자동화된 방법을 사용하여 서비스에 액세스하지 않습니다.",
  "M": "소스 코드를 가져 오려고 시도하거나 서비스의 소스 코드를 역컴파일, 역어셈블 또는 기타 방법으로 획득하지 않습니다.",
  "N": "서비스의 무결성을 변경, 적응, 해킹 또는 기타 방법으로 저해하지 않습니다.",
  "O": "Syngents.ai의 명시적 서면 허가 없이 서비스의 어떠한 부분도 복사, 복제, 판매, 재판매 또는 이용하지 않습니다."
}`,
    prohibited_behaviors: {
      A: "명예를 훼손하거나 외설, 음란, 음란, 무례하거나 인종 차별적인 콘텐츠를 게시하거나 배포하지 않습니다.",
      B: "지적 재산권, 영업 비밀, 개인 정보 보호 권 또는 기타 소유권을 침해하지 않습니다.",
      C: "바이러스, 트로이 목마, 스파이웨어 또는 기타 파괴적인 코드를 포함하는 콘텐츠를 제출하지 않습니다.",
      D: "승인되지 않은 광고, 스팸, 연쇄 메일, 기타 형태의 승인되지 않은 권유 또는 복권 또는 도박의 어떤 형태도 게시하지 않습니다.",
      E: "허위 또는 오해를 불러일으키는 정보를 제공하거나 어떠한 개인 또는 엔터티를 가장하지 않습니다.",
      F: "저작권 법, 상표 법, 개인 정보 보호 법, 스팸 방지 법을 포함하여 적용 가능한 법률이나 규정을 위반하지 않습니다.",
      G: "불법, 유해 또는 폭력적인 활동을 선전하지 않습니다.",
      H: "다른 사용자를 괴롭히거나 위협하거나 괴롭히거나 속이거나 기타 방법으로 방해하지 않습니다.",
      I: "서비스의 절차, 정책 또는 규정을 위반하지 않습니다.",
      J: "서비스 또는 관련 네트워크의 정상적인 운영을 방해하거나 방해하지 않습니다.",
      K: "승인되지 않은 서비스, 다른 계정 또는 연결된 컴퓨터 시스템 또는 네트워크에 액세스하지 않습니다.",
      L: "로봇, 스파이더, 스크래핑 도구 또는 기타 자동화된 방법을 사용하여 서비스에 액세스하지 않습니다.",
      M: "소스 코드를 가져 오려고 시도하거나 서비스의 소스 코드를 역컴파일, 역어셈블 또는 기타 방법으로 획득하지 않습니다.",
      N: "서비스의 무결성을 변경, 적응, 해킹 또는 기타 방법으로 저해하지 않습니다.",
      O: "Syngents.ai의 명시적 서면 허가 없이 서비스의 어떠한 부분도 복사, 복제, 판매, 재판매 또는 이용하지 않습니다.",
    },
    intellectual_property: "지적 재산",
    protection_of_intellectual_property:
      "Syngents.ai의 콘텐츠, 소프트웨어 및 상표는 법적으로 보호되며, 허가없이 사용할 수 없습니다.",
    acknowledge_and_agree:
      "Syngents.ai 또는 제3자의 지적 재산권을 침해하지 않겠다고 인정하고 동의합니다.",
    disclaimer_and_limitation_of_liability: "면책 성명 및 책임 제한",
    service_provided_as_is:
      '서비스는 "있는 그대로" 제공되며, Syngents.ai는 서비스의 중단, 오류 또는 사용자 데이터 손실에 대한 책임을 지지 않습니다.',
    no_liability_for_damage:
      "어떤 경우에도 Syngents.ai는 이익, 선량, 사용 손실, 데이터 손실 또는 기타 무형적 손실을 포함한 직접, 간접, 특수, 결과적 또는 처벌적 손해에 대해 책임지지 않습니다.",
    changes_to_terms: "약관 변경",
    right_to_modify_terms:
      "Syngents.ai는 언제든지 이 약관을 수정할 수 있으며 웹 사이트나 이메일을 통해 귀하는 통지를 받게 될 것입니다.",
    acceptance_of_new_terms:
      "서비스의 계속적인 사용은 새로운 약관을 수락한 것으로 간주됩니다. 새로운 약관에 동의하지 않으면 서비스 사용을 중단해야 합니다.",
    applicable_law_and_dispute_resolution: "적용 법률 및 분쟁 해결",
    governing_law:
      "이 약관은 Syngents.ai가 운영하는 관할 지역의 법률에 따릅니다.",
    dispute_resolution:
      "분쟁은 먼저 협상을 통해 해결되어야 하며 해결되지 않으면 구속력 있는 중재에 제출해야 합니다.",
    miscellaneous: "기타",
    invalid_terms:
      "이 약관의 일부가 집행할 수 없거나 무효로 간주되는 경우, 그 일부는 최소한으로 제한되거나 제거됩니다.",
    no_assignment:
      "귀하는 이 약관이나 이 약관에 따라 부여된 권리와 권한을 양도하거나 이전할 수 없습니다.",
    contact_information: "연락처 정보",
    contact_us:
      "이 약관에 관한 질문이나 우려 사항이 있으시면 <0>{{email}}</0>로 문의하십시오.",
  },
  workflow: {
    fileName: "파일 이름",
  },
  status: "상태",
  size: "크기",
  action: "작업",
};
