export default {
  "common.welcome": "Selamat datang di AI Agent",
  "common.password": "Kata sandi:",
  "common.password.confirm": "Konfirmasi kata sandi:",
  "common.userName": "Nama pengguna:",
  "common.login": "Masuk",
  "common.login.success": "Masuk berhasil",
  "common.register": "Daftar",
  "common.register.success": "Daftar berhasil",
  "common.register.title": "Daftar akun",
  "common.product.name": "Agen Cerdas",
  "common.user.placeHolder": "Masukkan nama pengguna!",
  "common.password.placeHolder": "Masukkan kata sandi!",
  "common.seeking": "Eksplorasi",
  "common.chat": "Obrolan",
  "common.canvas": "Kanvas",
  "common.community": "Komunitas",
  "common.create": "Pembuatan Agen",
  "common.myAgent": "Agen saya",
  "common.publish": "Publikasi Agen",
  "common.submit": "Kirim",
  "common.send": "Kirim",
  "common.tags": "Tag",
  "common.publish.time": "Waktu publikasi:",
  "common.update.time": "Pembaruan terakhir:",
  "common.join.chat": "Gabung obrolan",
  "common.create.same": "Buat yang serupa",
  "common.agent.singal": "Agen Tunggal",
  "common.back": "Kembali",
  "common.chat.default.greetings": "Bisa saya bantu?",
  "common.you": "Anda",
  "common.edit": "Edit",
  "common.category": "Kategori",
  "common.yes": "Ya",
  "common.no": "Tidak",
  "common.category.recent": "Terbaru",
  "common.category.work": "Kerja",
  "common.category.learning": "Belajar",
  "common.category.lifestyle": "Gaya hidup",

  "create.dialog.agent.desc": "Mulai buat Agen Anda",
  "create.normalmode": "Mode Normal",
  "create.professionalmode": "Mode Profesional",
  "create.name": "Nama Agen Cerdas",
  "create.name.placeholder": "Masukkan nama Agen",
  "create.introduce": "Perkenalan Agen",
  "create.introduce.placeholder": "Masukkan perkenalan Agen",
  "create.provider": "Penyedia model",
  "create.provider.placeholder": "Pilih penyedia model",
  "create.clue.edit": "Edit Petunjuk",

  "create.clue.placeholder": "Pilih jenis edit petunjuk",
  "create.clue.sender": "Pengirim",
  "create.humanInput.placeholder": "Pilih format pengirim",
  "create.clue.receiver": "Penerima",
  "create.agent.type": "Jenis Agen",
  "create.repository.upload": "Unggah Repositori",
  "create.repository.greetings": "Salam",
  "create.agent.tools": "Alat Agen",
  "create.reply.count": "Jumlah balasan otomatis:",
  "create.input.format": "Format masukan",
  "create.input.prompts": "Petunjuk masukan sistem",
  "create.emotional.temperature": "Suhu emosional:",
  "create.image.create": "Pembuatan Gambar",
  "create.info.search": "Pencarian informasi",
  "create.image.desc": "Deskripsi menghasilkan gambar",
  "create.preview.title": "Debug dan Pratinjau",
  "create.singal.agent": "Agen Tunggal",
  "create.muti.agent": "Agen Ganda",
  "create.to.detail": "Lihat Agen saya",
  "create.conversation": "Kategori Obrolan",
  "create.image.cover": "Gambar latar",
  "create.upload": "Unggah file",
  "create.greeting.placeholder": "Masukkan salam",
  "create.upload.tips": "Dukungan berbagai format file",
  "create.last.updatetime": "Terakhir disimpan:",
  "create.category.placeholder": "Pilih kategori",

  "publish.setting.title": "Pengaturan Publikasi",
  "publish.market.title": "Publikasi ke Pasar",
  "publish.market.desc": "Dapat ditemukan oleh pengguna lain",
  "publish.api.title": "Buat API",
  "publish.api.desc": "Untuk pengembangan lebih lanjut",
  "publish.link.title": "Buat Tautan Publikasi",
  "publish.link.desc": "Berinteraksi dengan Agen melalui web",
  "publish.third.title": "Hubungkan ke Aplikasi Pihak Ketiga",
  "publish.third.desc": "Koneksi dengan aplikasi lain",
  "publish.dialog.title": "Pengaturan Publikasi",
  "publish.dialog.style": "Pilih Gaya Publikasi",
  "publish.dialog.stylePlaceHolder": "Pilih gaya publikasi",
  "publish.dialog.permission": "Pengaturan Izin Publikasi",
  "publish.dialog.toPlace": "Publikasikan ke Pusat Agen",
  "publish.dialog.toDashBoard": "Hanya ke dashboard pribadi",
  "publish.dialog.edit.permission": "Pengaturan Izin Edit",
  "publish.dialog.viewOnly": "Hanya bisa dilihat",
  "publish.dialog.editView": "Dapat diedit dan dilihat",
  "publish.dialog.Agreement": "Klik untuk baca Perjanjian Pembuatan Agen",
  "publish.copy.success": "Berhasil disalin",
  "publish.recommand.tags": "Tag yang direkomendasikan",
  "publish.question.example": "Pertanyaan contoh",
  "publish.editable": "Bisa diedit",
  "publish.tags.placeholder": "Pilih tag",
  "publish.btntext": "Publikasi ke Pasar",
  "publish.agreement": "Publikasi berarti setuju untuk mematuhi",
  "publish.policy": "Kebijakan Konten Sygents.ai",
  "publish.last.time": "Terakhir dipublikasikan:",

  "chat.withModal": "Obrolan dengan Model Besar",
  "chat.withAgent": "Daftar Agen",
  "chat.input.placeholder": "Apa yang ingin Anda ketahui",
  chat: {
    withModal: "Bicara dengan Model Besar",
    withAgent: "Daftar Agen Pintar",
    input: {
      placeholder: "Masukkan konten yang ingin Anda ketahui",
    },
    delete: {
      title: 'Hapus Percakapan?',
      content: 'Apakah Anda yakin ingin menghapus "<0>{{name}}</0>"?'
    }
},

  "community.explore": "Jelajahi",
  "community.frontier": "Frontier AI",
  "community.cameby": "Dikunjungi oleh",
  "community.member": "Anggota",
  "community.online": "Online",
  "community.watched": "Ditonton oleh",
  "community.share": "Dibagikan oleh",

  myagent: {
    app: "Aplikasi",
    repository: "Repository",
    tools: "Alat",
    newagent: "Buat Agen Baru",
    subtotal: {
      left: "Total Agen:",
      right: "Agen",
    },
    delete: {
      title: "Hapus Agen",
      content: 'Apakah Anda yakin ingin menghapus agen ini? "<0>{{name}}</0>"',
      success: "Berhasil Dihapus",
      successText:
        '"<0>{{name}}</0>" telah berhasil dihapus dari daftar Agen Saya!',
      fail: "Gagal menghapus",
      failText:
        "Gagal menghapus. Silakan coba lagi nanti atau hubungi administrator.",
    },
  },

  "explore.tab.tool": "Alat Multimodal",
  "explore.tab.analysis": "Analisis Penelitian",
  "explore.tab.writing": "Penulisan Profesional",
  "explore.tab.coding": "Kode",
  "explore.tab.prompt": "Petunjuk Kata",
  "explore.tab.assistant": "Asisten Kecil",
  "explore.tab.entertainment": "Hiburan dan Santai",
  privacy_policy: {
    title: "Ketentuan Layanan Syngents.ai",
    introduction: "Pengantar",
    welcome_message:
      'Selamat datang di platform Syngents.ai (selanjutnya disebut sebagai "Syngents.ai" atau "kami"). Ketentuan Layanan ini ("Ketentuan") mengatur akses dan penggunaan Anda terhadap situs web, aplikasi seluler, dan produk dan layanan terkait lainnya yang disediakan oleh Syngents.ai (secara kolektif disebut sebagai "Layanan"). Dengan mengakses atau menggunakan Layanan kami, Anda menyetujui untuk terikat oleh Ketentuan ini dan Kebijakan Privasi kami.',
    account_registration_and_security: "Pendaftaran Akun dan Keamanan",
    provide_accuate_information:
      "Anda harus memberikan informasi yang benar, akurat, dan lengkap untuk membuat akun dan secara berkala memperbarui informasi akun Anda.",
    responsibility_of_account:
      "Anda bertanggung jawab atas semua aktivitas yang terjadi di bawah akun Anda.",
    right_of_refusal:
      "Syngents.ai berhak untuk menolak layanan, mengakhiri akun, atau menghapus konten, terutama dalam kasus pelanggaran atas Ketentuan ini.",
    user_content: "Konten Pengguna",
    retain_ownership:
      "Anda tetap memiliki kepemilikan atas konten yang Anda kirimkan melalui Syngents.ai, namun Anda memberikan kepada Syngents.ai hak non-eksklusif, bebas royalti, global, abadi, dan sepenuhnya dapat disublisensikan.",
    declare_and_guarantee:
      "Anda menyatakan dan menjamin bahwa Anda memiliki semua hak atas konten yang dikirimkan atau telah memperoleh izin yang diperlukan.",
    user_behavior: "Perilaku Pengguna",
    agreement_of_behavior:
      "Anda setuju untuk tidak menggunakan Layanan untuk tindakan berikut:",
    prohibited_behaviors_string: `{
  "A": "Posting atau menyebarkan konten yang memfitnah, cabul, pornografi, vulgar, ofensif, atau diskriminatif rasial.",
  "B": "Melanggar hak kekayaan intelektual, rahasia dagang, hak privasi, atau hak kekayaan lainnya.",
  "C": "Mengirimkan konten yang mengandung virus, trojan, perangkat lunak mata-mata, atau kode destruktif lainnya.",
  "D": "Posting iklan tidak sah, spam, rantai surat, bentuk lainnya dari permintaan yang tidak sah, atau bentuk perjudian atau lotre lainnya.",
  "E": "Memberikan informasi yang salah atau menyesatkan, atau menyamar sebagai orang atau entitas tertentu.",
  "F": "Melanggar hukum atau peraturan yang berlaku, termasuk namun tidak terbatas pada hukum hak cipta, hukum merek dagang, hukum privasi, dan hukum anti-spam.",
  "G": "Mempromosikan aktivitas ilegal, berbahaya, atau kekerasan.",
  "H": "Mengganggu, mengancam, mengintimidasi, menipu, atau mengganggu pengguna lain dengan cara apa pun.",
  "I": "Melanggar prosedur, kebijakan, atau peraturan Layanan.",
  "J": "Mengganggu atau mengganggu operasi normal Layanan atau jaringan terkait.",
  "K": "Mengakses Layanan, akun lain, atau sistem komputer atau jaringan terhubung tanpa izin.",
  "L": "Menggunakan robot, laba-laba, alat pengikis, atau metode otomatis lainnya untuk mengakses Layanan.",
  "M": "Membalikkan rekayasa, mendekompilasi, merakit ulang, atau mencoba mendapatkan kode sumber Layanan dengan cara lain.",
  "N": "Mengubah, menyesuaikan, membobol, atau mengompromikan integritas Layanan dengan cara lain.",
  "O": "Menyalin, mereplikasi, menjual, menjual kembali, atau mengeksploitasi bagian apa pun dari Layanan tanpa izin tertulis eksplisit dari Syngents.ai."
}`,
    prohibited_behaviors: {
      A: "Posting atau menyebarkan konten yang memfitnah, cabul, pornografi, vulgar, ofensif, atau diskriminatif rasial.",
      B: "Melanggar hak kekayaan intelektual, rahasia dagang, hak privasi, atau hak kekayaan lainnya.",
      C: "Mengirimkan konten yang mengandung virus, trojan, perangkat lunak mata-mata, atau kode destruktif lainnya.",
      D: "Posting iklan tidak sah, spam, rantai surat, bentuk lainnya dari permintaan yang tidak sah, atau bentuk perjudian atau lotre lainnya.",
      E: "Memberikan informasi yang salah atau menyesatkan, atau menyamar sebagai orang atau entitas tertentu.",
      F: "Melanggar hukum atau peraturan yang berlaku, termasuk namun tidak terbatas pada hukum hak cipta, hukum merek dagang, hukum privasi, dan hukum anti-spam.",
      G: "Mempromosikan aktivitas ilegal, berbahaya, atau kekerasan.",
      H: "Mengganggu, mengancam, mengintimidasi, menipu, atau mengganggu pengguna lain dengan cara apa pun.",
      I: "Melanggar prosedur, kebijakan, atau peraturan Layanan.",
      J: "Mengganggu atau mengganggu operasi normal Layanan atau jaringan terkait.",
      K: "Mengakses Layanan, akun lain, atau sistem komputer atau jaringan terhubung tanpa izin.",
      L: "Menggunakan robot, laba-laba, alat pengikis, atau metode otomatis lainnya untuk mengakses Layanan.",
      M: "Membalikkan rekayasa, mendekompilasi, merakit ulang, atau mencoba mendapatkan kode sumber Layanan dengan cara lain.",
      N: "Mengubah, menyesuaikan, membobol, atau mengompromikan integritas Layanan dengan cara lain.",
      O: "Menyalin, mereplikasi, menjual, menjual kembali, atau mengeksploitasi bagian apa pun dari Layanan tanpa izin tertulis eksplisit dari Syngents.ai.",
    },
    intellectual_property: "Kekayaan Intelektual",
    protection_of_intellectual_property:
      "Konten, perangkat lunak, dan merek dagang Syngents.ai dilindungi oleh hukum dan tidak boleh digunakan tanpa izin.",
    acknowledge_and_agree:
      "Anda mengakui dan menyetujui untuk tidak melanggar hak kekayaan intelektual Syngents.ai atau pihak ketiga mana pun.",
    disclaimer_and_limitation_of_liability:
      "Penafian Garansi dan Batasan Tanggung Jawab",
    service_provided_as_is:
      'Layanan disediakan "apa adanya" dan Syngents.ai tidak bertanggung jawab atas gangguan, kesalahan, atau kehilangan data pengguna.',
    no_liability_for_damage:
      "Dalam keadaan apa pun, Syngents.ai tidak akan bertanggung jawab atas kerugian langsung, tidak langsung, khusus, konsekuensial, atau hukuman, termasuk namun tidak terbatas pada keuntungan, reputasi, kehilangan penggunaan, kehilangan data, atau kerugian tak berwujud lainnya.",
    changes_to_terms: "Perubahan Ketentuan",
    right_to_modify_terms:
      "Syngents.ai dapat mengubah Ketentuan ini kapan saja dan akan memberi tahu Anda melalui situs web atau email.",
    acceptance_of_new_terms:
      "Penggunaan berkelanjutan atas Layanan menunjukkan penerimaan Anda terhadap Ketentuan baru. Jika Anda tidak setuju dengan Ketentuan baru, Anda harus menghentikan penggunaan Layanan.",
    applicable_law_and_dispute_resolution:
      "Hukum yang Berlaku dan Penyelesaian Sengketa",
    governing_law:
      "Ketentuan ini tunduk pada hukum yurisdiksi di mana Syngents.ai beroperasi.",
    dispute_resolution:
      "Setiap perselisihan harus terlebih dahulu diselesaikan melalui negosiasi, dan jika tidak terselesaikan, harus diserahkan ke arbitrase yang mengikat.",
    miscellaneous: "Lainnya",
    invalid_terms:
      "Jika bagian dari Ketentuan ini tidak dapat dilaksanakan atau tidak sah, bagian tersebut akan dibatasi atau dihilangkan sejauh minimal yang diperlukan.",
    no_assignment:
      "Anda tidak dapat mengalihkan atau mentransfer Ketentuan ini atau hak dan izin yang diberikan dalam Ketentuan ini.",
    contact_information: "Informasi Kontak",
    contact_us:
      "Jika Anda memiliki pertanyaan atau kekhawatiran mengenai Ketentuan ini, silakan hubungi kami di <0>{{email}}</0>",
  },
  workflow: {
    fileName: "Nama File",
  },
  status: "Status",
  size: "Ukuran",
  action: "Tindakan",
};
