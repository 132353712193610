import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import style from "./Content.module.scss";
import { useTranslation } from "react-i18next";
import AnswerIconDark from "./answer-dark.svg";
import AnswerIconLight from "./answer-light.svg";
import SourceIconDark from "./source-dark.svg";
import SourceIconLight from "./source-light.svg";
import RelatedIconDark from "./relate-dark.svg";
import RelatedIconLight from "./relate-light.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import { message as AntdMessage } from "antd";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyIcon from "./copy";
import TryAgainIcon from "./tryAgain";
import { isString } from "lodash";
import classNames from "classnames";

const Content = ({ message, onRelatedClick, curWidth, tryAgain }: any) => {
  const theme = useThemeHooks();
  const { content_type = "text", content } = message as any;
  const { t } = useTranslation();
  const [isTryIconHover, setIsTryIconHover] = React.useState(false);
  const [isCopyIconHover, setIsCopyIconHover] = React.useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const div = divRef.current as any;
    if (div && div?.querySelectorAll) {
      const anchorTags = div?.querySelectorAll("a");
      anchorTags.forEach((anchor: any) => {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      });
    }
  }, [content, message]);

  const searchType = () => (
    <>
      <div className={style.sectionWrap}>
        <div className={style.title}>
          <img
            src={theme === "dark" ? AnswerIconDark : AnswerIconLight}
            alt=''
            className={style.iconWrap}
          />
          {t("chat.answer")}
        </div>
        <div className={style.answerContent} ref={divRef}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[gfm]}>
            {content?.answer}
          </ReactMarkdown>
        </div>
        <div className={style.iconTab}>
          <CopyToClipboard
            text={
              content_type === "search" ? content?.answer : content || message
            }
            onCopy={(text, result) => {
              result && AntdMessage.success(t("chat.copySuccess"));
            }}
          >
            <div
              className={`${style.iconContent} ${
                isCopyIconHover ? style.iconHover : ""
              }`}
              onMouseEnter={() => setIsCopyIconHover(true)}
              onMouseLeave={() => setIsCopyIconHover(false)}
            >
              <div className={style.iconContentWrap}>
                <CopyIcon isCopyIconHover={isCopyIconHover} />
                {isCopyIconHover ? (
                  <div className={style.showWrap}>{t("chat.copy")}</div>
                ) : null}
              </div>
            </div>
          </CopyToClipboard>

          <div
            className={style.iconContent}
            onMouseEnter={() => setIsTryIconHover(true)}
            onMouseLeave={() => setIsTryIconHover(false)}
            onClick={() => tryAgain()}
          >
            <div className={style.iconContentWrap}>
              <TryAgainIcon isTryIconHover={isTryIconHover} />
              {isTryIconHover ? (
                <div className={style.showWrap}>{t("chat.tryAgain")}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {content?.source && content?.source?.length ? (
        <div className={style.sectionWrap}>
          <div className={style.title}>
            <img
              src={theme === "dark" ? SourceIconDark : SourceIconLight}
              alt=''
              className={style.iconWrap}
            />
            {t("chat.source")}
          </div>
          <div
            className={
              curWidth > 600 ? style.customGrid : style.customSmallGrid
            }
          >
            {(content?.source || [])?.map((item: any, index: number) => {
              return (
                <a
                  href={item.url}
                  target='_blank'
                  key={index}
                  className={style.source}
                  rel='noreferrer'
                >
                  <div
                    className={
                      item.type === "knowledge_base"
                        ? style.sourceLocalContentWrap
                        : theme === "dark"
                        ? style.sourceContentWrapDark
                        : style.sourceContentWrap
                    }
                  >
                    <div className={style.sourceTitle}>
                      {item.title}
                      {item.snippet && `: ${item.snippet}`}
                    </div>
                    <div className={style.sourceUrl}>
                      {item.type === "knowledge_base"
                        ? t("chat.localKnowledge")
                        : String(new URL(item.url)?.hostname)}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      ) : null}
      {content?.related && content?.related?.length ? (
        <div>
          <div className={style.title}>
            <img
              src={theme === "dark" ? RelatedIconDark : RelatedIconLight}
              alt=''
              className={style.iconWrap}
            />
            {t("chat.related")}
          </div>
          {content?.related?.map((item: any, index: number) => (
            <div
              key={index}
              className={style.relatedInfo}
              onClick={() => onRelatedClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );

  // 做一个兼容
  const compatibleContent = isString(content) ? content : "服务器开小差了~";

  return (
    <div
      className={style.page}
      style={
        content_type === "search"
          ? { padding: "24px 0 16px" }
          : { padding: "16px 0" }
      }
    >
      {content_type === "search" ? (
        searchType()
      ) : (
        <div className={style.answerContent} ref={divRef}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[gfm]}>
            {compatibleContent || message}
          </ReactMarkdown>
          <div className={style.iconTab}>
            <CopyToClipboard
              text={
                content_type === "search" ? content?.answer : content || message
              }
              onCopy={(text, result) => {
                result && AntdMessage.success(t("chat.copySuccess"));
              }}
            >
              <div
                className={`${style.iconContent} ${
                  isCopyIconHover ? style.iconHover : ""
                }`}
                onMouseEnter={() => setIsCopyIconHover(true)}
                onMouseLeave={() => setIsCopyIconHover(false)}
              >
                <div className={style.iconContentWrap}>
                  <i
                    className={classNames('iconfont', 'icon-copy_line')}
                    style={{
                      color:
                        theme === "dark"
                          ? isCopyIconHover
                            ? "#e8e8e6"
                            : "#a7a7a7"
                          : isCopyIconHover
                          ? "#272B30"
                          : "#80868d",
                    }}
                  />
                  {isCopyIconHover ? (
                    <div className={style.showWrap}>{t("chat.copy")}</div>
                  ) : null}
                </div>
              </div>
            </CopyToClipboard>

            <div
              className={style.iconContent}
              onMouseEnter={() => setIsTryIconHover(true)}
              onMouseLeave={() => setIsTryIconHover(false)}
            >
              <div className={style.iconContentWrap} onClick={() => tryAgain()}>
                <i
                  className={classNames('icon-again_line', "iconfont")}
                  style={{
                    color:
                      theme === "dark"
                        ? isTryIconHover
                          ? "#e8e8e6"
                          : "#a7a7a7"
                        : isTryIconHover
                        ? "#272B30"
                        : "#80868d",
                  }}
                />
                {isTryIconHover ? (
                  <div className={style.showWrap}>{t("chat.tryAgain")}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Content;
