import { useSelector } from 'react-redux';

const useGlobalUserData = () => {
  const globalUserData = useSelector((state: any) => state.globalUserData);
  // zhiji币数量
  const coin = globalUserData.coin_count;
  // 用户的套餐
  const plan = globalUserData.subscription_plan_type;
  return {...globalUserData, coin, plan};
};

export default useGlobalUserData;