import { configureStore } from "@reduxjs/toolkit";
import userInfo from "./reducer/userInfo/index";
import loginConfig, {
  openModal,
  closeModal,
} from "./reducer/loginConfig/index";
import config from "./reducer/config/index";
import knowledgeFiles from "./reducer/knowledgeFiles";
import globalUserData from './reducer/userConfig';
import coinRecharge from "./reducer/coinRecharge";

export default configureStore({
  reducer: {
    userInfo,
    loginConfig,
    config,
    knowledgeFiles,
    globalUserData,
    coinRecharge
  },
});

export const loginOption = { openModal, closeModal };
