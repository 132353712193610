import instance from "../request/interceptor";
import blogInstance from "../request/blogInterceptor";
import {
  Ilogin,
  IRegister,
  IAgentInfo,
  Ipage,
  IMsg,
  IPublishInfo,
  // IUserInfo,
  ITagData,
  ICreateModalChat,
  ICreateMyAgentChat,
  ICreatePubAgentChat,
  ITopicHistory,
  ITabModalList,
  ITabAgentList,
  ITabPubAgentList,
  ISendLLm,
  IUserIdList,
  ISeeking,
  Ilike,
  Ilikes,
  IlikeStatus,
  IquestionList,
} from "@/types/common";

// 登录
export const toLogin = (data: Ilogin) => {
  return instance.post("/users/login/", data);
};

// 注册
export const toRegister = (data: IRegister) => {
  return instance.post("/users/create/", data);
};

// userInfo
export const toUsersIslogin = () => {
  return instance.post("/users/info/");
};

// 创建Agent
export const toCreateAgent = (data: IAgentInfo) => {
  return instance.post("/aichat/agent/edit/add/", data);
};

// 更新Agent信息
export const toUpdateAgent = (data: IAgentInfo) => {
  return instance.post("/aichat/agent/edit/update/", data);
};

// 获取私有agent 信息
export const toGetAgentInfo = (data: { id?: number }) => {
  return instance.post("/aichat/agent/edit/get/", data);
};

// 获取公有agent 信息
export const toGetAgentPublicInfo = (data: { id?: number }) => {
  return instance.post("/aichat/pub_agent/get/", data);
};

// 获取我的agent 列表
export const toGetAgentList = (data: Ipage) => {
  return instance.post("/aichat/agent/edit/list/", data);
};

// 喜欢 我喜欢的公共Agent 列表
export const toGetLikeAgentList = (data: Ipage) => {
  return instance.post("/aichat/like/pub_agent/list/", data);
};

// agent 预览聊天
export const previewSendMsg = (data: IMsg) => {
  return instance.post("/aichat/agent/edit/chat/", data);
};

// agent 发布
export const toPublish = (data: IPublishInfo) => {
  return instance.post("/aichat/agent/edit/publish/", data);
};

// agent 删除
export const toDeleteAgent = (data: { id?: number }) => {
  return instance.post("/aichat/agent/edit/delete/", data);
};

// agent 回退
export const toRollbackAgent = (data: { agent_id?: number }) => {
  return instance.post("/aichat/agent/edit/rollback/", data);
};

// agent 分类下拉列表
export const getAgentCategory = (data: { show_mode?: number }) => {
  return instance.post("/aichat/category/list/", data);
};

// 社区列表
export const getSpaceList = (data: Ipage) => {
  return instance.post("/aichat/community/list/", data);
};

// 实战教程列表
export const getCombatList = (data: Ipage) => {
  return instance.post("/aichat/cases/list/", data);
};

// 获取实战教程课程本体
export const getActualCombat = (data: { id: number }) => {
  return instance.post("/aichat/cases/get/", data);
};

// 获取实战教程用户历史回答
export const getUserAnswer = (data: { id: string }) => {
  return instance.post("/aichat/cases/answer/get/", data);
};

// 保存用户已回答内容
export const getSaveAnswer = (data: { id: string; user_answer: object }) => {
  return instance.post("/aichat/cases/answer/save/", data);
};

// 前沿信息列表
export const getArticleList = (data: Ipage) => {
  return instance.post("/aichat/article/list/", data);
};

// 探索
export const toGetSeekingList = (data: ISeeking) => {
  return instance.post("/aichat/pub_agent/list/", data);
};

// tag字典
export const toGetTagData = (data: ITagData) => {
  return instance.post("/aichat/tag/dict/", data);
};

// 我对话过的Agent列表
export const toGetTalkedPubAgentList = (data: Ipage) => {
  return instance.post("/aichat/pub_agent/relation/list/", data);
};

// 删除我对话过的Agent列表
export const toDeleteTalkedPubAgent = (data: { pub_agent_id?: number }) => {
  return instance.post("/aichat/pub_agent/relation/delete/", data);
};

// 大模型对话列表
export const toGetModalList = (data: ITabModalList) => {
  return instance.post("/aichat/llm/topic/list/", data);
};

// 我的Agent对话列表
export const toGetMyAgentList = (data: ITabAgentList) => {
  return instance.post("/aichat/agent/topic/list/", data);
};

// 公共Agent对话列表
export const toGetPubAgentList = (data: ITabPubAgentList) => {
  return instance.post("/aichat/pub_agent/topic/list/", data);
};

// 创建大模型对话
export const toCreateModal = (data: ICreateModalChat) => {
  return instance.post("/aichat/llm/topic/add/", data);
};

// 创建我的Agent对话
export const toCreateMyAgent = (data: ICreateMyAgentChat) => {
  return instance.post("/aichat/agent/topic/add/", data);
};

// 创建公共Agent对话
export const toCreatePubAgent = (data: ICreatePubAgentChat) => {
  return instance.post("/aichat/pub_agent/topic/add/", data);
};

// 大模型对话历史
export const toGetModalHistory = (data: ITopicHistory) => {
  return instance.post("/aichat/llm/message/list/", data);
};

// Agent对话历史
export const toGetMyAgentHistory = (data: ITopicHistory) => {
  return instance.post("/aichat/agent/message/list/", data);
};

// 公有Agent对话历史
export const toGetPubAgentHistory = (data: ITopicHistory) => {
  return instance.post("/aichat/pub_agent/message/list/", data);
};

// 与大模型对话
export const toChatWithLLm = (data: ISendLLm) => {
  return instance.post("/aichat/llm/send/", data);
};

// 通过uid批量获取用户名
export const toGetUserNames = (data: IUserIdList) => {
  return instance.post("/users/dict/", data);
};

// 获取登陆验证码
export const toGetLoginCode = (data: {
  destination?: string;
  code_type: string;
}) => {
  return instance.post("/users/login/getcode/", data);
};

// 邮箱登录 校验code
export const toCheckLoginCode = (data: {
  code_type: string;
  code: string;
  destination: string;
}) => {
  return instance.post("/users/login/checkcode/", data);
};

// 三方登录回调接口
export const loginCallBack = (data: { login_type: string; token: string }) => {
  return instance.post("/users/third_login/login_call_back/", data);
};

// 点击喜欢
export const like = (data: Ilike) => {
  return instance.post("/aichat/like/pub_agent/add/", data);
};

// 取消喜欢
export const disLike = (data: Ilikes) => {
  return instance.post("/aichat/like/pub_agent/delete/", data);
};

// 获取对应agent_id 的喜欢人数列表
export const likeStatus = (data: IlikeStatus) => {
  return instance.post("/aichat/pub_agent/expend/dict/", data);
};

// 获取首页问题列表
export const mainQuestionList = (data: IquestionList) => {
  return instance.post("/aichat/common_problem/list/", data);
};

// 获取知识库列表
export const toGetKnowledgeList = (data: Ipage) => {
  return instance.post("/aichat/knowledge/list/", data);
};

// 知识库删除
export const deleteKnowledge = (data: { ids?: Array<number> }) => {
  return instance.post("/aichat/knowledge/delete/", data);
};

// 获取用户当前企业，也就是上次登陆企业
export const getUserCurrentCompany = () => {
  return instance.post("/users/company/current/");
};

// 公共配置接口
export const getConfig = (data: { keys: Array<string> }) => {
  return instance.post("/users/config/dict/", data);
};

// 知识库文件夹新增
export const createKnowledgeFolder = (data: {
  name: string;
  desc?: string;
}) => {
  return instance.post("/aichat/knowledge/folder/add/", data);
};

// 知识库文件夹列表
export const getKnowledgeFolderList = (data: Ipage) => {
  return instance.post("/aichat/knowledge/folder/list/", data);
};

// 知识库文件夹删除
export const deleteKnowledgeFolder = (data: { id: number }) => {
  return instance.post("/aichat/knowledge/folder/delete/", data);
};

// 知识库文件夹标签
export const getKnowledgeFolderTags = (data: { keys: string[] }) => {
  return instance.post("/users/config/json/", data);
};

// 知识库文件夹修改
export const updateKnowledgeFolder = (data: { id: number; name: string }) => {
  return instance.post("/aichat/knowledge/folder/update/", data);
};

// 知识库文件夹详情
export const getKnowledgeFolderDetail = (data: { id: number }) => {
  return instance.post("/aichat/knowledge/folder/get/", data);
};
// 获取分类文章列表
export const getCategoryArticleList = (data: any) => {
  return blogInstance.get("/api/public/article", {
    params: data,
  });
};

export const addKnowledgeContent = (data: {
  file_name: string;
  file: string;
  folder_id: number;
  file_size?: number;
  file_type?: string;
  file_source: number;
  file_category: number;
}) => {
  return instance.post("/aichat/knowledge/upload/", data);
};

// 获取文章详情
export const getCategoryArticle = (data: any) => {
  return blogInstance.get(`/api/public/article/${data.id}`, {});
};

// 获取tags
export const getTags = () => {
  return blogInstance.get("/api/public/tag", {});
};

export const getJsonConfig = (data: any) => {
  return instance.post("/users/config/json/", data);
};

// 获取配置
export const getAdvanceConfig = (data: any) => {
  return instance.post("/aichat/learning/config/get/", data);
};

// 保存配置
export const saveAdvanceConfig = (data: any) => {
  return instance.post("/aichat/learning/config/save/", data);
};

// 获取用户信息
export const getUserGuideInfo = () => {
  return instance.post("/users/get/");
};

// 用户完成新手教程
export const userGuideCompleted = () => {
  return instance.post("/users/beginner/tutorial/finish/");
};

// 获取免费的硬币
export const claimCoin = (data: any) => {
  return instance.post("/users/claim_coin/", data);
}

// 获取免费的硬币
export const getCanClaimCoinToday = () => {
  return instance.post("/users/can_claim_coin_today/");
}

// 获取商品列表
export const getProductList = () => {
  return instance.post("/aichat/product/list/");
}

// 创建订单列表
export const createOrder = (data: any) => {
  return instance.post("/aichat/order/create/", data);
}

// 获取订单状态
export const getOrderInfo = (data: any) => {
  return instance.post("aichat/order/info/", data);
}
// 修改用户头像，昵称
export const editUserInfoApi = (data: any) => {
  return instance.post("/users/update/", data);
};

// 修改用户头像，昵称
export const getWechatQRCodeApi = (data: any) => {
  return instance.post("/users/config/dict/", data);
};
