import { createSlice } from '@reduxjs/toolkit';

export interface Data {
  avatar: string;
  is_tutorial_completed: boolean;
  nick_name: string;
  subscription_expiry_date: string;
  subscription_plan: number;
  telephone: string;
  user_source: string;
  coin_count: number;
}

const initialState: Data = {
  avatar: '',
  is_tutorial_completed: false,
  nick_name: '',
  subscription_expiry_date: '',
  subscription_plan: 0,
  telephone: '',
  user_source: '',
  coin_count: 0,
};

const userDataSlice = createSlice({
  name: 'globalUserData',
  initialState,
  reducers: {
    setGlobalUserData: (state, action) => {
      return { ...action.payload };
    },
    changeGlobalUserData : (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
    setCoinNumber: (state, action) => {
      state.coin_count = action.payload;
    },
  },
});

// 导出action creator和reducer
export const { setGlobalUserData, setCoinNumber, changeGlobalUserData } = userDataSlice.actions;
export default userDataSlice.reducer;