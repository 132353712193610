import React, { useState, useReducer, useEffect } from "react";
import { Flex, Layout, Menu, theme, Tooltip } from "antd";
import { PlusCircleOutlined, FormOutlined } from "@ant-design/icons";
import { sidebar, userSiderBar } from "@/common/sidebar";
import styles from "./index.module.scss";
import { Select, message, QRCode } from "antd";
import { useTranslation } from "react-i18next";
import useLoginHooks from "@/hooks/useLoginHooks";
import CoinSvg from "@/assets/common/coin.svg";
import CreateAgent from "@/components/CreateAgent";
import classNames from "classnames";
import QuestionDark from "@/assets/community/questionDark.svg";
import QuestionLight from "@/assets/community/questionLight.svg";
import { Link, Navigate, useLocation } from "react-router-dom";
import unLogin from "@/assets/menu/unLogin.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import store, { loginOption } from "@/store";
import LogoutPic from "@/assets/common/logout.svg";
import LogoutLightPic from "@/assets/common/logout-light.svg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useGlobalUserData from "@/hooks/useUserConfigHook";
import { setCoinNumber } from "@/store/reducer/userConfig";
import FillSvg from "@/assets/common/add_circle_fill.svg";
import { openRecharge } from "@/store/reducer/coinRecharge";
import Odometer from "react-odometerjs";
// import "./odometer.scss";
import 'odometer/themes/odometer-theme-default.css';
import { createImageUrl } from "@/utils/common";
import {
  getWechatQRCodeApi
} from "@/apis/common";

const Avatar = createImageUrl('menu/avator.png');

const { Sider } = Layout;

const MenuBar = () => {
  const isMobile = window?.innerWidth <= 600;
  const [collapsed, setCollapsed] = useState(isMobile);

  const [QRCodeUrl, setQRCodeUrl] = useState("");

  const theme = useThemeHooks();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [, forceRerender] = useReducer((x) => x + 1, 0);
  let navigate = useNavigate();
  const { coin } = useGlobalUserData();

  function getTopLevelAndSecondLevelDomain(hostname: any) {
    const parts = hostname.split(".");
    if (parts.length > 2) {
      return parts.slice(-2).join(".");
    }
    return hostname;
  }
  const hostname = window.location.hostname;
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);


  //获取微信群聊二维码
  const handleAddWechat = async () => {
    try {
      const res = await getWechatQRCodeApi({
        keys: ["wechat_group_qr_url"]
      });
      if (res.data?.status === 200 && res.data?.data?.wechat_group_qr_url) {
        setQRCodeUrl(res.data.data.wechat_group_qr_url);
      }
    } catch (error) {
      message.error('获取微信二维码失败!')
    }
  }

  useEffect(() => {
    handleAddWechat();
  }, [])

  const { isLogin, username, isUserInfoLoading } = useLoginHooks();

  const location = useLocation();

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const logout = (type: string) => {
    Cookies.set("token", "", {
      domain: `${topLevelAndSecondLevelDomain}`,
    });
    localStorage.removeItem("token");
    window.location.reload();
  };

  const UserInfoComponent = () => {
    return (
      <div className={styles.user_info}>
        <img className={styles.avator} src={isLogin ? Avatar : unLogin}></img>
        {isLogin ? (
          <span className={styles.username}>
            <div
              className={styles.logout}
              onClick={() => {
                logout("logout");
              }}
            >
              <img src={theme === "dark" ? LogoutPic : LogoutLightPic}></img>
              {t("退出登录")}
            </div>
            {username}
          </span>
        ) : (
          <>
            {!isUserInfoLoading && (
              <span onClick={onLoginAndRegister}>
                <span className={styles.login}>{t("common.login")}</span>
              </span>
            )}
          </>
        )}
      </div>
    );
  };

  const CreateAgentClick = () => {
    setShow(true);
  };

  const CreateAgentClose = () => {
    setShow(false);
  };

  return (
    <Sider
      width={220}
      collapsedWidth={0}
      className={styles.sider}
      collapsible={isMobile}
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className={styles.top_box}>
        <div className={styles.sider_menu}>
          {sidebar.map((item, index) => {
            let isInPath;
            if (item.link === "/") {
              isInPath = location.pathname === "/";
            } else {
              isInPath = location.pathname.startsWith(item.link);
            }
            if (
              item.key === "seek" &&
              (location.pathname === "/" || location.pathname === "")
            ) {
              isInPath = true;
            }
            return (
              <Link to={item.link} key={index}>
                <div
                  className={classNames(
                    styles.sider_menu_item,
                    isInPath ? styles.sider_menu_item_active : ""
                  )}
                >
                  <img
                    src={
                      isInPath
                        ? theme === "dark"
                          ? item.activeIcon
                          : item.lightActiveIcon
                        : theme === "dark"
                        ? item.icon
                        : item.iconLight
                    }
                    className={styles.sider_menu_item_icon}
                  ></img>
                  <span>{item.label}</span>
                </div>
                <div
                  style={{
                    width: 0,
                    height: 0,
                    overflow: "hidden",
                    visibility: "hidden",
                    opacity: 0,
                  }}
                >
                  {item?.desc}
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      <div className={styles.bottom_box}>
        {
          <>
            {
              QRCodeUrl ? (
                <div className={classNames(styles.QRCodeContent, { [styles.notLogin]: !isLogin } )}>
                  <QRCode
                    color={theme === "dark" ? "#e8e8e6" : "#272b30"}
                    size={120}
                    value={QRCodeUrl}
                    bordered={false}
                  ></QRCode>
                  <div>扫码加入交流群</div>
                </div>
              ) : null
            }
            {!!isLogin && (
              <div className={styles.sider_menu}>
                {coin !== undefined && (
                  <div className={styles.coinWrap} onClick={() => {}}>
                    <img
                      src={CoinSvg}
                      alt=''
                    />
                    <Odometer value={coin} className={styles.coinText} />
                    <div className={styles.coinText}>智纪币</div>
                    <div
                      className={styles.fillWrap}
                      onClick={() => {
                        store.dispatch(openRecharge());
                      }}
                    >
                      {" "}
                      <img
                        src={FillSvg}
                        alt=''
                        className={styles.category_img}
                      />
                    </div>
                    <Tooltip title='1次GPT4o对话或1次Claude3.5对话消耗1智纪币'>
                      <Flex align='center'>
                        <img
                          src={theme !== "dark" ? QuestionDark : QuestionLight}
                          alt=''
                        />
                      </Flex>
                    </Tooltip>
                  </div>
                )}
                {userSiderBar.map((item, index) => {
                  const isInPath = item.link === location.pathname;
                  return (
                    <Link to={item.link} key={index}>
                      <div
                        className={classNames(
                          styles.sider_menu_item,
                          isInPath ? styles.sider_menu_item_active : ""
                        )}
                      >
                        <img
                          src={
                            isInPath
                              ? theme === "dark"
                                ? item.activeIcon
                                : item.lightActiveIcon
                              : theme === "dark"
                              ? item.icon
                              : item.iconLight
                          }
                          className={styles.sider_menu_item_icon}
                        ></img>
                        <span>{item.label}</span>
                      </div>
                    </Link>
                  );
                })}
              </div>
            )}
          </>
        }
      </div>

      <CreateAgent onClose={CreateAgentClose} show={show} />
    </Sider>
  );
};

export default MenuBar;
