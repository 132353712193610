import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./index.module.scss";
import { Input, message } from "antd";
import AvatarSvg from "@/assets/common/teacher.svg";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import agentBg from "@/assets/workflow/default-portrait.svg";
import "react-typed/dist/animatedCursor.css";
import baseWsURL from "@/request/getWsBaseURL";
import SendPic from "@/assets/chat/send.svg";
import SendLightPic from "@/assets/chat/send-light.svg";
import SendLightDisabledPic from "@/assets/chat/send-light-disabled.svg";
import SendDisabledPic from "@/assets/chat/send-disabled.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import hljs from "highlight.js";
import Cookies from "js-cookie";
import useLoginHooks from "@/hooks/useLoginHooks";
import "highlight.js/styles/vs2015.css";
import Content from "./components/Content";
import store, { loginOption } from "@/store";
import _, { isNumber } from "lodash";
import { getRandomError } from "@/common/errorMsg";
import UploadFilesList from "../uploadFilesList";
import { openRecharge } from "@/store/reducer/coinRecharge";
import { setCoinNumber } from "@/store/reducer/userConfig";
import useGlobalUserData from "@/hooks/useUserConfigHook";

type IChatBox = {
  agentId?: number | string;
  pubAgentId?: number | string;
  history?: any;
  botAvator?: string;
  questionEx?: Array<string>;
  greeting?: string;
};

let ws: any = null;
let controller = new AbortController();
const ChatBox = ({
  agentId,
  pubAgentId,
  history,
  botAvator,
  questionEx,
  greeting,
}: IChatBox) => {
  const { t } = useTranslation();
  const [chatMsg, setChatMsg] = useState("");
  const [sendNow, setSendNow] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [chatData, setChatData] = useState(history);
  const boxRef: any = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const theme = useThemeHooks();
  const [relatedClick, setRelatedClick] = useState(false);
  const token = localStorage.getItem("token") || Cookies.get("token");
  const [onFocus, setOnFocus] = useState(false);
  const [deleteFileList, setDeleteFileList] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isOnBottom, setIsOnBottom] = useState(false);
  const {avatar} = useGlobalUserData();
  const chatFiles = useMemo(() => {
    return fileList.filter((file: any) => {
      return !deleteFileList.some((item: any) => item.uid === file.uid);
    });
  }, [fileList, deleteFileList]);

  const { isLogin } = useLoginHooks();
  let isFinished = true;

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const tryAgain = (index: number) => {
    const data = [...chatData].slice(0, index);

    const lastMsg = _.findLast(data, (item) => item.role === 1);

    if (lastMsg.role === 1) {
      setChatMsg(lastMsg.content);
      setSendNow(true);
      goChatBottom();
    }
  };

  useEffect(() => {
    setChatData(history);
  }, [history]);

  useEffect(() => {
    if (!!sendNow) {
      sendMsg();
      setSendNow(false);
    }
  }, [sendNow]);

  const highlightCode = () => {
    const preEl = document.querySelectorAll("pre");

    preEl.forEach((el) => {
      hljs.highlightBlock(el);
    });
  };

  const connectWs = (func?: any) => {
    const token_string = `?token=${token}`;
    ws = new WebSocket(
      `${baseWsURL}/ws/agent_chat${token ? token_string : ""}`
    );

    ws.onopen = function (event: any) {
      if (func) {
        func();
      }
    };

    ws.onclose = function (event: any) {
      setBtnLoading(false);
      console.log("wsclose");
      highlightCode();
    };

    ws.onmessage = (res: any) => {
      const data = JSON.parse(res.data);
      if (data.status === 40103) {
        onLoginAndRegister();
      } else if (data.status === 200 && data.from !== "userproxy" && data) {
        let { is_finished, content, content_type } = data;
        if (isFinished) {
          setChatData((chatData: any) => {
            const newChatData = [...chatData];
            newChatData.push({ ...data, content_type });
            return newChatData;
          });
          isFinished = is_finished;
        } else {
          if (content_type === "text") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: newChatData[newChatData.length - 1].content + content,
                content_type,
              };
              return newChatData;
            });
          } else if (content_type === "search") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: {
                  ...data?.content,
                  answer:
                    newChatData[newChatData.length - 1]?.content?.answer +
                    data?.content?.answer,
                  content_type,
                },
              };
              return newChatData;
            });
          }
          isFinished = is_finished;
        }
      }

      if ((data.status && data.status === 20050) || data.status === 50001) {
        setBtnLoading(false);
        highlightCode();
      }

      if (data.status && data.status === 50001) {
        const errorMsg = getRandomError();
        message.error(errorMsg);
      }

      if (data.status && (data.status === 40030 || data.status === 40031)) {
        store.dispatch(openRecharge());
        message.error(
          data.status === 40031
            ? "没有智纪币啦, 为您打开订购套餐页面~"
            : "当前对话为付费套餐才可以使用的对话哦, 为您打开订购套餐页面~"
        );
        setBtnLoading(false);
      }

      if (data.current_coin_count && isNumber(data.current_coin_count)) {
        store.dispatch(setCoinNumber(data.current_coin_count));
      }

      hljs.initHighlighting();
    };

    ws.onerror = function (error: any) {
      setBtnLoading(false);
      console.log("wserror", error);
    };
  };

  const unConnectWs = () => {
    if (ws) {
      ws.close();
    }
  };

  useEffect(() => {
    hljs.highlightAll();
  }, [chatData]);

  useEffect(() => {
    if (agentId) {
      controller.abort();
      controller = new AbortController();
    }
  }, [agentId]);

  const curChatMsg = localStorage.getItem("curChatMsg");

  useEffect(() => {
    if (!!curChatMsg) {
      return;
    }

    unConnectWs();

    setTimeout(() => {
      connectWs();
    }, 200);

    return () => {
      unConnectWs();
    };
  }, []);

  useEffect(() => {
    if (curChatMsg) {
      unConnectWs();
      setTimeout(() => {
        connectWs(() => {
          setChatMsg(curChatMsg);
          setSendNow(true);
          localStorage.setItem("curChatMsg", "");
        });
      }, 200);
    }
  }, [curChatMsg]);

  const goChatBottom = () => {
    if (boxRef?.current) {
      boxRef.current.scrollTop = boxRef?.current?.scrollHeight;
    }
  };

  useEffect(() => {
    const onScroll = () => {
      const { scrollTop } = boxRef?.current;
      if (scrollTop > lastScrollTop) {
        setIsScrollingUp(false); // 用户向下滚动
      } else {
        setIsScrollingUp(true); // 用户向上滚动
      }
      setLastScrollTop(scrollTop);
    };

    boxRef.current.addEventListener("scroll", onScroll);

    return () => {
      boxRef && boxRef?.current?.removeEventListener("scroll", onScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    setIsOnBottom(true);
    if (boxRef?.current && boxRef.current?.scrollTop !== undefined) {
      boxRef.current.scrollTop = boxRef.current?.scrollHeight;
    }
  }, [agentId, pubAgentId]);

  useEffect(() => {
    const handleScrollFn = () => {
      const { scrollTop, scrollHeight, clientHeight } = boxRef?.current;
      const errorTolerance = 80; // 设置一个误差容忍值
      if (
        errorTolerance >= Math.abs(scrollTop - (scrollHeight - clientHeight))
      ) {
        setIsOnBottom(true); // 此时已经在底部了
      } else {
        setIsOnBottom(false);
      }
    };

    boxRef?.current?.addEventListener("scroll", handleScrollFn);

    return () => {
      boxRef && boxRef?.current?.removeEventListener("scroll", handleScrollFn);
    };
  }, []);

  useEffect(() => {
    let boxScrollBottom: any = null;
    // 根据滚动方向进行相应处理

    if (!isScrollingUp && isOnBottom) {
      if (boxRef?.current) {
        boxScrollBottom = setInterval(() => {
          if (
            boxRef.current?.scrollTop === undefined ||
            boxRef.current?.scrollTop === null
          ) {
            return;
          }
          boxRef.current.scrollTop = boxRef.current?.scrollHeight;
        }, 100);
      }
    } else {
      clearInterval(boxScrollBottom);
    }

    return () => {
      if (boxScrollBottom) {
        clearInterval(boxScrollBottom);
      }
    };
  }, [chatData, isScrollingUp, isOnBottom]);

  const getTopicId = (id: any) => {
    if (!isLogin || !id) {
      return -1;
    }

    return id;
  };

  const curQuestions = () => {
    if (!questionEx) return [];
    return questionEx
      ?.filter((item) => {
        const currentLocalStorage = localStorage.getItem(
          `${agentId || pubAgentId}_question`
        )
          ? JSON.parse(
              localStorage.getItem(`${agentId || pubAgentId}_question`) || "[]"
            )
          : [];
        return item !== "" && currentLocalStorage.indexOf(item) === -1;
      })
      .map((item, index) => {
        return (
          item.trim() && (
            <div
              className={Styles.question_item}
              key={index}
              onClick={() => {
                const currentLocalStorage = localStorage.getItem(
                  `${agentId || pubAgentId}_question`
                )
                  ? JSON.parse(
                      localStorage.getItem(
                        `${agentId || pubAgentId}_question`
                      ) || "[]"
                    )
                  : [];

                if (currentLocalStorage.indexOf(item) === -1) {
                  currentLocalStorage.push(item);
                  localStorage.setItem(
                    `${agentId || pubAgentId}_question`,
                    JSON.stringify(currentLocalStorage)
                  );
                }

                setChatMsg(item);
                setSendNow(true);
              }}
            >
              <span>{item}</span>
            </div>
          )
        );
      });
  };

  const sendMsg = () => {
    if (btnLoading) {
      return;
    }

    if (!chatMsg) {
      return false;
    }

    if (!token) {
      onLoginAndRegister();
      return;
    }
    setBtnLoading(true);
    const newChatData = [...chatData];
    const sendMsgWs = (id?: any) => {
      let msgObj: any = {
        message: chatMsg,
        topic_id: getTopicId(id ? id : -2),
        history: newChatData.slice(0, newChatData.length).slice(-6),
        is_save_msg: token ? true : false,
        chat_mode: "ai_agent",
        is_stream: true,
      };

      msgObj = {
        history: newChatData.slice(0, newChatData.length), // 历史聊天记录
        message: chatMsg,
        chat_mode: "ai_study", // ai_search or ai_agent
      };

      newChatData.push({
        role: 1,
        content: msgObj?.message,
      });

      const sendAction = () => {
        if (ws.readyState === 1) {
          //开始通信时的处理
          ws.send(JSON.stringify(msgObj));
        }
      };

      if (ws && ws.readyState === 1) {
        sendAction();
      } else {
        unConnectWs();
        setTimeout(() => {
          connectWs(() => {
            sendAction();
          });
        });
      }
    };
    sendMsgWs();
    setChatData(newChatData);
    setChatMsg("");
    setFileList([]);
    setDeleteFileList([]);
  };

  const getSendImgSrc = () => {
    return theme === "dark"
      ? btnLoading || !chatMsg
        ? SendDisabledPic
        : SendPic
      : btnLoading || !chatMsg
      ? SendLightDisabledPic
      : SendLightPic;
  };

  const onRelatedClick = (message: string) => {
    setChatMsg(message);
    setRelatedClick((value) => !value);
    goChatBottom();
  };

  useEffect(() => {
    sendMsg();
  }, [relatedClick]);

  useEffect(() => {
    if (chatData.length) {
      const clickHandler = (event: any) => {
        if (!event.target.getAttribute("href")) {
          return;
        }
        event.preventDefault();
        console.log(event.target.getAttribute("href"));
        window.open(event.target.getAttribute("href"), "_blank");
      };
      var content: any = document.getElementById("history");
      const aTags = content.getElementsByTagName("a");
      Array.prototype.forEach.bind(aTags)((a) => {
        a.addEventListener("click", clickHandler);
      });
    }
  }, [chatData.length]);

  return (
    <div
      className={classnames(
        Styles.chat,
        Styles.chat_preview,

        Styles.chat_advanced
      )}
    >
      <div className={Styles.chat_inner}>
        <div className={Styles.chat_history} ref={boxRef}>
          {chatData && !!chatData.length ? (
            <div
              className={Styles.chat_history_inner}
              id='history'
              style={
                !!curQuestions().length
                  ? {
                      marginBottom: 0,
                    }
                  : {}
              }
            >
              {chatData.map((item: any, index: number) => {
                return (
                  <div
                    className={classnames(
                      Styles.chat_history_item,
                      // item.role === 2
                      Styles.chat_history_item_receive
                      // : Styles.chat_history_item_send
                    )}
                    key={index}
                  >
                    <div className={Styles.chat_history_name_box}>
                      <img
                        className={Styles.chat_history_item_img}
                        src={item.role === 2 ? botAvator || agentBg : avatar || AvatarSvg}
                        loading='lazy'
                        alt=''
                      ></img>
                      <div>
                        {item.role === 2
                          ? item?.from ||
                            item?.sender_name ||
                            "知识检索助手" ||
                            ""
                          : t("common.you")}
                      </div>
                    </div>
                    {item.content && (
                      <div className={Styles.chat_history_item_msg}>
                        {item.role === 2 ? (
                          <span
                            style={{ whiteSpace: "pre-wrap", width: "100%" }}
                          >
                            <Content
                              curWidth={boxRef.current?.clientWidth}
                              message={item}
                              onRelatedClick={onRelatedClick}
                              isLast={index === chatData.length - 1}
                              tryAgain={() => {
                                tryAgain(index);
                              }}
                              loading={btnLoading}
                            />
                          </span>
                        ) : (
                          <div className={Styles.chat_history_item_wrapper}>
                            <div className={Styles.chat_history_item_inner}>
                              <span
                                style={{
                                  whiteSpace: "pre-wrap",
                                  width: "100%",
                                }}
                              >
                                {typeof item.content === "string" ? (
                                  item.content
                                ) : (
                                  <>
                                    {
                                      item?.content?.find(
                                        (subItem: any) =>
                                          subItem.type === "text"
                                      )?.text
                                    }
                                  </>
                                )}
                              </span>
                            </div>
                            {Array.isArray(item?.content) &&
                            item?.content?.filter(
                              (subItem: any) => subItem.type !== "text"
                            ).length ? (
                              <div
                                className={Styles.filesList}
                                style={{
                                  position: "relative",
                                  top: "-10px",
                                }}
                              >
                                <UploadFilesList
                                  fileList={item?.content?.filter(
                                    (subItem: any) => subItem.type !== "text"
                                  )}
                                  isChat={true}
                                />
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              {btnLoading && (
                <div className={Styles.chat_history_item_loading}>
                  <div className='dot-pulse'></div>
                </div>
              )}
            </div>
          ) : (
            <div className={classnames(Styles.empty, Styles.empty_card)}>
              <img
                src={botAvator}
                className={Styles.card_empty_img}
                loading='lazy'
                alt=''
              ></img>
              <div
                className={Styles.card_empty_msg}
                dangerouslySetInnerHTML={{
                  __html: greeting || "",
                }}
              ></div>
            </div>
          )}

          {!!curQuestions().length && (
            <div
              className={Styles.questionsWrap}
              style={
                chatData && !chatData.length
                  ? {
                      position: "absolute",
                      bottom: "0px",
                    }
                  : {
                      width: "100%",
                      position: "relative",
                      // top: "-20px",
                      bottom: "0px",
                      padding: 0,
                    }
              }
            >
              <div
                className={Styles.questions}
                style={{
                  ...(curQuestions()?.length === 1
                    ? {
                        gridTemplateColumns: "1fr",
                      }
                    : {}),
                  ...(chatData && !chatData.length
                    ? {}
                    : {
                        width: "100%",
                      }),
                }}
              >
                {curQuestions()}
              </div>
            </div>
          )}
        </div>

        <div className={Styles.chat_insert}>
          <div
            className={Styles.chat_to_bottom}
            style={{ visibility: isOnBottom ? "hidden" : "visible" }}
          >
            <img
              className={Styles.chat_insert_send}
              src={theme === "dark" ? SendDisabledPic : SendLightDisabledPic}
              onClick={() => {
                goChatBottom();
              }}
              loading='lazy'
              alt=''
            ></img>
          </div>
          <div
            className={Styles.chat_input_box_wrap}
            style={{
              height: "100%",
              position: "relative",
              bottom: !!chatFiles.length
                ? chatFiles.length <= 3
                  ? "100px"
                  : chatFiles.length <= 6
                  ? "150px"
                  : "215px"
                : "0",
            }}
          >
            <div
              className={
                onFocus ? Styles.chat_input_wrap_active : Styles.chat_input_wrap
              }
              style={{
                background: "var(--theme-bg2)",
              }}
            >
              <Input.TextArea
                onFocus={() => {
                  setOnFocus(true);
                }}
                onBlur={() => {
                  setOnFocus(false);
                }}
                className={Styles.chat_insert_input}
                value={chatMsg}
                autoSize={{ minRows: 1, maxRows: 4 }}
                onChange={(e) => {
                  setChatMsg(e.target.value);
                }}
                style={{
                  background: "var(--theme-bg2)",
                }}
                placeholder={t("chat.input.placeholder")}
                onPressEnter={(e) => {
                  if (e.which === 13 || e.keyCode === 13) {
                    if (e.shiftKey) {
                      return;
                    }

                    if (!isTyping) {
                      sendMsg();
                    }
                    e.preventDefault();
                  }
                }}
                onCompositionStart={() => {
                  setIsTyping(true);
                }}
                onCompositionEnd={() => {
                  setIsTyping(false);
                }}
              ></Input.TextArea>

              <div className={Styles.chat_insert_right}>
                <img
                  className={Styles.chat_insert_send}
                  src={getSendImgSrc()}
                  onClick={() => {
                    sendMsg();
                  }}
                  loading='lazy'
                  alt=''
                ></img>
              </div>
            </div>
            {chatFiles && chatFiles.length > 0 && (
              <div className={Styles.filesList}>
                <UploadFilesList
                  fileList={chatFiles}
                  onDelete={(index: number) => {
                    setDeleteFileList([...deleteFileList, chatFiles[index]]);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
