import { useEffect, useState, useRef } from "react";
import Styles from "./index.module.scss";
import { Graph } from "@antv/g6";
import { message } from "antd";
import ChatBox from "@/components/ChatBox";
import useConfigHooks from "@/hooks/useConfigHooks";
import { CloseOutlined } from "@ant-design/icons";
import { createImageUrl, getImageUrl } from "@/utils/common";
import {
  getCategoryArticleList,
  getCategoryArticle,
  getJsonConfig,
  getAdvanceConfig,
  saveAdvanceConfig,
  getTags,
} from "@/apis/common";
import useThemeHooks from "@/hooks/useThemeHooks";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import framePic from "@/assets/common/frame.svg";
import teacherPic from "@/assets/common/teacher.svg";
import settingPic from "@/assets/advanced/setting.svg";
import settingLightPic from "@/assets/advanced/setting-light.svg";
import { Switch, Breadcrumb, Pagination } from "antd";
import "highlight.js/styles/vs2015.css";
import hljs from "highlight.js";
import { useSearchParams } from "react-router-dom";
import store from "@/store";
import { openRecharge } from "@/store/reducer/coinRecharge";
import useGlobalUserData from "@/hooks/useUserConfigHook";

const agentBg = createImageUrl('common/agent-bg.png');
const teacherChatPic = createImageUrl('advanced/teacher.png');

let graph: any;

const Advanced = () => {
  const [type, setType] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [notFirstShow, setNotFirstShow] = useState(false);
  const [knowledgeNodes, setKnowledgeNodes] = useState<any>({});
  const [history, setHistory] = useState<any>([]);
  const [showSetting, setShowSetting] = useState(false);
  const [tagAllList, setTagAllList] = useState<any>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [currentTag, setCurrentTag] = useState<any>("");
  const [article, setArticle] = useState({
    content: "",
    title: "",
  });
  const [settingData, setSettingData] = useState<any>({});
  const [titleList, setTitleList] = useState<any>([]);
  const [settingsOptions, setSettingsOptions] = useState<any>([]);
  const [articleList, setArticleList] = useState<Array<any>>([]);
  const [contentType, setContentType] = useState("text");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(12);
  const [total, setTotal] = useState(0);
  const [questionSamples, setQuestionSamples] = useState<any>([]);
  const [greeting, setGreeting] = useState<any>("");
  const theme = useThemeHooks();
  const containerRef: any = useRef<HTMLDivElement>(null);

  const [params] = useSearchParams();
  const paramsId = params.getAll("id")?.[0] || "";
  const paramsArticleID = params.getAll("articleID")?.[0] || "";
  const { subscription_plan_type } = useGlobalUserData();

  const changePagination = (pageNo: number, pageSize: number) => {
    setPageNo(pageNo);
  };

  useEffect(() => {
    if (pageNo && titleList[titleList.length - 1]?.category) {
      CategoryArticleList(
        titleList[titleList.length - 1]?.id,
        titleList[titleList.length - 1]?.category
      );
    }
  }, [pageNo]);

  const CategoryArticleList = (
    id: any,
    category: any,
    isMenuClick?: boolean
  ) => {
    getCategoryArticleList({
      category: category,
      page: pageNo,
      pageSize: pageSize,
      tags: currentTag,
    }).then((res: any) => {
      if (res && res.data && res.data.statusCode === 200) {
        const articles = res.data.data.articles || [];
        setArticleList(articles);

        setTotal(res.data.data.total || 0);
        setContentType("list");
        if (!isMenuClick) {
          let newTitleList: any = [
            {
              name: "进阶学习之路",
              icon: framePic,
              id: -1,
            },
          ];

          setKnowledgeNodes((knowledgeNodes: any) => {
            let haveNodes = false;
            knowledgeNodes.edges.forEach((edge: any) => {
              if (edge.target === id && edge.source !== "知识空间") {
                knowledgeNodes.nodes.forEach((item: any) => {
                  if (item.id === edge.source) {
                    haveNodes = true;
                    newTitleList.push({
                      name: item.label,
                      id: item.id,
                      articleID: item.articleID,
                      category: item.category,
                    });
                    newTitleList.push({
                      name: category,
                      id: id,
                      category: category,
                    });
                  }
                });
              }
            });
            if (!haveNodes) {
              newTitleList.push({
                name: category,
                id: id,
                category: category,
              });
            }
            return knowledgeNodes;
          });

          setTitleList(newTitleList);

          let newTaglist: any = [];

          setTagAllList((tagAllList: any) => {
            for (let list_item in tagAllList) {
              tagAllList[list_item].forEach((item: any) => {
                if (
                  item.category === category &&
                  !newTaglist.includes(list_item)
                ) {
                  newTaglist.push(list_item);
                }
              });
            }

            setTagList(newTaglist);

            return tagAllList;
          });

          console.log(newTaglist);
        }

        setNotFirstShow(true);
        setShowDetail(true);

        console.log(articles, "------f-----", paramsArticleID);
        if (
          articles.length &&
          articles.some((item: any) => {
            return `${item.id}` === `${paramsArticleID}`;
          })
        ) {
          CategoryArticle(-3, Number(paramsArticleID), undefined);
        }
      }
    });
  };

  const CategoryArticle = (
    id: any,
    articleID: any,
    label: any,
    isMenuClick?: boolean
  ) => {
    getCategoryArticle({
      id: articleID,
    }).then((res: any) => {
      if (res && res.data && res.data.statusCode === 200) {
        if (!isMenuClick) {
          let newTitleList: any = [];
          if (id === -3) {
            newTitleList = [...titleList];
            newTitleList.push({
              name: res.data.data.article.title,
              id: id,
              articleID: articleID,
            });
          } else {
            newTitleList = [
              {
                name: "进阶学习之路",
                icon: framePic,
                id: -1,
              },
            ];
            newTitleList.push({
              name: label,
              id: id,
              articleID: articleID,
            });
          }
          setTitleList(newTitleList);
        }
        setArticle(res.data.data.article);
        setContentType("text");
        setNotFirstShow(true);
        setShowDetail(true);

        setTimeout(() => {
          highlightCode();
        }, 1000);
      }
    });
  };

  useEffect(() => {
    if (article) {
      const clickHandler = (event: any) => {
        if (!event.target.getAttribute("href")) {
          return;
        }

        // 阻止 a 标签点击事件的默认行为，即页面跳转
        event.preventDefault();

        console.log(event.target.getAttribute("href"));

        window.open(event.target.getAttribute("href"), "_blank");

        // 进行你需要的处理
        // ...
      };

      var content: any = document.getElementById("detail");

      console.log(content, 1234);

      const aTags = content.getElementsByTagName("a");

      // 借用 Array.prototype.forEach 遍历处理 选到的 a 标签
      Array.prototype.forEach.bind(aTags)((a) => {
        a.addEventListener("click", clickHandler);
      });
    }
  }, [article]);

  const highlightCode = () => {
    const preEl = document.querySelectorAll("pre");

    preEl.forEach((el) => {
      hljs.highlightBlock(el);
    });
  };

  const generateGraph = (data: any) => {
    data.nodes.forEach((item: any) => {
      item.label = item.id;
    });
    const container: any = document.getElementById("g6-container");
    const width = container?.scrollWidth || 500;
    const height = container?.scrollHeight || 800;
    const nodes = data.nodes;
    graph = new Graph({
      container: "g6-container",
      defaultEdge: {
        /* style for the keyShape */
        style: {
          stroke: theme === "dark" ? "#2f2f2d" : "#d2d2d2",
          lineAppendWidth: 1,
        },
      },
      width,
      height,
      layout: {
        type: "force",
        preventOverlap: true,
        linkDistance: (d: any) => {
          if (d.source.id === "AI Agent学习地图" || d.source?.root) {
            if (width > 650) {
              return 140;
            }
            return 120;
          }
          if (d.isLeaf) {
            return 55;
          }
          return 55;
        },
        nodeSize: 63,
        collideStrength: 1,
        alpha: 1.2,
        nodeCollideStrength: 1,
        comboCollideStrength: 20,
        nodeStrength: (d: any) => {
          if (d.id === "AI Agent学习地图" || d?.root) {
            return -100; // 增加根节点的斥力
          }
          if (d.isLeaf) {
            return -20;
          }
          return -40;
        },
        edgeStrength: (d: any) => {
          if (d.source.id === "AI Agent学习地图" || d.source?.root) {
            return 0.25; // 减小根节点的引力
          }
          if (d.source.isLeaf === true) {
            return 1.3;
          }
          return 1.3;
        },
      },
      defaultNode: {
        color: "#888888",
        labelCfg: {
          position: "bottom",
          style: {
            fill: theme === "dark" ? "#fff" : "#000",
            fontSize: 10,
            stroke: theme === "dark" ? "#1d1d1b" : "#f7f7f7", // 描边颜色
            strokeWidth: 10, // 描边宽度
          },
        },
        size: 6,
        style: {
          cursor: "pointer",
          fill: "#5C5C5C",
          lineWidth: 0,
        },
      },
      nodeStateStyles: {
        active: {
          fill: "#00D44B",
          lineWidth: 0,
        },
        selected: {
          fill: "#00D44B", // 这个颜色可以根据个人喜好进行修改
          lineWidth: 0,
        },
        hover: {
          fill: "#5C5C5C",
          lineWidth: 0,
        },
        highlight: {
          fill: "#5C5C5C",
          lineWidth: 0,
        },
        disable: {
          fill: "#5C5C5C",
          lineWidth: 0,
        },
        default: {
          fill: "#5C5C5C",
          lineWidth: 0,
        },
        inactive: {
          fill: "#5C5C5C",
          lineWidth: 0,
        },
      },
      edgeStateStyles: {
        active: {
          stroke: "#00D44B",
        },
        selected: {
          stroke: "#00D44B",
        },
        hover: {
          stroke: theme === "dark" ? "#2f2f2d" : "#d2d2d2",
        },
        highlight: {
          stroke: theme === "dark" ? "#2f2f2d" : "#d2d2d2",
        },
        disable: {
          stroke: theme === "dark" ? "#2f2f2d" : "#d2d2d2",
        },
        inactive: {
          stroke: theme === "dark" ? "#2f2f2d" : "#d2d2d2",
        },
      },
      modes: {
        default: [
          "activate-relations",
          "click-select",
          "drag-canvas",
          "zoom-canvas",
        ],
      },
    });
    graph.data({
      nodes,
      edges: data.edges.map(function (edge: any, i: any) {
        edge.id = "edge" + i;
        return Object.assign({}, edge);
      }),
    });
    graph.fitCenter();
    graph.fitView(0, { onlyOutOfViewPort: true });
    graph.focusItem("进阶学习地图");
    graph.render();

    graph.on("node:mouseenter", (e: any) => {
      const nodeItem = e.item;
      graph.updateItem(nodeItem, {
        labelCfg: {
          style: {
            fill: "#00D44B", // 改变文字颜色
            fontWeight: "bold", // 改变文字粗细
            zIndex: 2,
            // 其他样式...
          },
        },
      });
    });

    graph.on("node:mouseleave", (e: any) => {
      const nodeItem = e.item;
      graph.updateItem(nodeItem, {
        labelCfg: {
          style: {
            fill: theme === "dark" ? "#fff" : "#000", // 恢复文字颜色
            fontWeight: "normal", // 恢复文字粗细
            zIndex: 0,
            // 其他样式...
          },
        },
      });
    });

    graph.on("node:click", function (e: any) {
      if (subscription_plan_type !== 2) {
        return;
      }
      if (e.item.getModel().articleID) {
        setPageNo(1);
        CategoryArticle(
          e.item.getModel().id,
          e.item.getModel().articleID,
          e.item.getModel().label
        );
      } else if (e.item.getModel().category) {
        setPageNo(1);
        CategoryArticleList(e.item.getModel().id, e.item.getModel().category);
      }
    });

    // let nodeStartPosition: any = null;

    graph.on("node:dragstart", function (e: any) {
      if (e.item._cfg.id !== "进阶学习地图") {
        // graph.layout();
        // refreshDragedNodePosition(e);
        // nodeStartPosition = {
        //   x: e.item.getModel().x,
        //   y: e.item.getModel().y,
        // };
      }
    });
    graph.on("node:drag", function (e: any) {
      if (e.item._cfg.id !== "进阶学习地图") {
        // refreshDragedNodePosition(e);
      }
    });
    graph.on("node:dragend", function (e: any) {
      if (e.item._cfg.id !== "进阶学习地图") {
        e.item.get("model").fx = null;
        e.item.get("model").fy = null;
      }
      // graph.updateItem(e.item, {
      //   x: nodeStartPosition.x,
      //   y: nodeStartPosition.y,
      // });
    });

    setTimeout(() => {
      graph.fitCenter();
      graph.focusItem("进阶学习地图");
      graph.fitView(0, { onlyOutOfViewPort: true });
    }, 200);
  };

  useEffect(() => {
    console.log(
      contentType === "list",
      pageNo,
      pageSize,
      currentTag,
      titleList
    );
    if (contentType === "list" && pageSize && pageNo) {
      console.log(contentType, pageNo, pageSize, currentTag, titleList, 222);
      CategoryArticleList(
        titleList[titleList.length - 1]?.id,
        titleList[titleList.length - 1]?.category,
        true
      );
    }
  }, [pageNo, pageSize, currentTag, contentType]);

  useEffect(() => {
    if (theme) {
      if (graph) {
        graph.destroy();
      }
      getJsonConfig({
        keys: [
          "learning_knowledge_nodes",
          "learning_user_options",
          "learning_greeting",
        ],
      }).then((res) => {
        if (res.status === 200 && res.data.data.learning_knowledge_nodes) {
          setKnowledgeNodes(
            res.data.data.learning_knowledge_nodes || {
              nodes: [],
              edges: [],
            }
          );

          const nodes = res.data.data.learning_knowledge_nodes?.nodes || [];
          if (
            nodes.some((item: any) => item.id === decodeURIComponent(paramsId))
          ) {
            const currentNode = nodes.filter(
              (item: any) => item.id === decodeURIComponent(paramsId)
            )[0];
            if (currentNode.articleID) {
              CategoryArticle(
                currentNode.id,
                currentNode.articleID,
                currentNode.label
              );
            } else if (currentNode.category) {
              CategoryArticleList(currentNode.id, currentNode.category);
            }
          }

          generateGraph(res.data.data.learning_knowledge_nodes);
        }

        if (
          res.status === 200 &&
          res.data.data.learning_greeting.question_samples
        ) {
          setQuestionSamples(res.data.data.learning_greeting?.question_samples);
        }

        if (res.status === 200 && res.data.data.learning_greeting?.greeting) {
          setGreeting(res.data.data.learning_greeting?.greeting);
        }

        if (res.status === 200 && res.data.data.learning_user_options) {
          setSettingsOptions(res.data.data.learning_user_options);
        }
      });
      // setTimeout(() => {
      //   generateGraph();
      // }, 100);
    }
  }, [theme]);

  useEffect(() => {
    getSettings();
    getTagsAction();
  }, []);

  useEffect(() => {
    const hasContent = Object.keys(settingData).length > 0;
    if (!showSetting) {
      if (hasContent) {
        saveAdvanceConfig({
          learning_config: settingData,
        }).then((res: any) => {
          if (res && res.data && res.data.status === 200) {
            message.success("保存成功");
          }
        });
      }
    } else {
      if (hasContent) {
        getSettings();
      }
    }
  }, [showSetting]);

  const changeSettingData = (key: string, value: any) => {
    let newSettingData = { ...settingData };
    newSettingData[key] = value;
    setSettingData(newSettingData);
  };

  const getSettings = () => {
    getAdvanceConfig({}).then((res: any) => {
      if (res && res.data && res.data.status === 200) {
        setSettingData(res.data.data.learning_config);
      }
    });
  };

  const getTagsAction = () => {
    getTags().then((res: any) => {
      if (res && res.data && res.data.statusCode === 200) {
        setTagAllList(res.data.data);
      }
    });
  };

  const maskRender = () => {
    return (
      <div
        className={Styles.maskBox}
        style={{
          background:
            theme === "light"
              ? "linear-gradient(180deg, color(display-p3 1 1 1 / 0.00) 0%, color(display-p3 1 1 1) 25%)"
              : "linear-gradient(180deg, color(display-p3 0.0196078 0.0196078 0.0235294 / 0) 0%, color(display-p3 0.0196078 0.0196078 0.0235294 / 1) 25%)",
        }}
      >
        <div className={Styles.title}>订阅解锁系统学习</div>
        <div className={Styles.subTitle}>
          系统学习包含一个不断完善的AI知识库、课程库和个性化学习导师, 你不仅可以系统性学习AI大模型及其应用的相关知识, 甚至可以自定义模式学习任何知识!
        </div>
        <div
          className={Styles.btn}
          onClick={() => {
            store.dispatch(openRecharge());
          }}
        >
          立即订阅
        </div>
      </div>
    );
  };

  const { config } = useConfigHooks();

  useEffect(() => {
    if (graph && graph?.changeSize) {
      if (showDetail) {
        const container: any = document.getElementById("g6-container");
        const width = container?.scrollWidth || 500;
        let height: number = container?.scrollHeight || 800;
        const halfHeight = height / 2;
        const oneHeight = (height - halfHeight) / 30;
        const interval = setInterval(() => {
          if (height > halfHeight) {
            height = height - oneHeight;
          } else {
            clearInterval(interval);
          }
          try {
            graph.changeSize(width, height);
            // graph.fitCenter();
            graph.fitView(0, { onlyOutOfViewPort: true });
          } catch (error) {}
        }, 20);
      } else {
        const container: any = document.getElementById("g6-container");
        const width = container?.scrollWidth || 500;
        let height: number = container?.scrollHeight || 800;
        let halfHeight = height / 2;
        const oneHeight = (height - halfHeight) / 30;
        const interval = setInterval(() => {
          if (height > halfHeight) {
            halfHeight = halfHeight + oneHeight;
            try {
              graph.changeSize(width, halfHeight);
              // graph.fitCenter();
              graph.fitView(0, { onlyOutOfViewPort: true });
            } catch (error) {}
          } else {
            clearInterval(interval);
          }
        }, 20);
      }
    }
  }, [showDetail]);

  const getMarkDownImg = (markdown: string) => {
    const regex = /!\[.*?\]\((.*?)\)/g;
    let match;
    let imgUrl = "";
    while ((match = regex.exec(markdown)) !== null) {
      imgUrl = match[1]; // 输出匹配到的图片 URL
    }
    return imgUrl ? "https://blog.syngents.cn" + imgUrl : "";
  };

  const getMarkDownMore = (markdown: string) => {
    const regex = /<!-- more -->([\s\S]*?)<!-- more -->/;
    const match = markdown.match(regex);
    let more = "";
    if (match) {
      more = match[1]; // 输出匹配到的内容
    }
    return more;
  };

  const clickTab = (item: any, index: number) => {
    if (index === titleList.length - 1 || subscription_plan_type !== 2) {
      return;
    }
    if (item?.id === -1 || item?.id === -2) {
      setShowDetail(false);
    } else {
      if (item.articleID) {
        setPageNo(1);
        CategoryArticle(item.id, item.articleID, item.label, true);
      } else if (item.category) {
        setPageNo(1);
        CategoryArticleList(item.id, item.category, true);
      }

      setTitleList(titleList.slice(0, index + 1));
    }
  };
  return (
    <div className={Styles.advanced}>
      {/* <Switch
        checked={type === 0}
        onChange={(value: any) => {
          changeSwitch(value);
        }}
      ></Switch> */}
      <div className={Styles.Content}>
        {/* <Spin
          spinning={loading}
          style={{
            width: "100%",
          }}


        > */}
        <div
          className={classNames(
            Styles.Content_return,
            "magictime",
            showDetail ? "tinUpIn" : "spaceOutUp"
          )}
          style={{
            display: notFirstShow ? "flex" : "none",
          }}
          onClick={() => {
            setShowDetail(false);
          }}
        >
          <span
            style={{
              fontWeight: 600,
              animationDuration: "0.3s",
            }}
          >
            {"<"}
          </span>
          &nbsp;返回
        </div>
        <div className={Styles.Content_inner}>
          <div
            style={{
              animationDuration: "0.6s",
            }}
            className={classNames(
              Styles.Content_header,
              "magictime",
              showDetail ? "slideUp" : "slideUpReturn"
            )}
          >
            <div className={Styles.header_inner}>
              <img src={framePic}></img>
              <span>知识空间</span>
            </div>
          </div>
          <div
            className={Styles.ContentG6}
            id='g6-container'
            ref={containerRef}
            onClick={() => {
              // setShowDetail(false);
            }}
          ></div>
          <div
            className={classNames(
              Styles.Content_detail,
              showDetail ? Styles.Content_detail_show : "",
              "magictime",
              notFirstShow ? (showDetail ? "spaceInDown" : "spaceOutDown") : "",
              "scroll-hide"
            )}
            style={{
              animationDuration: showDetail ? "0.6s" : "1.2s",
              display: notFirstShow ? "block" : "none",
            }}
            id='detail'
          >
            {!!titleList.length && (
              <div className={Styles.title_list}>
                {titleList.map((item: any, index: number) => {
                  return (
                    <div
                      className={classNames(
                        Styles.title_list_item,
                        index === titleList.length - 1
                          ? Styles.title_list_item_last
                          : ""
                      )}
                      onClick={() => {
                        clickTab(item, index);
                      }}
                    >
                      {index > 0 && <span>&nbsp;{">"}&nbsp;</span>}
                      {item.icon && <img src={item.icon}></img>}
                      {item.name}
                    </div>
                  );
                })}
              </div>
            )}
            {contentType === "text" && (
              <>
                <div className={Styles.article_title}>
                  {titleList[titleList.length - 1]?.name}
                </div>
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[gfm]}
                >
                  {article.content.replaceAll(
                    "/static/img/",
                    "https://blog.syngents.cn/static/img/"
                  )}
                </ReactMarkdown>
              </>
            )}
            {contentType === "list" && (
              <div className={Styles.article_list}>
                <div className={Styles.article_title}>
                  {titleList[titleList.length - 1]?.name}
                </div>
                <div className={Styles.article_list_tags}>
                  <div
                    className={classNames(
                      Styles.article_list_tag,
                      currentTag === "" ? Styles.article_list_tag_active : ""
                    )}
                    onClick={() => {
                      setPageNo(1);
                      setCurrentTag("");
                    }}
                  >
                    全部
                  </div>
                  {tagList.map((item: any, index: number) => {
                    return (
                      <div
                        className={classNames(
                          Styles.article_list_tag,
                          currentTag === item
                            ? Styles.article_list_tag_active
                            : ""
                        )}
                        onClick={() => {
                          setPageNo(1);
                          setCurrentTag(item);
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
                <div className={Styles.article_list_box}>
                  {articleList.map((item, index) => {
                    return (
                      <div
                        className={Styles.article_item}
                        onClick={() => {
                          CategoryArticle(-3, item.id, item.label);
                        }}
                      >
                        <img
                          src={getMarkDownImg(item.content) || agentBg}
                        ></img>
                        <div className={Styles.article_item_content}>
                          <div className={Styles.article_item_content_title}>
                            {item.title}
                          </div>
                          <div className={Styles.article_item_content_desc}>
                            {getMarkDownMore(item.content)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={Styles.pagination}>
                  <Pagination
                    pageSize={pageSize}
                    current={pageNo}
                    total={total}
                    showSizeChanger={false}
                    // hideOnSinglePage
                    onChange={changePagination}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* </Spin> */}
        {subscription_plan_type !== 2 ? (
          <div className={Styles.Content_mask}></div>
        ) : null}
      </div>
      <div className={Styles.Chat}>
        <div className={Styles.header}>
          <div className={Styles.header_inner}>
            <img src={teacherPic}></img>
            <span>学习空间</span>
            <img
              src={theme === "dark" ? settingPic : settingLightPic}
              className={Styles.header_menu}
              onClick={() => {
                setShowSetting(true);
              }}
            ></img>
          </div>
        </div>
        <div
          className={classNames(
            Styles.study_settings,
            "magictime",
            showSetting ? "slideRightReturn" : "slideRight",
            showSetting
              ? Styles.study_settings_show
              : Styles.study_settings_hide
          )}
          style={{
            animationDuration: "0.4s",
          }}
        >
          <div className={Styles.study_settings_header}>
            <CloseOutlined
              size={16}
              className={classNames(
                Styles.study_settings_close,
                theme === "dark"
                  ? Styles.study_settings_close_dark
                  : Styles.study_settings_close_light
              )}
              onClick={() => {
                setShowSetting(false);
              }}
            />
            个性化学习模式
          </div>
          <div
            className={classNames(Styles.study_settings_list, "scroll-hide")}
          >
            {settingsOptions.map((item: any, index: number) => {
              return (
                <div key={index} className={Styles.study_settings_list_item}>
                  <div className={Styles.study_settings_list_name}>
                    {item.name}
                  </div>
                  <div className={Styles.study_settings_options}>
                    {item.options.map((item1: any, index1: number) => {
                      return (
                        <div
                          key={index1}
                          className={classNames(
                            Styles.study_settings_option,
                            settingData[item.key] === item1.value
                              ? Styles.study_settings_option_active
                              : ""
                          )}
                          onClick={() => {
                            changeSettingData(item.key, item1.value);
                          }}
                        >
                          {item1.label}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={Styles.Chat_inner}>
          <ChatBox
          scene="advanced"
            agentId={config.homepage_agent_id}
            pubAgentId={config.homepage_agent_id}
            questionEx={questionSamples}
            greeting={greeting}
            history={history}
            botAvator={teacherChatPic}
          />
        </div>
      </div>
      {subscription_plan_type !== 2 ? maskRender() : null}
    </div>
  );
};

export default Advanced;
