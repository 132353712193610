import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import styles from './index.module.scss';
import SvgCompleted from "@/assets/community/completed.svg";
import SvgInProgress from "@/assets/community/inProgress.svg";
import SvgNotStartLight from "@/assets/community/notStartLight.svg";
import SvgNotStartDark from "@/assets/community/noStartDark.svg"
import useThemeHooks from '@/hooks/useThemeHooks';

// 定义 InputState 类型
enum InputState {
    Editable,
    Locked,
    Indeterminate,
}

// 定义 SingleInputState 类型，表示单个输入框的状态
type SingleInputState = {
    editable: InputState;
    value: string;
};

// 定义 InputsState 类型，表示多个输入框的状态
type InputsState = {
    [key: number]: SingleInputState;
};

// 定义 Line 组件的属性类型
interface LineProps {
    inputsState: InputsState; // 多个输入框的状态
    loading: boolean;         // 加载状态
}

// 使用 forwardRef 和 useImperativeHandle 来暴露 handleTrigger 方法
const StepLine = forwardRef(({ inputsState, loading }: LineProps, ref) => {

    // 将 inputsState 映射为 editable 对象
    const editable = Object.entries(inputsState).reduce((acc, [step_id, { editable }]) => {
        acc[Number(step_id)] = editable;
        return acc;
    }, {} as { [key: number]: InputState });

    const entries = Object.entries(editable);

    // 定义当前激活项的索引状态，初始值为-1
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    // 定义 SVG 图标的最终状态，初始值为不定状态
    const [finalSvgState, setFinalSvgState] = useState<InputState>(InputState.Indeterminate);

    const theme = useThemeHooks();

    useEffect(() => {
        // 如果当前激活项的索引为-1，则直接返回
        if (activeIndex === -1) return;

        let timeoutId: NodeJS.Timeout;

        // 如果当前项的索引小于总项数
        if (activeIndex < entries.length) {
            timeoutId = setTimeout(() => {
                // 每隔500ms递增激活项索引
                setActiveIndex(prev => prev + 1);
            }, 500);
        } else if (!loading) {
            // 如果不处于加载状态，则在500ms后将最终SVG状态设置为已完成
            timeoutId = setTimeout(() => {
                setFinalSvgState(InputState.Locked);
            }, 500);
        }

        // 清理定时器
        return () => clearTimeout(timeoutId);
    }, [activeIndex, entries.length, loading]);

    // 触发方法，用于初始化和启动动画过程
    const handleTrigger = () => {
        setActiveIndex(-1); // 首先重置为初始状态
        setFinalSvgState(InputState.Indeterminate);
        setTimeout(() => setActiveIndex(0), 50); // 在重置后启动动画
    };

    // 暴露 handleTrigger 方法给外部组件
    useImperativeHandle(ref, () => ({
        handleTrigger
    }));

    // 渲染 SVG 元素
    const renderSvg = (state: InputState) => {
        const svgMap = {
            [InputState.Editable]: SvgInProgress,
            [InputState.Locked]: SvgCompleted,
            [InputState.Indeterminate]: theme === "dark" ? SvgNotStartDark : SvgNotStartLight,
        };
        return <img src={svgMap[state]} alt='' />;
    };

    // 返回不同状态下的步骤标签样式
    const getStepLabelStyle = (state: InputState) => {
        if (state === InputState.Editable || state === InputState.Locked) {
            return { color: 'var(--color-primary-1)' };
        }
        return { color: 'var(--color-text-2)' };
    };

    return (
        <div className={styles.container}>
            <div className={styles.stepSection}>
                <div className={styles.svgWrapper}>
                    {renderSvg(InputState.Locked)}
                </div>
                <div className={styles.stepLabel} style={getStepLabelStyle(InputState.Locked)}>任务背景</div>
            </div>

            {entries.map(([key, value], index) => {
                const keyStr = Number(key) < 10 ? `0${key}` : key;
                return (
                    <React.Fragment key={key}>
                        <div className={`${styles.connector} ${activeIndex > index ? styles.green : ''}`} />
                        <div className={styles.stepSection}>
                            <div className={styles.svgWrapper}>
                                {renderSvg(value)}
                            </div>
                            <div className={styles.stepLabel} style={getStepLabelStyle(value)}>step{keyStr}</div>
                        </div>
                    </React.Fragment>
                );
            })}

            <div className={`${styles.connector} ${activeIndex >= entries.length && !loading ? styles.green : ''}`} />
            <div className={styles.stepSection}>
                <div className={styles.svgWrapper}>
                    {renderSvg(finalSvgState)}
                </div>
                <div className={styles.stepLabel} style={getStepLabelStyle(finalSvgState)}>输出</div>
            </div>
        </div>
    );
});

export default StepLine;