import { getUserGuideInfo } from "@/apis/common";
import { useEffect, useState } from "react";
import Guide from "./components/Guide";
import RecommendPage from "./components/Recommend";
import useLoginHooks from "@/hooks/useLoginHooks";
import style from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import useLoginEffect from "@/hooks/useLoginEffect";

const Home = () => {
  const [isGuideCompleted, setIsGuideCompleted] = useState(true);
  const { isLogin } = useLoginHooks();
  const isMobile = window.innerWidth <= 600;

  const navigate = useNavigate();

  const getUserInfoGuideCompleted = () => {
    getUserGuideInfo().then((res: any) => {
      if (res.data && res.data.status === 200) {
        setIsGuideCompleted(res.data.data.is_tutorial_completed);
      }
    });
  };
  const [showRecommend, setShowRecommend] = useState(true);

  useLoginEffect(() => {
    getUserInfoGuideCompleted();
  }, []);

  useEffect(() => {
    if (!showRecommend) {
      navigate("/HomeChatPage");
    }
  }, [showRecommend]);

  if (isGuideCompleted === false && isLogin && !isMobile) {
    return (
      <div className={style.homePage}>
        <div className={style.homeContent}>
          <Guide setIsGuideCompleted={setIsGuideCompleted} />
        </div>
      </div>
    );
  }

  return <RecommendPage setShowRecommend={setShowRecommend} />;
};

export default Home;
