import { useEffect, useRef, useState } from "react";
import Styles from "./index.module.scss";
import { Input, message, Tooltip } from "antd";
import AvatarSvg from "@/assets/common/teacher.svg";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import agentBg from "@/assets/workflow/default-portrait.svg";
import "react-typed/dist/animatedCursor.css";
import baseWsURL from "@/request/getWsBaseURL";
import SendPic from "@/assets/chat/send.svg";
import SendLightPic from "@/assets/chat/send-light.svg";
import SendLightDisabledPic from "@/assets/chat/send-light-disabled.svg";
import SendDisabledPic from "@/assets/chat/send-disabled.svg";
import useThemeHooks from "@/hooks/useThemeHooks";
import hljs from "highlight.js";
import Cookies from "js-cookie";
import Content from "./components/Content";
import store, { loginOption } from "@/store";
import _, { isNumber } from "lodash";
import { getRandomError } from "@/common/errorMsg";
import UploadFilesList from "../uploadFilesList";
import { openRecharge } from "@/store/reducer/coinRecharge";
import { setCoinNumber } from "@/store/reducer/userConfig";
import { createImageUrl } from "@/utils/common";
import "highlight.js/styles/vs2015.css";
import Coin from "@/assets/common/coin.svg";
import useGlobalUserData from "@/hooks/useUserConfigHook";

const AgentIcon1 = createImageUrl("workflow/agent01.png");
const AgentIcon2 = createImageUrl("workflow/agent02.png");
const AgentIcon3 = createImageUrl("workflow/agent03.png");
const AgentIcon4 = createImageUrl("workflow/agent04.png");
const AgentIcon5 = createImageUrl("workflow/agent05.png");
const defaultAgentBg = createImageUrl('common/agent-bg.png');

const agentList = [AgentIcon1, AgentIcon2, AgentIcon3, AgentIcon4, AgentIcon5];
type IChatBox = {
  mode?: string;
  agentId?: number | string;
  pubAgentId?: number | string;
  history?: any;
  botAvator?: string;
  botName?: string;
  questionEx?: Array<string>;
  frameId?: number;
  currentInfo?: any;
  greeting?: string;
  cardShowCoin?: boolean;
};

let ws: any = null;
let controller = new AbortController();
const CardChatBox = ({
  mode,
  agentId,
  pubAgentId,
  history,
  botAvator,
  botName,
  questionEx,
  frameId,
  currentInfo,
  greeting,
  cardShowCoin,
}: IChatBox) => {
  const { t } = useTranslation();
  const [chatMsg, setChatMsg] = useState("");
  const [sendNow, setSendNow] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [chatData, setChatData] = useState(history);
  const boxRef: any = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const theme = useThemeHooks();
  const [isOnBottom, setIsOnBottom] = useState(false);
  const token = localStorage.getItem("token") || Cookies.get("token");
  const [onFocus, setOnFocus] = useState(false);
  const [relatedClick, setRelatedClick] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const { avatar } = useGlobalUserData();
  // 维护首次接收到有效消息 用于渲染loading态 防止loading元素导致聊天自动滚动有问题
  const [isFirst200Received, setIsFirst200Received] = useState<boolean>(false);
  let isFinished = true;

  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const tryAgain = (index: number) => {
    const data = [...chatData].slice(0, index);

    const lastMsg = _.findLast(data, (item) => item.role === 1);

    if (lastMsg.role === 1) {
      setChatMsg(lastMsg.content);
      setSendNow(true);
      goChatBottom();
    }
  };

  useEffect(() => {
    setChatData(history);
  }, [history]);

  useEffect(() => {
    if (!!sendNow) {
      sendMsg();
      setSendNow(false);
    }
  }, [sendNow]);

  const highlightCode = () => {
    const preEl = document.querySelectorAll("pre");

    preEl.forEach((el) => {
      hljs.highlightBlock(el);
    });
  };

  const connectWs = (func?: any) => {
    const token_string = `?token=${token}`;
    ws = new WebSocket(
      `${baseWsURL}/ws/agent_chat${token ? token_string : ""}`
    );

    ws.onopen = function (event: any) {
      if (func) {
        func();
      }
    };

    ws.onclose = function (event: any) {
      setBtnLoading(false);
      setIsFirst200Received(false)
      console.log("wsclose");
      highlightCode();
    };

    ws.onmessage = (res: any) => {
      const data = JSON.parse(res.data);
      if (data.status === 40103) {
        onLoginAndRegister();
      } else if (data.status === 200 && data.from !== "userproxy" && data) {
        let { is_finished, content, content_type } = data;
        if (isFirst200Received) {
          setIsFirst200Received(false);
        }
        if (isFinished) {
          setChatData((chatData: any) => {
            const newChatData = [...chatData];
            newChatData.push({ ...data, content_type });
            return newChatData;
          });
          isFinished = is_finished;
        } else {
          if (content_type === "text") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: newChatData[newChatData.length - 1].content + content,
                content_type,
              };
              return newChatData;
            });
          } else if (content_type === "search") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: {
                  ...data?.content,
                  answer:
                    newChatData[newChatData.length - 1]?.content?.answer +
                    data?.content?.answer,
                  content_type,
                },
              };
              return newChatData;
            });
          }
          isFinished = is_finished;
        }
      }

      if ((data.status && data.status === 20050) || data.status === 50001) {
        setBtnLoading(false);
        setIsFirst200Received(false)
        highlightCode();
      }

      if (data.status && data.status === 50001) {
        const errorMsg = getRandomError();
        message.error(errorMsg);
      }

      if (data.status && (data.status === 40030 || data.status === 40031)) {
        store.dispatch(openRecharge());
        message.error(
          data.status === 40031
            ? "没有智纪币啦, 为您打开订购套餐页面~"
            : "当前对话为付费套餐才可以使用的对话哦, 为您打开订购套餐页面~"
        );
        setBtnLoading(false);
        setIsFirst200Received(false)
      }

      if (data.current_coin_count && isNumber(data.current_coin_count)) {
        store.dispatch(setCoinNumber(data.current_coin_count));
      }

      hljs.initHighlighting();
    };

    ws.onerror = function (error: any) {
      setBtnLoading(false);
      setIsFirst200Received(false)
      console.log("wserror", error);
    };
  };

  const unConnectWs = () => {
    if (ws) {
      ws.close();
    }
  };

  useEffect(() => {
    hljs.highlightAll();
  }, [chatData]);

  useEffect(() => {
    if (agentId) {
      controller.abort();
      controller = new AbortController();
    }
  }, [agentId]);

  const curChatMsg = localStorage.getItem("curChatMsg");

  useEffect(() => {
    if (!!curChatMsg) {
      return;
    }

    unConnectWs();

    setTimeout(() => {
      connectWs();
    }, 200);

    return () => {
      unConnectWs();
    };
  }, []);

  useEffect(() => {
    if (curChatMsg) {
      unConnectWs();
      setTimeout(() => {
        connectWs(() => {
          setChatMsg(curChatMsg);
          setSendNow(true);
          localStorage.setItem("curChatMsg", "");
        });
      }, 200);
    }
  }, [curChatMsg]);

  const goChatBottom = () => {
    if (boxRef?.current) {
      boxRef.current.scrollTop = boxRef?.current?.scrollHeight;
    }
  };

  useEffect(() => {
    const onScroll = () => {
      const { scrollTop } = boxRef?.current;
      if (scrollTop > lastScrollTop) {
        setIsScrollingUp(false); // 用户向下滚动
      } else {
        setIsScrollingUp(true); // 用户向上滚动
      }
      setLastScrollTop(scrollTop);
    };

    boxRef.current.addEventListener("scroll", onScroll);

    return () => {
      boxRef && boxRef?.current?.removeEventListener("scroll", onScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    setIsOnBottom(true);
    if (boxRef?.current && boxRef.current?.scrollTop !== undefined) {
      boxRef.current.scrollTop = boxRef.current?.scrollHeight;
    }
  }, [agentId, pubAgentId]);

  useEffect(() => {
    const handleScrollFn = () => {
      const { scrollTop, scrollHeight, clientHeight } = boxRef?.current;
      const errorTolerance = 80; // 设置一个误差容忍值
      if (
        errorTolerance >= Math.abs(scrollTop - (scrollHeight - clientHeight))
      ) {
        setIsOnBottom(true); // 此时已经在底部了
      } else {
        setIsOnBottom(false);
      }
    };

    boxRef?.current?.addEventListener("scroll", handleScrollFn);

    return () => {
      boxRef && boxRef?.current?.removeEventListener("scroll", handleScrollFn);
    };
  }, []);

  useEffect(() => {
    let boxScrollBottom: any = null;
    if (!isScrollingUp && isOnBottom) {
      if (boxRef?.current) {
        boxScrollBottom = setInterval(() => {
          if (
            boxRef.current?.scrollTop === undefined ||
            boxRef.current?.scrollTop === null
          ) {
            return;
          }
          boxRef.current.scrollTop = boxRef.current?.scrollHeight;
        }, 100);
      }
    } else {
      clearInterval(boxScrollBottom);
    }

    return () => {
      if (boxScrollBottom) {
        clearInterval(boxScrollBottom);
      }
    };
  }, [chatData, isScrollingUp, isOnBottom]);

  const curQuestions = () => {
    if (!questionEx) return [];
    return questionEx
      ?.filter((item) => {
        const currentLocalStorage = localStorage.getItem(
          `${agentId || pubAgentId}_question`
        )
          ? JSON.parse(
              localStorage.getItem(`${agentId || pubAgentId}_question`) || "[]"
            )
          : [];
        return item !== "" && currentLocalStorage.indexOf(item) === -1;
      })
      .map((item, index) => {
        return (
          item.trim() && (
            <div
              className={Styles.question_item}
              key={index}
              onClick={() => {
                const currentLocalStorage = localStorage.getItem(
                  `${agentId || pubAgentId}_question`
                )
                  ? JSON.parse(
                      localStorage.getItem(
                        `${agentId || pubAgentId}_question`
                      ) || "[]"
                    )
                  : [];

                if (currentLocalStorage.indexOf(item) === -1) {
                  currentLocalStorage.push(item);
                  localStorage.setItem(
                    `${agentId || pubAgentId}_question`,
                    JSON.stringify(currentLocalStorage)
                  );
                }

                setChatMsg(item);
                setSendNow(true);
              }}
            >
              <span>{item}</span>
            </div>
          )
        );
      });
  };

  const sendMsg = () => {
    if (btnLoading) {
      return;
    }

    if (!chatMsg) {
      return false;
    }

    if (!token) {
      onLoginAndRegister();
      return;
    }

    setBtnLoading(true);
    setIsFirst200Received(true)
    const newChatData = [...chatData];
    const sendMsgWs = (id?: any) => {
      let msgObj: any = {
        message: chatMsg,
        topic_id: -2,
        history: newChatData.slice(0, newChatData.length).slice(-6),
        is_save_msg: token ? true : false,
        chat_mode: frameId === 101 ? "llm_chat" : "ai_agent",
        is_stream: true,
        model: currentInfo?.flow_config.receiver[0].model,
        toolset: currentInfo?.flow_config?.skills,
      };

      if (mode === "agent") {
        msgObj.is_public = false;
        msgObj.agent_id = agentId;
      } else if (mode === "pub_agent") {
        msgObj.is_public = true;
        msgObj.agent_id = pubAgentId;
      }

      newChatData.push({
        role: 1,
        content: msgObj?.message,
      });
      const sendAction = () => {
        if (ws.readyState === 1) {
          //开始通信时的处理
          ws.send(JSON.stringify(msgObj));
        }
      };
      if (ws && ws.readyState === 1) {
        sendAction();
      } else {
        unConnectWs();
        setTimeout(() => {
          connectWs(() => {
            sendAction();
          });
        });
      }
    };
    sendMsgWs();
    setChatData(newChatData);
    setChatMsg("");
  };

  const getSendImgSrc = () => {
    return theme === "dark"
      ? btnLoading || !chatMsg
        ? SendDisabledPic
        : SendPic
      : btnLoading || !chatMsg
      ? SendLightDisabledPic
      : SendLightPic;
  };

  const onRelatedClick = (message: string) => {
    setChatMsg(message);
    setRelatedClick((value) => !value);
    goChatBottom();
  };

  const getAvatarUrl = (name: string) => {
    const receiver = currentInfo?.flow_config?.receiver || [];
    // console.log(currentInfo, "-------fff-----", name);
    return currentInfo?.id === 1000 || !name || !currentInfo
      ? botAvator
      : receiver.filter((item: any) => item?.name === name)?.[0]?.avatar_url ||
          (receiver.length === 1
            ? defaultAgentBg
            : agentList[
                receiver.findIndex((item: any) => item.name === name) === -1
                  ? 0
                  : receiver.findIndex((item: any) => item.name === name)
              ]);
  };

  useEffect(() => {
    sendMsg();
  }, [relatedClick]);

  useEffect(() => {
    if (chatData.length) {
      const clickHandler = (event: any) => {
        if (!event.target.getAttribute("href")) {
          return;
        }
        event.preventDefault();
        console.log(event.target.getAttribute("href"));
        window.open(event.target.getAttribute("href"), "_blank");
      };

      var content: any = document.getElementById("history");
      const aTags = content.getElementsByTagName("a");
      Array.prototype.forEach.bind(aTags)((a) => {
        a.addEventListener("click", clickHandler);
      });
    }
  }, [chatData.length]);

  return (
    <div
      className={classnames(Styles.chat, Styles.chat_preview, Styles.chat_card)}
    >
      <div className={Styles.chat_inner}>
        <div className={Styles.chat_history} ref={boxRef}>
          {chatData && !!chatData.length ? (
            <div
              className={Styles.chat_history_inner}
              id='history'
              style={
                !!curQuestions().length
                  ? {
                      marginBottom: 0,
                    }
                  : {}
              }
            >
              {chatData.map((item: any, index: number) => {
                return (
                  <div
                    className={classnames(
                      Styles.chat_history_item,
                      // item.role === 2
                      Styles.chat_history_item_receive
                      // : Styles.chat_history_item_send
                    )}
                    key={index}
                  >
                    <div className={Styles.chat_history_name_box}>
                      <img
                        alt=''
                        className={Styles.chat_history_item_img}
                        src={
                          item.role === 2
                            ? getAvatarUrl(item?.from || item?.sender_name) ||
                              agentBg
                            : avatar || AvatarSvg
                        }
                        loading='lazy'
                      ></img>
                      <div>
                        {item.role === 2
                          ? item?.from || item?.sender_name || botName || ""
                          : t("common.you")}
                      </div>
                    </div>
                    {item.content && (
                      <div className={Styles.chat_history_item_msg}>
                        {item.role === 2 ? (
                          <span
                            style={{ whiteSpace: "pre-wrap", width: "100%" }}
                          >
                            <Content
                              curWidth={boxRef.current?.clientWidth}
                              message={item}
                              onRelatedClick={onRelatedClick}
                              isLast={index === chatData.length - 1}
                              tryAgain={() => {
                                tryAgain(index);
                              }}
                              loading={btnLoading}
                            />
                            {/* <ReactMarkdown>{item.content}</ReactMarkdown> */}
                          </span>
                        ) : (
                          <div className={Styles.chat_history_item_wrapper}>
                            <div className={Styles.chat_history_item_inner}>
                              <span
                                style={{
                                  whiteSpace: "pre-wrap",
                                  width: "100%",
                                }}
                              >
                                {typeof item.content === "string" ? (
                                  item.content
                                ) : (
                                  <>
                                    {
                                      item?.content?.find(
                                        (subItem: any) =>
                                          subItem.type === "text"
                                      )?.text
                                    }
                                  </>
                                )}
                                {/* <Content message={item} /> */}
                                {/* {item.content} */}
                              </span>
                            </div>
                            {Array.isArray(item?.content) &&
                            item?.content?.filter(
                              (subItem: any) => subItem.type !== "text"
                            ).length ? (
                              <div
                                className={Styles.filesList}
                                style={{
                                  position: "relative",
                                  top: "-10px",
                                }}
                              >
                                <UploadFilesList
                                  fileList={item?.content?.filter(
                                    (subItem: any) => subItem.type !== "text"
                                  )}
                                  isChat={true}
                                />
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              {btnLoading && (
                <div className={Styles.chat_history_item_loading}>
                  <div className='dot-pulse'></div>
                </div>
              )}
            </div>
          ) : (
            <div className={classnames(Styles.empty, Styles.empty_card)}>
              <img
                src={botAvator}
                className={Styles.card_empty_img}
                loading='lazy'
                alt=''
              ></img>
              <div
                className={Styles.card_empty_msg}
                dangerouslySetInnerHTML={{
                  __html: greeting || t("关于左侧的知识有什么可以帮你吗？"),
                }}
              ></div>
            </div>
          )}

          {!!curQuestions().length && (
            <div
              className={Styles.questionsWrap}
              style={
                chatData && !chatData.length
                  ? {
                      position: "absolute",
                      bottom: "0px",
                    }
                  : {
                      width: "100%",
                      position: "relative",
                      top: "-20px",
                      bottom: "0px",
                      padding: 0,
                    }
              }
            >
              <div
                className={Styles.questions}
                style={{
                  ...(curQuestions()?.length === 1
                    ? {
                        gridTemplateColumns: "1fr",
                      }
                    : {}),
                  ...(chatData && !chatData.length
                    ? {}
                    : {
                        width: "100%",
                      }),
                }}
              >
                {curQuestions()}
              </div>
            </div>
          )}
        </div>

        <div className={Styles.chat_insert}>
          <div
            className={Styles.chat_to_bottom}
            style={{ visibility: isOnBottom ? "hidden" : "visible" }}
          >
            <img
              className={Styles.chat_insert_send}
              src={theme === "dark" ? SendDisabledPic : SendLightDisabledPic}
              onClick={() => {
                goChatBottom();
              }}
              loading='lazy'
              alt=''
            ></img>
          </div>
          <div
            className={Styles.chat_input_box_wrap}
            style={{
              height: "100%",
              position: "relative",
              bottom: "0",
            }}
          >
            <div
              className={
                onFocus ? Styles.chat_input_wrap_active : Styles.chat_input_wrap
              }
            >
              {
                <>
                  <Input.TextArea
                    onFocus={() => {
                      setOnFocus(true);
                    }}
                    onBlur={() => {
                      setOnFocus(false);
                    }}
                    className={Styles.chat_insert_input}
                    value={chatMsg}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    onChange={(e) => {
                      setChatMsg(e.target.value);
                    }}
                    placeholder={t("chat.input.placeholder")}
                    onPressEnter={(e) => {
                      if (e.which === 13 || e.keyCode === 13) {
                        if (e.shiftKey) {
                          return;
                        }

                        if (!isTyping) {
                          sendMsg();
                        }
                        e.preventDefault();
                      }
                    }}
                    onCompositionStart={() => {
                      setIsTyping(true);
                    }}
                    onCompositionEnd={() => {
                      setIsTyping(false);
                    }}
                  ></Input.TextArea>

                  <div className={Styles.chat_insert_right}>
                    {cardShowCoin && (
                      <Tooltip
                        title='消费标准: 1次GPT4o对话或1次Claude3.5对话消耗1智纪币'
                        zIndex={10000}
                        overlayStyle={{ maxWidth: 200 }}
                      >
                        <img
                          src={Coin}
                          alt=''
                          style={{ width: 24, height: 24, marginRight: 4 }}
                        />
                      </Tooltip>
                    )}
                    <img
                      className={Styles.chat_insert_send}
                      src={getSendImgSrc()}
                      onClick={() => {
                        sendMsg();
                      }}
                      loading='lazy'
                      alt=''
                    ></img>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardChatBox;
