import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import styles from "./index.module.scss";
import { Input, Form, Button, message } from "antd";
import { registerFieldType } from "@/types/common";
import { toLogin, toUsersIslogin } from "@/apis/common";
import { useNavigate } from "react-router-dom";
import { updateUserInfo } from "@/store/reducer/userInfo";
import store from "@/store";
import Cookies from "js-cookie";
const Login = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  function getTopLevelAndSecondLevelDomain(hostname: any) {
    // 分割主机名
    const parts = hostname.split(".");
    // 确保至少有三部分（例如 sub.example.com）
    if (parts.length > 2) {
      // 提取最后两部分作为顶级域名和二级域名
      return parts.slice(-2).join(".");
    }
    // 如果主机名部分不足，返回原始主机名
    return hostname;
  }
  const hostname = window.location.hostname;
  const topLevelAndSecondLevelDomain =
    getTopLevelAndSecondLevelDomain(hostname);

  const onFinish = (data: any) => {
    setLoading(true);
    toLogin(data).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        message.success(t("common.login.success"));
        navigate("/");

        toUsersIslogin().then((res: any) => {
          store.dispatch({
            ...updateUserInfo(res.data.data),
            isUserInfoLoading: false,
            token: localStorage.getItem("token"),
          });

          Cookies.set("token", localStorage.getItem("token") as string, {
            domain: `${topLevelAndSecondLevelDomain}`,
          });
        });
      }
    });
  };

  const onFinishFailed = () => {};

  const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
  };

  return (
    <div className={styles.pages}>
      <div className={styles.login_box}>
        <Form
          name="login"
          className={styles.form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h3 className={styles.welcome}>{t("common.welcome")}</h3>
          <Form.Item<registerFieldType>
            label={t("common.userName")}
            name="username"
            rules={[{ required: true, message: t("common.user.placeHolder") }]}
            className={styles.form_item}
          >
            <Input className={styles.input} />
          </Form.Item>
          <Form.Item<registerFieldType>
            label={t("common.password")}
            name="password"
            rules={[
              { required: true, message: t("common.password.placeHolder") },
            ]}
            className={styles.form_item}
          >
            <Input.Password className={styles.input} />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.login_btn}
            loading={loading}
          >
            {t("common.login")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
