import ChatBox from "@/components/ChatBox";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { getUserGuideInfo, mainQuestionList } from "@/apis/common";
import { useEffect, useState } from "react";
import useConfigHooks from "@/hooks/useConfigHooks";
import useLoginHooks from "@/hooks/useLoginHooks";
import LoginModal from "@/components/LoginModal";
import style from "./index.module.scss";

const HomeIcon = createImageUrl('common/knowledge-agent.png');

const Home = () => {
  // const [homeQuestionList, setHomeQuestionList] = useState<any[]>([]);
  const [isGuideCompleted, setIsGuideCompleted] = useState(false);
  const { isLogin } = useLoginHooks();
  const { config } = useConfigHooks();

  const getUserInfoGuideCompleted = () => {
    getUserGuideInfo().then((res: any) => {
      if (res.data && res.data.status === 200) {
        setIsGuideCompleted(res.data.data.is_tutorial_completed);
      }
    });
  };

  useEffect(() => {
    if (isLogin) {
      getMainQuestionList();
      getUserInfoGuideCompleted();
    } else {
      goToLogin();
    }
  }, [isGuideCompleted, isLogin]);

  const goToLogin = () => {
    return <LoginModal />;
  };

  const getMainQuestionList = async () => {
    mainQuestionList({
      size: 4,
    }).then((res: any) => {
      if (res && res.data && res.data.status === 200) {
        // TODO: 这段代码是干什么的 homeQuestionList也没用啊
        // setHomeQuestionList(res.data.data);
      }
    });
  };

  return (
    <div className={style.homePage}>
      <div className={style.homeContent}>
        <ChatBox
          scene="home"
          agentId={config.homepage_agent_id}
          pubAgentId={config.homepage_agent_id}
          history={[]}
          botAvator={getImageUrl(HomeIcon)}
        />
      </div>
    </div>
  );
};

export default Home;
