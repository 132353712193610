import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import zhCN from "./locales/zh-cn";
import zhTW from "./locales/zh-tw";
import esAR from "./locales/es-ar";
import inID from "./locales/in-id";
import jaJP from "./locales/ja-jp";
import koKR from "./locales/ko-kr";
import frFR from "./locales/fr-fr";
import en from "./locales/en-us";

i18n
  // 检测用户当前使用的语言
  // 文档: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // 注入 react-i18next 实例
  .use(initReactI18next)
  // 初始化 i18next
  // 配置参数的文档: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: "zh-CN", // 设置默认语言为中文
    // fallbackLng: "en",
    fallbackLng: false, // 不使用回退语言
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          // 这里是我们的翻译文本
          ...en,
        },
      },
      "zh-CN": {
        translation: {
          ...zhCN,
        },
      },
      "zh-TW": {
        translation: {
          ...zhTW,
        },
      },
      "es-AR": {
        translation: {
          ...esAR,
        },
      },
      "in-ID": {
        translation: {
          ...inID,
        },
      },
      "ja-JP": {
        translation: {
          ...jaJP,
        },
      },
      "ko-KR": {
        translation: {
          ...koKR,
        },
      },
      "fr-FR": {
        translation: {
          ...frFR,
        },
      },
    },
  });

export default i18n;
