import Styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import { IAgentInfoProps } from "@/types/common";
import { createImageUrl, getImageUrl } from "@/utils/common";
import SeekingItemDetail from "./ToolItemDetail";
import { like, disLike } from "@/apis/common";
import { numberFormat } from "@/utils/common";
import _ from "lodash";
import classNames from "classnames";

const agentBg = createImageUrl('common/agent-bg.png');
const Avatar = createImageUrl('menu/avator.png');

const SeekingItem = ({
  agentInfo,
  tagNameObj,
  userObj,
  likeStatusObj,
  setLikeStatusObj,
  isSelfItem = false,
  top,
}: IAgentInfoProps) => {
  const itemRef: any = useRef();
  const [currentWidth, setCurrentWidth] = useState();
  const [isLiking, setIsLiking] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const curId = isSelfItem ? agentInfo?.pub_agent_id : agentInfo?.id;

  // 点赞逻辑
  const likeAction = () => {
    if (isLiking) {
      return;
    }

    if (curId && setLikeStatusObj) {
      setIsLiking(true);
      if (!likeStatusObj[curId]?.is_my_like) {
        like({ pub_agent_id: curId })
          .then((res) => {
            setIsLiking(false);
            if (res && res.data && res.status === 200) {
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  is_my_like: true,
                  like_count:
                    newLikeStatusObj[curId]?.like_count &&
                    newLikeStatusObj[curId]?.like_count > 0
                      ? newLikeStatusObj[curId]?.like_count + 1
                      : 1,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      } else {
        disLike({ pub_agent_ids: [curId] })
          .then((res) => {
            if (res && res.data && res.status === 200) {
              setIsLiking(false);
              const newLikeStatusObj = _.cloneDeep(likeStatusObj);
              if (curId) {
                newLikeStatusObj[curId] = {
                  is_my_like: false,
                  like_count:
                    newLikeStatusObj[curId]?.like_count &&
                    newLikeStatusObj[curId]?.like_count > 0
                      ? newLikeStatusObj[curId]?.like_count - 1
                      : 0,
                };
              }
              setLikeStatusObj(newLikeStatusObj);
            }
          })
          .catch((err) => {
            setIsLiking(false);
          });
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (itemRef) {
      setCurrentWidth(itemRef.current.offsetWidth || 0);
    }
  }, [itemRef, windowWidth]);
  const findUserById = (id: any) => {
    if (!userObj) {
      return null;
    }
    
    const user = userObj.find((user: any) => user.id === id);
    return user ? { username: user.username, avatar: user.avatar } : null;
  };
  return (
    <div className={Styles.agent_item} ref={itemRef}>
      <div className={Styles.agent_item_content}>
        <div className={Styles.agent_item_img_box}>
          <div className={Styles.agent_item_img_padding}></div>
          <img
            className={Styles.agent_item_img}
            src={getImageUrl(agentInfo?.avatar_url) || agentBg}
            onError={(e: any) => {
              e.target.src = agentBg;
            }}
            loading="lazy"
          ></img>
          <div className={Styles.agent_item_padding}></div>
          {/* <div className={Styles.agent_item_type}>
            <img src={SingalAgentPic}></img>
            <span>{t("common.agent.singal")}</span>
          </div> */}
          <div className={Styles.agent_item_number}>
            <i className={classNames('iconfont', 'icon-heat_fill')}/>
            <span>{numberFormat(agentInfo?.hot)}</span>
          </div>
          <div className={Styles.agent_item_mask}></div>
          <SeekingItemDetail
            agentInfo={agentInfo}
            tagNameObj={tagNameObj}
            userObj={userObj}
            currentWidth={currentWidth}
            likeStatusObj={likeStatusObj}
            setLikeStatusObj={setLikeStatusObj}
            likeAction={likeAction}
            isSelfItem={isSelfItem}
            curId={curId}
            top={top}
          />
        </div>
        {/* <div className={Styles.tag_list}>
        {agentInfo.tags?.map((item, index) => {
          return (
            <div className={Styles.tag_list_item} key={index}>
              {tagNameObj[item]}
            </div>
          );
        })}
      </div> */}
        <div className={Styles.agent_item_info}>
          <img className={Styles.avator} src={findUserById(agentInfo?.author)?.avatar || Avatar} loading="lazy"></img>
          <div>
            <div className={Styles.name}>{agentInfo?.name}</div>
            <div className={Styles.author}>
              @{findUserById(agentInfo?.author)?.username|| "智纪用户"}
            </div>
          </div>
          {/* <div className={Styles.action}>
          <Button style={{ marginLeft: "20px" }} size="small" onClick={toChat}>
            {t("对话")}
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            size="small"
            onClick={createSame}
          >
            {t("创建同款")}
          </Button>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default SeekingItem;
