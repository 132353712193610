import Styles from "./index.module.scss";
import { IAgentInfoProps } from "@/types/common";
import { toGetAgentInfo } from "@/apis/common";
import { useNavigate } from "react-router-dom";
import { createImageUrl, getImageUrl } from "@/utils/common";
import { useTranslation } from "react-i18next";
import useLoginHooks from "@/hooks/useLoginHooks";
import EditPic from "@/assets/common/edit.svg";
import CopyPic from "@/assets/common/copy.svg";
import DeletePic from "@/assets/myAgent/delete.svg";
import qs from "qs";
import useGlobalUserData from "@/hooks/useUserConfigHook";

const agentBg = createImageUrl('common/agent-bg.png');
const Avatar = createImageUrl('menu/avator.png');

const MyAgentItem = ({ agentInfo, onDelete }: IAgentInfoProps) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { username } = useLoginHooks();
  const {avatar} = useGlobalUserData();
  const createSame = () => {
    localStorage.setItem("createAgentInfo", "");
    toGetAgentInfo({
      id: agentInfo.id,
    }).then((res: any) => {
      if (res?.data?.status === 200) {
        if (res.data.data) {
          console.log(res.data.data, 890);
          localStorage.setItem(
            "createAgentInfo",
            JSON.stringify(res.data.data)
          );
        }
      }
    });
    navigate(
      `/workflow?${qs.stringify({
        step: "create",
      })}`
    );
  };

  const edit = () => {
    let param: any = {
      step: "create",
      id: String(agentInfo?.id),
      type: "private",
    };
    if (!!agentInfo.pub_agent_id) {
      param.publicId = agentInfo?.pub_agent_id;
    }
    navigate(`/workflow?${qs.stringify(param)}`);
  };

  const deleteItem = () => {
    onDelete?.(agentInfo);
  };

  return (
    <div className={Styles.agent_item}>
      <div className={Styles.agent_item_content}>
        <div className={Styles.agent_item_img_box}>
          <div className={Styles.agent_item_img_padding}></div>
          <img
            className={Styles.agent_item_img}
            src={getImageUrl(agentInfo?.avatar_url) || agentBg}
            onError={(e: any) => {
              e.target.src = agentBg;
            }}
            loading="lazy"
          ></img>
          <div
            className={Styles.agent_item_delete}
            onClick={() => {
              deleteItem();
            }}
          >
            <img src={DeletePic} />
          </div>
          {/* <div className={Styles.agent_item_number}>
            <img src={FirePic}></img>
            <span>1.2k</span>
          </div> */}
          <div className={Styles.agent_item_mask}></div>
          <div className={Styles.agent_item_action}>
            <div
              className={Styles.agent_item_action_item}
              onClick={() => {
                edit();
              }}
            >
              <img src={EditPic}></img>
              <span>{t("common.edit")}</span>
            </div>
            |
            <div
              className={Styles.agent_item_action_item}
              onClick={() => {
                createSame();
              }}
            >
              <img src={CopyPic}></img>
              <span>{t("common.create.same")}</span>
            </div>
          </div>
        </div>
        {/* <div className={Styles.tag_list}>
        {agentInfo.tags?.map((item, index) => {
          return (
            <div className={Styles.tag_list_item} key={index}>
              {tagNameObj[item]}
            </div>
          );
        })}
      </div> */}
        <div className={Styles.agent_item_info}>
          <img className={Styles.avator} src={avatar || Avatar}></img>
          <div>
            <div className={Styles.name}>{agentInfo?.name}</div>
            <div className={Styles.author}>@{username}</div>
          </div>
          {/* <div className={Styles.action}>
          <Button style={{ marginLeft: "20px" }} size="small" onClick={toChat}>
            {t("对话")}
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            size="small"
            onClick={createSame}
          >
            {t("创建同款")}
          </Button>
        </div> */}
        </div>
      </div>
    </div>
    // <div className={Styles.agent_item}>
    //   <img
    //     className={Styles.agent_item_img}
    //     src={getImageUrl(agentInfo?.avatar_url) || agentBg}
    //   ></img>
    //   <div className={Styles.agent_item_info}>
    //     <div>{agentInfo?.name}</div>
    //     <div className={Styles.action}>
    //       <Button size="small" onClick={edit}>
    //         {t("编辑")}
    //       </Button>
    //       <Button
    //         style={{ marginLeft: "20px" }}
    //         size="small"
    //         onClick={createSame}
    //       >
    //         {t("创建同款")}
    //       </Button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default MyAgentItem;
