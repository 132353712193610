import { useEffect } from 'react';
import useLoginHooks from './useLoginHooks';

type EffectFunction = () => void | Promise<void>;

/**
 * 自定义的 `useLoginEffect` 钩子，用于在用户登录状态下执行副作用。
 *
 * @param {EffectFunction} effectFn - 当用户登录时执行的副作用函数。
 * @param {any[]} deps - 依赖数组，用于控制副作用的执行。
 * @param {EffectFunction} fallbackFn - 当用户未登录时执行的函数。
 */
const useLoginEffect = (
  effectFn: EffectFunction,
  deps: any[] = [],
  fallbackFn: EffectFunction = () => {}
) => {
  // 使用自定义的 useLoginHooks 钩子获取当前的登录状态
  const { isLogin } = useLoginHooks();

  useEffect(() => {
    // 如果用户已登录，执行 effectFn
    if (isLogin) {
      effectFn();
    } else {
      // 如果用户未登录，执行 fallbackFn
      fallbackFn();
    }
  }, [isLogin, ...deps]);
};

export default useLoginEffect;
