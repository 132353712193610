import { createSlice } from "@reduxjs/toolkit";


export const knowledgeFilesSlice = createSlice({
  name: "knowledgeFiles",
  initialState: {
    knowledgeFiles: [],
    from: "",
  },
  reducers: {
    updateFiles: (state, action) => {
      state.knowledgeFiles = [ ...action.payload.knowledgeFiles ] as any ;
      state.from = action.payload.from as any ;
    },
  },
});

export const { updateFiles } = knowledgeFilesSlice.actions;

export default knowledgeFilesSlice.reducer;
