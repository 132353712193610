import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Modal } from "antd";
import Styles from "./index.module.scss";
import WarningPic from "@/assets/myAgent/warning.svg";
import { useTranslation } from "react-i18next";
const CustomModal = forwardRef((props: any, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const {
    title,
    modalText,
    confirmFn,
    isAsync,
    okText,
    noTitleIcon,
    danger = true,
    cancelFn,
  } = props;

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setConfirmLoading(false);
    setOpen(false);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    confirmFn && confirmFn();
    if (!isAsync) {
      hideModal();
    }
  };

  const handleCancel = () => {
    cancelFn && cancelFn();
    setOpen(false);
  };

  const titleNode = () => {
    return (
      <div className={Styles.title}>
        {noTitleIcon ? null : <img src={WarningPic} />}
        {title}
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    showModal,
    hideModal,
  }));

  return (
    <Modal
      title={titleNode()}
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      className={Styles.modal}
      onCancel={handleCancel}
      okType={"primary"}
      okText={okText || t("confirm")}
      okButtonProps={{
        danger,
      }}
      centered
      cancelText={t("cancel")}
    >
      <div className={Styles.content}>
        {props.children}
        <p>{modalText}</p>
      </div>
    </Modal>
  );
});

export default CustomModal;
